import React, { useEffect, useState } from 'react'
import { useTranslate, useRecordContext, useNotify, useRefresh } from 'react-admin'

import Button from '@mui/material/Button'
import Checkbox from '@mui/material/Checkbox'
import Rating from '@mui/material/Rating'

import { AllowedRole, Role } from 'api/common'
import { GatedCommunityMemberSmallRecord, GatedCommunityRecord, updateGatedCommunityMemberRoles } from 'api/gatedCommunity'
import { Box } from '@mui/material'

import 'styles.scss'

const REFRESH_LIST_AFTER = 1000

interface Props {
  member: GatedCommunityMemberSmallRecord
  onClose: () => void
}

const MemberRolesChange = ({ member, onClose }: Props) => {
  const [allowedRoles, setAllowedRoles] = useState<AllowedRole[]>(member.allowedRoles)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const record = useRecordContext<GatedCommunityRecord>()
  const notify = useNotify()
  const refresh = useRefresh()
  const translate = useTranslate()

  useEffect(() => {
    setAllowedRoles(() => member.allowedRoles)
  }, [member])

  const handleMemberAllowedRoleChange = (userRole: Role) => {
    if (allowedRoles.find(({ role }) => role === userRole)) {
      setAllowedRoles(roles => roles.filter(({ role }) => role !== userRole))
    } else {
      setAllowedRoles(roles => [...roles, { role: userRole, priority: 0 }])
    }
  }

  const handleMemberPriorityRoleChange = (userRole: Role, priority: number) => {
    setAllowedRoles(roles => roles.map(allowedRole => allowedRole.role === userRole ? { role: userRole, priority: priority } : allowedRole))
  }

  const handleMemberRolesUpdate = async () => {
    setIsLoading(true)
    try {
      await updateGatedCommunityMemberRoles(record.id, member.id as string, allowedRoles)
      notify('Roles were successfully updated.', { type: 'success' })
    } catch (error) {
      notify('Something went wrong.', { type: 'error' })
    }

    onClose()
    setIsLoading(false)

    setTimeout(() => {
      refresh()
    }, REFRESH_LIST_AFTER)
  }

  return <Box sx={{ display: 'flex', flexDirection: 'column' }}>
    {member.roles.map((availableRole) => {
      const roleParams = allowedRoles.find(({ role }) => role === availableRole)
      return <Box key={availableRole} sx={{ display: 'flex', alignItems: 'center' }}>
        <Checkbox
          size='small'
          sx={{ paddingRight: 1 }}
          checked={!!roleParams}
          onChange={() => handleMemberAllowedRoleChange(availableRole)}
        />
        <Rating
          sx={{ paddingRight: 1 }}
          disabled={roleParams === undefined}
          max={1}
          value={roleParams?.priority || 0}
          onChange={((_, value) => handleMemberPriorityRoleChange(availableRole, value || 0))}
        />
        {translate(`dict.role.${availableRole}`)}
      </Box>
    })}
    <Box margin="16px 0px" width={400}>
      {!!member.roles.length && <Button
        sx={{ height: "35px" }}
        variant='contained'
        color='secondary'
        className='outlined-button'
        disabled={isLoading || JSON.stringify(allowedRoles) === JSON.stringify(member.allowedRoles)}
        onClick={handleMemberRolesUpdate}>
        Update Roles
      </Button>}
    </Box>
  </Box>
}

export default MemberRolesChange