import React, { useCallback, useState } from 'react'
import {
  Datagrid, List, ListContextProvider, useGetList, useListContext, useRecordContext, Identifier, FunctionField, NumberField
} from 'react-admin'

import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Divider from '@mui/material/Divider'
import Drawer from '@mui/material/Drawer'
import { EditIcon } from 'common/Icons'

import CustomLinkField from 'common/CustomLinkField'
import CustomTranslatedTextField from 'common/CustomTranslatedTextField'
import CustomPagination from 'common/CustomPagination'
import { GatedCommunityMemberSmallRecord, GatedCommunityRecord } from 'api/gatedCommunity'
import MemberEdit from './MemberEdit'
import { Role } from 'api/common'
import UserService from 'UserService'
import rowStyle from 'gatedCommunity/rowStyle'
import { OutlinedArrayInput } from 'common/OutlinedArrayInput'
import { OutlinedSearchInput } from 'common/OutlinedSearchInput'
import { getOptionsSelectFromArray } from 'utils/helpers'
import { OutlinedSelectInput } from 'common/OutlinedSelectInput'
import { OutlinedDateTimeInput, LanguageFilter } from 'common'
import { Chipify } from 'common/Chipify'
import { UsersSortBy } from 'api/users'
import { FilterExportToolbar } from 'common/FilertExportToolbar'
import StarIcon from '@mui/icons-material/Star'

const rolesPriority = [0, 1]
const roles: Role[] = ['ROLE_BUYER', 'ROLE_REDACTOR', 'ROLE_ENHANCER', 'ROLE_TRUST_MINER']

const orderFilters = [
  <OutlinedSearchInput source="name" label="Search" alwaysOn />,
  <OutlinedArrayInput label="Roles" source="roles" choices={getOptionsSelectFromArray(roles, 'dict.role.')} alwaysOn />,
  <OutlinedArrayInput label="Allowed Roles" source="allowedRoles" choices={getOptionsSelectFromArray(roles, 'dict.role.')} alwaysOn />,
  <OutlinedSelectInput label='Roles Priority' source='rolesPriority' choices={getOptionsSelectFromArray(rolesPriority, 'dict.role.priorityType.')} alwaysOn />,
  <LanguageFilter alwaysOn source='sourceLanguage' label='Source Lang' />,
  <LanguageFilter alwaysOn source='targetLanguage' label='Target Lang' />,
  <LanguageFilter alwaysOn source='allowedSourceLanguage' label='Allowed Source Lang' />,
  <LanguageFilter alwaysOn source='allowedTargetLanguage' label='Allowed Target Lang' />,
  <OutlinedDateTimeInput source="lastActivityDateFrom" label='Activity From' />,
  <OutlinedDateTimeInput source="lastActivityDateTo" label='Activity To' />,
  <OutlinedDateTimeInput source="signupDateFrom" label='Signup From' />,
  <OutlinedDateTimeInput source="signupDateTo" label='Signup To' />
]

const tabs = [
  { id: 'MEMBER', name: 'Active' },
  { id: 'PENDING', name: 'Pending' },
  { id: 'SUSPENDED', name: 'Suspended' },
  { id: 'REJECTED', name: 'Rejected' }
]

const useGetTotals = (filterValues: any, resourcePath: string) => {
  const { total: totalMembers } = useGetList(resourcePath, {
    pagination: { perPage: 1, page: 1 },
    sort: { field: UsersSortBy.USER_NAME, order: 'ASC' },
    filter: { ...filterValues, membershipStatus: 'MEMBER' }
  })
  const { total: totalPending } = useGetList(resourcePath, {
    pagination: { perPage: 1, page: 1 },
    sort: { field: UsersSortBy.USER_NAME, order: 'ASC' },
    filter: { ...filterValues, membershipStatus: 'PENDING' }
  })
  const { total: totalSuspended } = useGetList(resourcePath, {
    pagination: { perPage: 1, page: 1 },
    sort: { field: UsersSortBy.USER_NAME, order: 'ASC' },
    filter: { ...filterValues, membershipStatus: 'SUSPENDED' }
  })
  const { total: totalRejected } = useGetList(resourcePath, {
    pagination: { perPage: 1, page: 1 },
    sort: { field: UsersSortBy.USER_NAME, order: 'ASC' },
    filter: { ...filterValues, membershipStatus: 'REJECTED' }
  })

  return {
    MEMBER: totalMembers,
    PENDING: totalPending,
    SUSPENDED: totalSuspended,
    REJECTED: totalRejected
  }
}

interface Props {
  setDrawerFor: (value: Identifier | undefined) => void
  drawerFor: Identifier | undefined
}

const TabbedDatagrid = ({ setDrawerFor }: Props) => {
  const listContext = useListContext()
  const { filterValues, setFilters, displayedFilters } = listContext

  const record = useRecordContext<GatedCommunityRecord>()

  const totals = useGetTotals(filterValues, `gatedCommunities/${record.id}/members`) as any

  const EditPrivilege = UserService.hasRoles(['ROLE_PLATFORM_MANAGER', 'ROLE_SUPPORT', 'ROLE_GATED_COMMUNITY_MANAGER'])

  const handleChange = useCallback(
    (event: React.ChangeEvent<any>, value: any) => {
      setFilters &&
        setFilters(
          { ...filterValues, membershipStatus: value },
          displayedFilters
        )
    },
    [displayedFilters, filterValues, setFilters]
  )

  const findRole = (record: GatedCommunityMemberSmallRecord, role: Role) => record.allowedRoles.find(allowedRole => role === allowedRole.role)

  return <>
    <Tabs
      variant="fullWidth"
      centered
      value={filterValues.membershipStatus}
      indicatorColor="primary"
      onChange={handleChange}
    >
      {tabs.map(choice => (
        <Tab
          key={choice.id}
          label={totals[choice.id] ? `${choice.name} (${totals[choice.id]})` : choice.name}
          value={choice.id}
        />
      ))}
    </Tabs>
    <Divider />
    <div>
      <ListContextProvider value={{ ...listContext }}>
        <Datagrid
          bulkActionButtons={false}
          className='assigments-datagrid'
          rowStyle={(row: GatedCommunityMemberSmallRecord) => rowStyle(row)}
        >
          <CustomLinkField source="userName" basePath='users' targetFieldId='id' show label={'Username'} sortBy={UsersSortBy.USER_NAME} />
          <CustomTranslatedTextField source='profile' translationPath='gatedCommunity.profile.' sortable={false} />
          <NumberField source='score' label={'Trust Chain'} textAlign="right" options={{ maximumFractionDigits: 1, minimumFractionDigits: 1 }} sortable={false} />
          <Chipify 
            source='roles' 
            sortable={false} 
            variant="squere-chip" 
            translation='dict.role.' 
            renderIcon={(role, row) => findRole(row, role as Role)?.priority === 1 ? <StarIcon style={{ color: "#faaf00" }} /> : undefined}
            disable={(role, row) => findRole(row, role as Role) === undefined}
          />
          {EditPrivilege && <FunctionField render={({ id }: GatedCommunityMemberSmallRecord) => <EditIcon onClick={() => setDrawerFor(id)} />} />}
        </Datagrid>
      </ListContextProvider>
    </div>
  </>
}

const MemberList = () => {
  const [drawerFor, setDrawerFor] = useState<Identifier | undefined>(undefined)
  const record = useRecordContext<GatedCommunityRecord>()

  const handleClose = useCallback(() => {
    setDrawerFor(undefined)
  }, [drawerFor])

  if (!record) return null

  return <>
    <List
      className="gray-skin dark-variant community-members"
      resource={`gatedCommunities/${record.id}/members`}
      title={'/Members'}
      filterDefaultValues={{ membershipStatus: 'MEMBER' }}
      sort={{ field: UsersSortBy.USER_NAME, order: 'ASC' }}
      perPage={50}
      pagination={<CustomPagination />}
      actions={<FilterExportToolbar showExport showFilter filters={orderFilters} />}
    >
      <TabbedDatagrid setDrawerFor={setDrawerFor} drawerFor={drawerFor} />
    </List>
    <Drawer
      variant="persistent"
      open={!!drawerFor}
      anchor="right"
      onClose={handleClose}
      sx={{ zIndex: 100 }}
    >
      {/* To avoid any errors if the route does not match, we don't render at all the component in this case */}
      {!!drawerFor && (
        <MemberEdit
          id={drawerFor}
          onClose={handleClose}
          gcName={record.name}
        />
      )}
    </Drawer>
  </>
}

export default React.memo(MemberList)