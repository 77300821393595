
import { green, orange, red } from '@mui/material/colors'

import { AdminAssignmentDashboardRecord } from 'api/assignments'

const rowStyle = (record: AdminAssignmentDashboardRecord) => {
  const style = {}
  if (!record) {
    return style
  }
  
  if (['ACCEPTED', 'DELIVERED', 'SUPERSEDED', 'FINISHED'].includes(record.status))
  {return {
    ...style,
    borderLeftColor: green[500],
    borderLeftWidth: 5,
    borderLeftStyle: 'solid'
  }}
  if (['PENDING', 'INTERRUPTED'].includes(record.status))
  {return {
    ...style,
    borderLeftColor: orange[500],
    borderLeftWidth: 5,
    borderLeftStyle: 'solid'
  }}
  if (['REJECTED', 'MISSED', 'CANCELLED'].includes(record.status))
  {return {
    ...style,
    borderLeftColor: red[500],
    borderLeftWidth: 5,
    borderLeftStyle: 'solid'
  }}
  return style
}

export default rowStyle