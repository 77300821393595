import React from 'react'
import { Show, TabbedShowLayout, Tab, useRecordContext } from 'react-admin'

import { UserRecord } from 'api/users'
import { Assignments, Profile, Skills } from './userTabs'
import UserTrustChain from './userTabs/TrustChain'

import "styles.scss"
import Tempo from './userTabs/Tempo'

const UserEdit = () => {
  return <Show title={<UserTitle />} component="div" className='user-panel'>
    <UserTabbed />
  </Show>
}

const UserTabbed = () => {
  const record = useRecordContext()
  if (!record) return null

  return <TabbedShowLayout className='gray-skin'>
    <Tab label="Profile">
      <Profile />
    </Tab>
    <Tab label="Assignments" path="assignments">
      <Assignments />
    </Tab>
    <Tab label="Skills" path="skills">
      <Skills />
    </Tab>
    <Tab label="Trust Chain" path="trustChain">
      <UserTrustChain userName={record.userName} />
    </Tab>
    <Tab label="Tempo" path="tempo">
      <Tempo userId={record.id}/>
    </Tab>
  </TabbedShowLayout>
}

const UserTitle = () => {
  const record = useRecordContext<UserRecord>()
  return record ? <span>{record.userName}</span> : null
}

export default UserEdit