import { AxiosError } from "axios"
import moment from "moment"
import "moment-timezone"

import { Currency, SelectOption } from "api/common"

export const getOptionsSelectFromArray = (array: any[], translationPath?: string): SelectOption[] => 
  array.map(ele => ({ id: ele, name: translationPath ? translationPath + ele : ele }))
  
const getUserTimeZoneName = (timezone: string) => {
  return moment.tz(timezone).zoneAbbr()
}

export const parseTimestamp = (timestamp: string | undefined | null, timezone: string, displayTimezone = true, displaySeconds = false) => {
  if (!timestamp) return null
  const isTimezonePresent = displayTimezone && timestamp !== 'Unknown'
  const format = displaySeconds ? "YYYY-MM-DD HH:mm:ss" : "YYYY-MM-DD HH:mm"
  return (moment(timestamp).tz(timezone).format(format) || "") + (isTimezonePresent ? ' ' + getUserTimeZoneName(timezone) : '')
}

export const showDateCountdown = (dueDate: string | undefined, deliveryDate?: string): string => {
  if(!dueDate) return ''

  const MINUTES_IN_DAY = 1440
  const MINUTES_IN_HOUR = 60

  let diffMinutes = Math.floor(moment.duration(moment(dueDate).diff(deliveryDate ? deliveryDate: moment())).asMinutes()) 

  const timeLeft = []
  let isDaysPresent = false

  const appendix = diffMinutes >= 0 ? 'left' : 'overdue'
  diffMinutes = Math.abs(diffMinutes)

  if(diffMinutes / MINUTES_IN_DAY > 1) {
    timeLeft.push(Math.floor(diffMinutes / MINUTES_IN_DAY) + ' day(s)')
    diffMinutes = diffMinutes % MINUTES_IN_DAY
    isDaysPresent = true
  }

  if(diffMinutes / MINUTES_IN_HOUR > 1) {
    timeLeft.push(Math.floor(diffMinutes / MINUTES_IN_HOUR) + ' hour(s)')
    diffMinutes = diffMinutes % MINUTES_IN_HOUR
  }

  if(!isDaysPresent) {
    timeLeft.push(diffMinutes + ' minute(s)')
  }

  timeLeft.push(appendix)
  return timeLeft.join(' ')
}

export const secondsToReadableUnits = (seconds: number, showLowValues: boolean): string => {
  const SECONDS_IN_MINUTE = 60 
  const SECONDS_IN_HOUR = 3600
  const SECONDS_IN_DAY = 86400

  if(seconds < 60) {
    if (showLowValues) return `${seconds} second(s)`
    return '0 minute(s)' 
  }
  let diffSeconds = seconds

  const timeLeft = []
  let isDaysPresent = false

  if(diffSeconds / SECONDS_IN_DAY >= 1) {
    timeLeft.push(Math.floor(diffSeconds / SECONDS_IN_DAY) + ' day(s)')
    diffSeconds = diffSeconds % SECONDS_IN_DAY
    isDaysPresent = true
  }

  if(diffSeconds / SECONDS_IN_HOUR >= 1) {
    timeLeft.push(Math.floor(diffSeconds / SECONDS_IN_HOUR) + ' hour(s)')
    diffSeconds = diffSeconds % SECONDS_IN_HOUR
  }

  if(diffSeconds / SECONDS_IN_MINUTE >= 1 && !isDaysPresent) {
    timeLeft.push(Math.floor(diffSeconds / SECONDS_IN_MINUTE) + ' minute(s)')
    diffSeconds = diffSeconds % SECONDS_IN_MINUTE
  }

  return timeLeft.join(' ')
}

const isAxiosError = (e: any): e is AxiosError<unknown> => {
  return (Boolean(e.isAxiosError)) && typeof e.config == "object"
}

export const hasErrorCode = (status: number, error: any) =>
  isAxiosError(error) && error.response?.status === status

export const currencyCodeToSymbol = (currency: Currency) => {
  switch (currency) {
    case 'CHF': return 'CHF'
    case 'EUR': return '€' 
    case 'USD': return '$' 
    case 'JPY': return '¥' 
    default:
      return ''
  }
}

export const parsePrice = (price: number) => (Math.floor(price * 100) / 100).toFixed(2)

export function trimTitle (title: string): string {
  return title.length > 26 ?
      (title.substring(0, 18) + "..." + title.slice(title.length - 8)) :
      title
}
