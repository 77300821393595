import React, { ReactNode } from 'react'
import 'styles.scss'

interface LabelProps {
  children: ReactNode
  noSelect?: boolean
  margin?: boolean
}

export const Label = ({ noSelect, children, margin }: LabelProps) => {
  const noSel = noSelect ? 'no-select ' : ''
  const marg = margin ? 'margin ' : ''

  return <span className={`label ${noSel} ${marg}`}>{children}</span>
}

export const Title = ({ children }: LabelProps) => {
  return <span className='title'>{children}</span>
}
