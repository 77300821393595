import React from "react"
import { Box, FormControlLabel, Modal, Radio, RadioGroup } from "@mui/material"
import { SumbitButtons } from "common/SubmitButtons"
import { RaRecord, useNotify, useRefresh } from "ra-core"
import { ModalProps } from "./common"
import { CompanyMemberRole, updateCompanyMember } from "api/company"
import { fromForm } from "common/fromForm"
import RoundedPanel from "common/RoundedPanel"

export const MemberModal = ({ record, open, onClose }: ModalProps<RaRecord>) => {
  const [role, setRole] = React.useState<string>(record.profile as string)

  const refresh = useRefresh()
  const notify = useNotify()

  const handleSave = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const newRole = fromForm<{role: CompanyMemberRole}>(new FormData(e.currentTarget))

    updateCompanyMember(record.id as string, newRole.role)
      .then(() => {
        notify('Company member updated', { type: 'success' })
      })
      .catch(() => {
        notify('Failed to update company member', { type: 'warning' })
      })
      .finally(() => {
        refresh()
      })
    onClose()
  }

  return <Modal
    open={open}
    onClose={onClose}
    disableAutoFocus={true}
  >
    <RoundedPanel modal width="370px">
      <h1>Company Role</h1>
      <Box height={13} />
      <form
        onSubmit={handleSave}
      >
        <RadioGroup
          row
          aria-label="role"
          name="role"
          value={role}
          onChange={(e) => setRole(e.target.value)}
        >
          <FormControlLabel value="ORGANIZATION_ADMIN" control={<Radio />} label="Admin" />
          <FormControlLabel value="ORGANIZATION_MEMBER" control={<Radio />} label="Member" />
        </RadioGroup>
        <hr />
        <SumbitButtons onClose={onClose} saveDisabled={role === record.profile} />
      </form>
    </RoundedPanel>
  </Modal>
}