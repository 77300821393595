import React from 'react'
import { useNotify } from 'react-admin'

import ActionButton from 'components/ActionButton'
import { propagateStage } from 'api/stages'

interface Props {
  stageId: string
}

const ReassignExecutors = ({ stageId }: Props) => {
  const notify = useNotify()
  
  const handleReassignExecutors = async () => {
    try {
      await propagateStage(stageId)
      notify('Project was successfully reassigned', { type: 'success' })
    } catch (error: any) {
      notify(error.response.data.message || 'Something went wrong', { type: 'error' })
    }
  }

  return <ActionButton onClick={handleReassignExecutors}>Reassign Exfluencers</ActionButton>
}

export default ReassignExecutors