import React, { useEffect, useState } from "react"
import { ModalProps } from "./common"
import { Box, Grid, InputAdornment, Modal } from "@mui/material"
import { useDataProvider, useNotify, useRefresh } from "react-admin"
import { CompanyRecord, HourlyRateLimits, getCompanyHourlyRateLimits } from "api/company"
import { currencySymbol } from "api/common"
import { SumbitButtons } from "common/SubmitButtons"
import RoundedPanel, { CrossCheckbox, Label } from 'common/RoundedPanel'

import 'styles.scss'
import { NormalTextField } from "common/TextField"

export const EditCompanyModal = ({ record, open, onClose, billingInfoFilled }: ModalProps & {billingInfoFilled: boolean}) => {
  const [hourlyRateLimits, setHourlyRateLimits] = useState<HourlyRateLimits | undefined>(undefined)

  const dataProvider = useDataProvider()
  const refresh = useRefresh()
  const notify = useNotify()

  useEffect(() => {
    getCompanyHourlyRateLimits(record.id as string)
      .then((response) => {
        setHourlyRateLimits(response)
      })
  }, [])

  const currencyInputProps = {
    startAdornment: <InputAdornment position="start">{
      currencySymbol(hourlyRateLimits?.currency)
    }</InputAdornment>
  }

  const handleSave = (e: any) => {
    e.preventDefault()
    
    const data: CompanyRecord = {
      ...record,
      name: e.target.name.value,
      minHourlyRate: e.target.minHourlyRate.value,
      maxHourlyRate: e.target.maxHourlyRate.value,
      paymentThreshold: e.target.paymentThreshold?.value,
      ngo: e.target.ngo.checked,
      foldingEnabled: e.target.foldingEnabled.checked
    }

    const updateQuerry = {
      id: record.id,
      data: data,
      previousData: record
    }

    dataProvider.update('companies', updateQuerry)
      .then(() => {
        refresh()
        onClose()
        notify('Company updated', { type: 'success' })
      })
      .catch(() => {
        notify('Failed to update company', { type: 'warning' })
      })

  }

  return <Modal
    open={open}
    onClose={onClose}
    disableAutoFocus={true}
  >
    <RoundedPanel modal width='600px'>
      <h1>Company details</h1>
      <Box height={13} />
      <form
        onSubmit={handleSave}
      >
        <Grid container spacing={'20px'}>
          <Grid item xs={12}>
            <NormalTextField
              id="name"
              label="Company"
              defaultValue={record.name}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <NormalTextField
              id="minHourlyRate"
              label="Min hourly rate"
              disabled={hourlyRateLimits===undefined}
              defaultValue={hourlyRateLimits?.min}
              InputProps={currencyInputProps}
              type="number"
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <NormalTextField
              id="maxHourlyRate"
              label="Max hourly rate"
              disabled={hourlyRateLimits===undefined}
              defaultValue={hourlyRateLimits?.max}
              InputProps={currencyInputProps}
              type="number"
              fullWidth
            />
          </Grid>
          {billingInfoFilled && <Grid item xs={6}>
            <NormalTextField
              id="paymentThreshold"
              label="Company payment threshold"
              disabled={hourlyRateLimits===undefined}
              InputProps={{ ...currencyInputProps, inputProps: { min: 0 } }}
              defaultValue={record.paymentThreshold}
              type="number"
              fullWidth
            />
          </Grid>}
        </Grid>
        <Box height={13} />
        <Label>Status</Label>
        <Box height={13} />
        <CrossCheckbox defaultChecked={record.ngo} id="ngo" />Accept Charity<br/>
        <CrossCheckbox defaultChecked={record.foldingEnabled} id="foldingEnabled" />Asset Folding Enabled<hr />
        <SumbitButtons onClose={onClose} />
      </form>
    </RoundedPanel>
  </Modal>
}
