import React, { useState } from 'react'
import { styled } from '@mui/material/styles'
import IconButton from '@mui/material/IconButton'
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded'
import KeyboardArrowUpRoundedIcon from '@mui/icons-material/KeyboardArrowUpRounded'
import { Table, TableCell, TableRow, TableHead, TableBody } from '@mui/material'

import { UserFile } from 'api/jobs'
import { SectionAttachmentDownload } from './SectionAttachmentDownload'

const StageRowWrapper = styled('div')`
  display:flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding-bottom: 10px;
`
const StageRow = styled('div')`
  display:flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
`
const labelStyle = {
  margin: 0,
  textAlign: 'left' as const
}

interface Props {
  files: UserFile[],
  label: string,
}
const AttachmentFileDownload = ({ label, files }: Props) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(false)

  return <StageRowWrapper>
    <StageRow>
      <IconButton onClick={() => setIsExpanded(prevState => !prevState)}>
        {isExpanded ? <KeyboardArrowUpRoundedIcon /> : <KeyboardArrowDownRoundedIcon />}
      </IconButton>
      <p style={labelStyle}>{label}</p>
    </StageRow>
    {isExpanded && <Table>
      <TableHead>
        <TableRow>
          <TableCell>File name:</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {files.map((file) => (
          <SectionAttachmentDownload attachment={file} key={file.fileId}/>
        ))}
      </TableBody>
    </Table>
    }
  </StageRowWrapper>
}

export default React.memo(AttachmentFileDownload) 