import React from "react"
import { FormControl, TextField, TextFieldProps } from "@mui/material"

export const WhiteTextField = (props: TextFieldProps) => {
  const { fullWidth, disabled } = props
  return <FormControl className="custom-array-input" fullWidth={fullWidth} disabled={disabled}>
    <TextField
      {...props}
    />
  </FormControl>
}

export const NormalTextField = (props: TextFieldProps) => {
  const { fullWidth, disabled } = props
  return <FormControl className="custom-array-input normal-height" fullWidth={fullWidth} disabled={disabled}>
    <TextField
      {...props}
    />
  </FormControl>
}