import React from 'react'
import ActionButton from 'components/ActionButton'

interface Props {
    assignmentId: string
}
const OpenEditor = ({ assignmentId }: Props) => {

  return <a href={`${process.env.REACT_APP_EDITOR_URL}/editor/${assignmentId}`} target="_blank" rel="noreferrer" style={{ textDecoration: "none" }}>
    <ActionButton>
      Show in Editor
    </ActionButton>
  </a> 
}

export default OpenEditor