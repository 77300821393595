import { Chip } from '@mui/material'
import React, { ReactElement } from 'react'
import { FunctionField, useTranslate } from "react-admin"
import get from 'lodash/get'

interface ChipifyProps {
  variant: "squere-chip" | "big-chip";
  translation?: string;
  source: string | string[];
  subsource?: string
  renderIcon?: (value: string, row?: any) => ReactElement | undefined;
  disable?: (value: string, row?: any) => boolean;
  label?: string;
  fallbackChipLabel?: string;
  sortable?: boolean;
}

function defaultIcon () {
  return undefined
}

function defaultDisable () {
  return false
}

export const Chipify = ({ variant, translation, source, subsource, renderIcon = defaultIcon, disable=defaultDisable, fallbackChipLabel }: ChipifyProps) => {
  const translate = useTranslate()
  const chip = (value: string, row?: any) => (
    <Chip
      key={value}
      className={variant}
      label={translation && translate(`${translation}${value}`) || value}
      icon={renderIcon(value, row)}
      disabled={disable(value, row)}
    />
  )

  const rolesToChip = (record?: any) => {
    if (typeof source === 'string') {
      const chips = get(record, source)
        .map((chiplabel: any) => subsource && chip(chiplabel[subsource], record) || chip(chiplabel, record))
      return chips.length > 0 ? chips : (fallbackChipLabel && <Chip
        key={fallbackChipLabel}
        className={variant}
        label={fallbackChipLabel}
      />)
    }
    return chip(record as string, undefined)
  }

  if (typeof source === 'string') {
    return <FunctionField source={source} render={rolesToChip} />
  }
  return <>{source.map(rolesToChip)}</>
}