import React, { useEffect, useState } from 'react'
import { useNotify, useTranslate } from 'ra-core'

import { TableCell, TableRow } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import MenuItem from '@mui/material/MenuItem'
import ButtonGroup from '@mui/material/ButtonGroup'
import Popper from '@mui/material/Popper'
import Grow from '@mui/material/Grow'
import Paper from '@mui/material/Paper'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import MenuList from '@mui/material/MenuList'

import { StageJobSection, StageType } from 'api/common'
import { SimpleStageRecord } from 'api/jobs'
import { FileType, downloadJobFile, requestFileGeneration, FileNotReadyException, FileNotFoundException } from 'api/stages'
import { DownloadType, FileInfo } from './types'

interface Options {
  key: string,
  text: string,
  icon: string
  value: DownloadType
  file: FileInfo
}

const RETRY_TIMEOUT = 5000

interface Props {
  stage: SimpleStageRecord,
  section: StageJobSection,
  targetLang: string
}

const SectionFileDownload = ({ stage, section, targetLang }: Props) => {
  const [resultFile, setResultFile] = useState<FileInfo>()
  const [otherFiles, setOtherFiles] = useState<Options[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [open, setOpen] = useState(false)

  const notify = useNotify()
  const anchorRef = React.useRef<HTMLDivElement>(null)

  const translate = useTranslate()

  const fileTypeToExtension = (original: string, fileType: FileType) => {
    const extension = original.split('.').pop()
    switch (fileType) {
      case "RESULT": return extension
      case "XLIFF2": return "xlf"
      case "XLIFF1": return "xlf"
      case "BILANG_DOCX": return "docx"
      case "TRANSLATED_DOCX": return "docx"
      case "UPLOADED": return extension
      default: return extension
    }
  }
  const replaceExtension = (original: string, fileType: FileType) => {
    const extension = fileTypeToExtension(original, fileType)
    return original.replace(/\.[^/.]+$/, `.${extension}`)
  }

  useEffect(() => {
    setOtherFiles([])
    init()
  }, [])

  const init = () => {
    const { id, type } = stage
    addFiles(id, type, section.readyFileTypes, true, 'file', section.id, section.name)

    const filtered = section.availableFileTypes.filter(i => !section.readyFileTypes.includes(i))
    addFiles(id, type, filtered, false, 'file outline', section.id, section.name)
  }

  const addFiles = (stageId: string, stageType: StageType, fileTypes: FileType[], isReady: boolean, icon: string, jobSectionId: string, jobSectionName: string) => {
    fileTypes.forEach((fileType) => {
      const file: FileInfo = { stageId, stageType, isReady, fileType, jobSectionId, fileName: replaceExtension(jobSectionName, fileType) }
      if (fileType === "RESULT") {
        setResultFile(file)
      } else {
        setOtherFiles(o => [...o, { key: fileType, icon, file, text: translate(`dict.file.${fileType}`), value: fileType }])
      }
    })
  }

  const getFile = (value: DownloadType) => {
    const file = otherFiles.find(f => f.file.fileType == value)?.file
    setOpen(false)

    if (!file) return
    downloadFile(file)
  }

  const downloadFile = async (file: FileInfo) => {
    const { fileType, stageId, jobSectionId, fileName } = file
    const fileNameWithoutExtension = fileName.substring(0, fileName.lastIndexOf("."))
    const extension = fileName.substring(fileName.lastIndexOf(".")+1, fileName.length)
    const fileNameWithSuffix = `${fileNameWithoutExtension}_${targetLang.toLowerCase()}.${extension}`
    try {
      setLoading(true)
      await downloadJobFile(stageId, fileType, jobSectionId, fileNameWithSuffix)
    } catch (e) {
      if (e instanceof FileNotReadyException || e instanceof FileNotFoundException) {
        notify("notifications.fileNotExists", { type: 'info', autoHideDuration: RETRY_TIMEOUT })
        await generateFile(file)
        setTimeout(() => { downloadJobFile(stageId, fileType, jobSectionId, fileNameWithSuffix) }, RETRY_TIMEOUT)
      } else {
        notify("notifications.fileError", { type: 'error' })
      }
    } finally {
      setLoading(false)
    }
  }

  const generateFile = async ({ stageId, fileType, jobSectionId }: FileInfo) => {
    try {
      await requestFileGeneration(stageId, fileType, jobSectionId)
    } catch (e) { }
  }

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
  }

  const handleClose = (event: Event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) return
    setOpen(false)
  }

  return <TableRow key={stage.id}>
    <TableCell>{section.name}</TableCell>
    {resultFile && <>
      <ButtonGroup ref={anchorRef}>
        <LoadingButton onClick={() => downloadFile(resultFile)} variant="contained" loading={loading} disabled={loading || !resultFile.isReady}>
          {translate(`dict.file.${resultFile.fileType}`)}
        </LoadingButton>
        <LoadingButton size="small" onClick={handleToggle} variant="contained" disabled={loading || !resultFile.isReady || !otherFiles.length}>
          &#8595;
        </LoadingButton>
      </ButtonGroup>
      <Popper sx={{ zIndex: 1 }} open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom'
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  {otherFiles.map((option) => (
                    <MenuItem key={option.value} onClick={() => getFile(option.value)}>
                      {option.text}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
    }
  </TableRow>
}

export default React.memo(SectionFileDownload)
