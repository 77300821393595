import FeatureParameterIcon from '@mui/icons-material/SettingsApplicationsOutlined'

import FeatureParameterEdit from './FeatureParameterEdit'
import FeatureParameterList from './FeatureParameterList'

const resource = {
  list: FeatureParameterList,
  edit: FeatureParameterEdit,
  icon: FeatureParameterIcon
}

export default resource