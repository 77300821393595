import React, { useEffect, useState } from 'react'
import { getTableauToken } from './BiApi'
import axios from 'axios'

interface TableauEmbeddingWithTokenProps {
  url: string
}

const TableauEmbeddingWithToken: React.FC<TableauEmbeddingWithTokenProps> = ({ url }) => {
  const [token, setToken] = useState<string | null | undefined>(undefined)

  useEffect(() => {
    const fetchAndStoreToken = async () => {
      try {
        const t = await getTableauToken()
        setToken(t)
      } catch (error) {
        if (axios.isAxiosError(error) && error.response?.status == 404) {
          console.log("Received 404 when requesting Tableau token - Tableau is not configured")
          setToken(null)
        } else {
          console.error('Error fetching token:', error)
        }
      }
    }

    fetchAndStoreToken()
  }, [])

  if (token === undefined) {
    return <div>Loading...</div>
  } else if (token === null) {
    return <div>Tableau not configured</div>
  }

  return (
    <tableau-embedding
      url={url}
      token={token}
    ></tableau-embedding>
  )
}

export default TableauEmbeddingWithToken
