import React from 'react'
import { FieldProps, useRecordContext, useTranslate } from 'react-admin'
import Tooltip from '@mui/material/Tooltip'

import { trimTitle } from 'utils/helpers'

interface Props extends FieldProps {
  translationPath?: string
  trim?: boolean
}

const CustomTranslatedTextField = ({ translationPath, trim, source }: Props) => {
  const translate = useTranslate()
  const record = useRecordContext()

  const transformText = (record: any, source: any) => {
    return trim ? <Tooltip title={record[source] || ''}><span>{trimTitle(record[source] || '')}</span></Tooltip> : record[source] || ''
  }

  return (record && source) ? <div> 
    {translationPath ? translate(`${translationPath}${record[source]}`) : transformText(record, source)}
  </div> : null
}

export default CustomTranslatedTextField