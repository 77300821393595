import React from 'react'
import { EditButton, List, TextField } from 'react-admin'

import CustomTranslatedTextField from 'common/CustomTranslatedTextField'
import SortedByTranslatedIdDatagrid from './SortedByTranslatedIdDatagrid'

const FeatureParameterList = () => {
  return <List exporter={false} pagination={false}>
    <SortedByTranslatedIdDatagrid translationPath="dict.featureParam." >
      <CustomTranslatedTextField source="id" label={'Feature'} sortable={false} translationPath="dict.featureParam." />
      <TextField source='value' label={'Value'} sortable={false} />
      <EditButton />
    </SortedByTranslatedIdDatagrid>
  </List>
}

export default React.memo(FeatureParameterList)