import React from 'react'
import { Datagrid, List, useRecordContext, TextField, FunctionField } from 'react-admin'

import { UserRecord } from 'api/users'
import { CustomPagination } from 'common'
import 'styles.scss'
import { Box } from '@mui/material'

interface Props {
  disableSyncWithLocation?: boolean
  title?: string
}

const UserSkills = ({ disableSyncWithLocation, title = '/skills' }: Props) => {
  const record = useRecordContext<UserRecord>()

  if (!record) return null

  return <>
    <Box margin="60px 0px" >
      <List
        resource={`users/${record.id}/genres`}
        exporter={false}
        disableSyncWithLocation={true}
        empty={<div>No genres yet.</div>}
        actions={false}
        pagination={false}
        title={" "}
      >
        <Datagrid bulkActionButtons={false} className="assigments-datagrid small-row">
          <TextField source="genre" label='Genre' sortable={false} />
        </Datagrid>
      </List>
    </Box>
    <Box margin="60px 0px" >
      <List
        resource={`users/${record.id}/skills`}
        perPage={50}
        pagination={<CustomPagination />}
        title={title}
        exporter={false}
        disableSyncWithLocation={disableSyncWithLocation}
        empty={<div>No skills yet.</div>}
        actions={false}
      >
        <Datagrid bulkActionButtons={false} className="assigments-datagrid small-row">
          <TextField source="langFrom" label='Source' sortable={false} />
          <TextField source="langTo" label='Target' sortable={false} />
          <TextField source="currency" sortable={false} />
          <TextField source="price" sortable={false} textAlign="right" />
          <FunctionField source="taxonomy" render={(record: any) => record.taxonomy.join(', ')} sortable={false} />
        </Datagrid>
      </List>
    </Box>
  </>
}

export default UserSkills