import { Box, Tooltip } from '@mui/material'
import React from 'react'
import { Button } from 'react-admin'

import 'styles.scss'

interface SubmitButtonsProps {
  onClose?: () => void
  onSave?: () => void
  onDelete?: () => void
  onCloseLabel?: string
  onDeleteDisabled?: boolean
  onDeleteDisabledMessage?: string
  onSaveLabel?: string
  onDeleteLabel?: string
  isLoading?: boolean
  saveDisabled?: boolean
}

export const SumbitButtons = ({ onClose, onSave, onDelete, onCloseLabel, onSaveLabel, onDeleteLabel, isLoading, saveDisabled, onDeleteDisabled: onCloseDisabled, onDeleteDisabledMessage: onCloseDisabledMessage }: SubmitButtonsProps) => {
  const deleteButton = <span><Button
    variant="contained"
    className='outlined-button'
    color="secondary"
    label={ onDeleteLabel || 'Delete' }
    disabled={isLoading || onCloseDisabled}
    onClick={onDelete}
    sx={{
      marginLeft: '10px',
      width: 'auto',
      height: '33px'
    }}
  /></span>

  return (
    <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', marginTop: '25px'}}>
      <Box>
        <Button
          variant="contained"
          className='outlined-button'
          color="primary"
          label={ onSaveLabel || 'Save' }
          type="submit"
          disabled={ isLoading || saveDisabled }
          onClick={onSave}
          sx={{
            marginRight: '10px',
            width: '70px',
            height: '33px'
          }}
        />
        <Button
          variant="contained"
          className='outlined-button'
          color="secondary"
          label={ onCloseLabel || 'Discard' }
          disabled={isLoading}
          onClick={onClose}
          sx={{
            width: '70px',
            height: '33px'
          }}
        />
      </Box>
      <Box>
        {onDelete && onCloseDisabledMessage && onCloseDisabled && <Tooltip title={onCloseDisabledMessage} >{deleteButton}</Tooltip>}
        {onDelete && !onCloseDisabled && deleteButton}
      </Box>
    </Box>
  )
}