import React from 'react'
import { FieldProps, useRecordContext } from "react-admin"
import VisibilityIcon from '@mui/icons-material/Visibility'
import { IconButton } from '@mui/material'

const CustomShowInEditorButton = ({ source }: FieldProps) => {
  const record = useRecordContext()
  
  return (record && source && record[source]) ? 
    <a href={`${process.env.REACT_APP_EDITOR_URL}/editor/${record[source]}`} target="_blank" rel="noreferrer">
      <IconButton aria-label='open in editor'>
        <VisibilityIcon/>
      </IconButton>
    </a> :
   null
}

export default CustomShowInEditorButton