import React, { useEffect, useState } from 'react'
import { Modal, Box, Autocomplete, TextField } from '@mui/material'
import { CompanySearchRecord, getSearchCompany } from 'api/company'
import { changeUserCompany } from 'api/users'
import { useNotify, useRefresh } from 'ra-core'

import 'styles.scss'
import { ModalProps } from './common'
import { SumbitButtons } from 'common/SubmitButtons'
import RoundedPanel from 'common/RoundedPanel'

interface CompanySearchOptionRecord {
  id: string
  label: string
}

export const ChangeCompanyModal = ({ record, open, onClose }: ModalProps) => {
  const userId = record.id
  const { company } = record

  const notify = useNotify()
  const refresh = useRefresh()

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [searchDelay, setSearchDelay] = useState<ReturnType<typeof setTimeout> | undefined>(undefined)
  const [companies, setCompanies] = useState<CompanySearchOptionRecord[]>([])
  const [chosenCompany, setChosenCompany] = useState<CompanySearchOptionRecord | undefined>(undefined)
  const [currentCompany, setCurrentCompany] = useState<string>(company?.name || '')

  const [inputValue, setInputValue] = useState<string>('')

  useEffect(() => {
    if(open) {
      setChosenCompany(undefined)
      setInputValue('')
      setCurrentCompany(company?.name || '')
    }
  }, [open])
  
  const convertRecordsToOptions = (records: CompanySearchRecord[]) : CompanySearchOptionRecord[] => 
    records.map(({ id, name }) => ({ label: name, id }))

  const fetchCompanies = async (search: string) => {
    setIsLoading(true)
    try {
      const response = await getSearchCompany(search)
      setCompanies(convertRecordsToOptions(response))
    } catch (error) {
      notify('Something went wrong during searching for companies.', { type: 'error' })
    }
    setIsLoading(false)
  }

  const handleTextChange = (_: any, value: string | null) => {
    setInputValue(value || '')
    if(searchDelay) {
      clearTimeout(searchDelay)
    } 
    setSearchDelay(setTimeout(() => fetchCompanies(value || ''), 500))
  }

  const handleOptionChange = (_: any, value: any | undefined) => {
    setChosenCompany(value)
  }

  const handleChangeCompany = () => {
    if(!chosenCompany) return
    setIsLoading(true)

    changeUserCompany(userId, chosenCompany.id).then(() => {
      notify('Company was successfully changed.', { type: 'success' })
      setCurrentCompany(chosenCompany?.label)
    }).catch(() => {
      notify('Something went wrong', { type: 'error' })
    }).finally(() => {
      refresh()
      onClose()
      setIsLoading(false)
    })
  }

  return <Modal
    open={open}
    onClose={onClose}
    disableAutoFocus={true}
  >
    <RoundedPanel modal width="600px">
      <h1>Change Company</h1> 
      <Box height={32} />
      <Autocomplete
        className='custom-array-input normal-height'
        options={companies}
        getOptionLabel={(option) => option.label}
        filterOptions={(options) => options.filter(({ label }) => label !== currentCompany )}
        value={chosenCompany}
        inputValue={inputValue}
        loading={isLoading}
        onClickCapture={() => fetchCompanies(inputValue)}
        onInputChange={handleTextChange}
        onChange={handleOptionChange}
        isOptionEqualToValue={(e1, e2) => e1.id === e2.id }
        renderInput={(params) => <TextField {...params} label="Search" />}
        noOptionsText='No companies found'
      />
      <hr/>
      <SumbitButtons onClose={onClose} onSave={handleChangeCompany} />
    </RoundedPanel>
  </Modal>
}

