/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable prefer-destructuring */
import React from "react"

import { Button } from "@mui/material"

import './style.css'

import FileUploadItem from "./FileUploadItem"

const MAX_FILE_SIZE = 100 * 1000 * 1000

interface FileUploadProps {
  onFileUploaded: (file: File | null) => void
  onError: (size: string) => void
  initFilePath?: string
}
const FileUpload: React.FC<FileUploadProps> = (props: FileUploadProps) => {

  const [file, setFile] = React.useState<File | null>()
  const [isDragOver, setDragOver] = React.useState<boolean>(false)

  const handleChange = (e: any) => {
    const file = e.target.files[0]
    if (file) { readFile(file) }
  }

  const clearFile = () => {
    setFile(null)
    props.onFileUploaded(null)
  }

  const renderTitle = () => {
    if (isDragOver) return (<h3>Drop file here</h3>)
    if (file) return <FileUploadItem file={file} onFileRemoved={clearFile} />
    if (props.initFilePath && file !== null) return <FileUploadItem initSvgPath={props.initFilePath} onFileRemoved={clearFile} />
    return (<h3>Drag or upload SVG file</h3>)
  }

  const onDrop = (e: React.DragEvent<HTMLElement>) => {
    e.preventDefault()
    e.stopPropagation()
    const file = e.dataTransfer.files[0]
    if (file) {
      setDragOver(false)
      if ('image/svg+xml' != file.type) return
      readFile(file)
    }
  }

  const onDragEnter = (e: React.DragEvent) => {
    e.preventDefault()
    e.stopPropagation()
  }

  const onDragOver = (e: React.DragEvent<HTMLElement>) => {
    e.preventDefault()
    e.stopPropagation()
    setDragOver(true)
  }

  const onDragLeave = (e: React.DragEvent) => {
    e.preventDefault()
    e.stopPropagation()
    setDragOver(false)
  }

  const readFile = (file: any) => {
    if (file.size > MAX_FILE_SIZE) {
      props.onError(formatFileSize(MAX_FILE_SIZE))
      return
    }
    setFile(file)
    props.onFileUploaded(file)
  }

  return (
    <div className="file-upload--container">
      <input
        type="file"
        onChange={handleChange}
        id="file-upload"
        className="none"
        accept={'image/svg+xml'}
      />
      <div className="file-upload--description">
        {renderTitle()}
        <Button className="file-upload--btn">{file ? "Replace file" : "Upload file"
        }</Button>
      </div>
      <label
        htmlFor="file-upload"
        className={`file-upload--backdrop ${isDragOver && 'file-upload--drop'}`}
        onDrop={onDrop} onDragOver={onDragOver} onDragEnter={onDragEnter} onDragLeave={onDragLeave}
      ></label>
    </div>
  )
}

export default FileUpload

export const formatFileSize = (size: number): string => {
  if (size < 10000) {
    return `${size} B`
  } else if (size < 100000) {
    return `${(size / 1000).toFixed(2)} KB`
  }
  return `${(size / 1000000).toFixed(2)} MB`
}