import React, { FormEvent } from "react"
import { Box, Grid, Modal } from "@mui/material"
import RoundedPanel, { CrossCheckbox } from "common/RoundedPanel"
import { ModalProps } from "./common"
import { SimpleGatedCommunityRecord, GatedCommunityRecord } from "api/gatedCommunity"
import { NormalTextField } from "common/TextField"
import { OutlinedNoSourceArrayInput } from "common/OutlinedNoSourceArrayInput"
import { SumbitButtons } from "common/SubmitButtons"
import { useDataProvider, useRefresh, useNotify } from "ra-core"

export const EditGC = ({ record, open, onClose }: ModalProps<SimpleGatedCommunityRecord>) => {
  const [recyclableTranslationLevels, setRecyclableTranslationLevels] = React.useState<string[]>(record.recyclableTranslationLevels)
  const [isLoading, setIsLoading] = React.useState<boolean>(false)

  const dataProvider = useDataProvider()
  const refresh = useRefresh()
  const notify = useNotify()

  const handleSave = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    
    const data: SimpleGatedCommunityRecord = {
      ...record,
      name: e.currentTarget.GCname.value,
      recyclableTranslationLevels: recyclableTranslationLevels,
      anonymizationEnabled: e.currentTarget.anonymizationEnabled.checked
    }

    const updateQuery = {
      id: record.id,
      data: data,
      previousData: record
    }

    setIsLoading(true)

    dataProvider
      .update<GatedCommunityRecord>("gatedCommunities", updateQuery)
      .then(() => {
        notify('Gated Community was successfully updated.', { type: 'success' })
      })
      .catch((e) => {
        notify(e.message || 'Something went wrong', { type: 'error' })
      })
      .finally(() => {
        setIsLoading(false)
        refresh()
        onClose()
      })
  }
  return <Modal
    open={open}
    onClose={onClose}
    disableAutoFocus={true}
  >
    <RoundedPanel modal width="500px">
      <h2>Edit Gated Community</h2>
      <hr />
      <form
        onSubmit={handleSave}
      >
        <Grid container spacing={'20px'}>
          <Grid item xs={12}>
            <NormalTextField
              id="GCname"
              label="Gated Community Name"
              defaultValue={record.name}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <OutlinedNoSourceArrayInput
              label="Recyclable Translation Levels"
              onChange={setRecyclableTranslationLevels}
              defaultValue={recyclableTranslationLevels}
              choices={[
                { id: 'TRUST_MINED', name: 'Trust Minined' },
                { id: 'ENHANCED', name: 'Enhanced' }
              ]}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <CrossCheckbox defaultChecked={record.anonymizationEnabled} id="anonymizationEnabled" />Anonymization Enabled
          </Grid>
        </Grid>
        <Box height={20} />
        <hr />
        <SumbitButtons 
          isLoading={isLoading}
          onClose={onClose}
        />
      </form>

    </RoundedPanel>
  </Modal>
}