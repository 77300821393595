import React, { useEffect } from "react"
import { useInput, useTranslate } from "react-admin"

import { getOptionsSelectFromArray } from "utils/helpers"
import { languages } from "./dictonary"
import { Autocomplete, Chip, FormControl, TextField } from "@mui/material"
import { SelectOption } from "api/common"
import { FilterIcon } from "./Icons"

interface LanguageAutocompleteProps {
  label: string
  initValue?: SelectOption
  onChange: (newValue: any) => void
  disabled?: boolean
  allowedOptions?: SelectOption[]
}

export const LanguageAutocomplete = ({ label, initValue: defaultValue, onChange, disabled, allowedOptions }: LanguageAutocompleteProps) => {
  const translate = useTranslate()
  const options = allowedOptions || getOptionsSelectFromArray(languages, 'dict.lang.')
  const [selectedItem, setSelectedItem] = React.useState<SelectOption | null>(defaultValue || null)

  return <FormControl className="custom-array-input language-autocomplete">
    <Autocomplete
      disabled={disabled}
      options={options}
      isOptionEqualToValue={(option, value) => option.id == value.id}
      value={selectedItem}
      onChange={(event, newValue) => {
        setSelectedItem(newValue)
        onChange(newValue)
      }}
      getOptionLabel={(option) => translate(option.name)}
      renderInput={(params) => <TextField
        {...params}
        label={label}
        InputLabelProps={{ shrink: true }}
      />} />
  </FormControl>
}

interface LanguageMultiAutocompleteProps {
  label: string
  initValue?: SelectOption[]
  onChange: (newValue: any) => void
}

export const LanguageMultiAutocomplete = ({ label, initValue, onChange }: LanguageMultiAutocompleteProps) => {
  const translate = useTranslate()
  const options = getOptionsSelectFromArray(languages, 'dict.lang.')
  const [selectedItems, setSelectedItems] = React.useState<SelectOption[]>(initValue || [])

  return <FormControl className="language-autocomplete">
    <Autocomplete
      multiple
      options={options}
      isOptionEqualToValue={(option, value) => option.id == value.id}
      value={selectedItems}
      onChange={(event, newValue) => {
        setSelectedItems(newValue)
        onChange(newValue)
      }}
      getOptionLabel={(option) => translate(option.name)}
      renderInput={(params) => <TextField
        {...params}
        InputLabelProps={{ shrink: true }}
        label={label}
      />} 
      renderTags={(value, getTagProps) =>
        value.map((option: SelectOption, index: number) => (
          <Chip variant="outlined" label={translate(option.name)} {...getTagProps({ index })} 
            className="big-chip"
            deleteIcon={
              <FilterIcon
                type="gray-x"
                onMouseDown={(event) => event.stopPropagation()} />
            }/>
        ))}/>
  </FormControl>
}

interface LanguageFilterProps {
  source: string
  label: string
  alwaysOn: boolean
}

const LanguageFilter = ({ label, source }: LanguageFilterProps) => {
  const { field } = useInput({ source: source })
  const [selectedItem, setSelectedItem] = React.useState<SelectOption | null>(field.value || null)

  useEffect(() => {
    field.onChange(selectedItem)
  }, [selectedItem])

  return <LanguageAutocomplete label={label}
    onChange={(newValue) => { setSelectedItem(newValue) }}
    initValue={selectedItem || undefined} />
}

const LanguageFilterMemo = React.memo(LanguageFilter)

export default LanguageFilterMemo