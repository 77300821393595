import React from 'react'
import './arrow.css'
import { Box } from '@mui/material'

export const Arrow = () => {
  return (
    <Box>
      <div className="vertical">
        <div className="grot grot-left-bottom"></div>
        <div className="grot grot-right-bottom"></div>
        <div className="grot grot-left-top"></div>
        <div className="grot grot-right-top"></div>
      </div>
    </Box>
  )
}