import React from 'react'

import { ExtendedJobRecord, getJobLogs } from 'api/jobs'
import UserService from 'UserService'
import { CancelJob, ChangeDeadlines, DownloadFiles, OpenEditor, Logs, ReassignExecutors, SendReminder, OpenRCA } from './actions'
import { useRecordContext } from 'ra-core'
import { Box } from '@mui/material'

const JobShowActions = () => {
  const record = useRecordContext<ExtendedJobRecord>()

  if(!record) return null

  const pendingStageId = record.stages.filter((stage) => ['NO_EXECUTORS', 'PENDING_ACCEPTANCE'].includes(stage.status))[0]?.id

  return <Box display='flex' gap="10px" flexWrap='wrap'>
    {record.lastAccessibleAssignmentId && <OpenEditor assignmentId={record.lastAccessibleAssignmentId}/>}
    <DownloadFiles job={record} />
    {UserService.hasRoles(['ROLE_EXFLUENCY_ADMIN']) && <Logs getLogsPromise={() => getJobLogs(record.id)} />}
    {UserService.hasRoles(['ROLE_PLATFORM_MANAGER', 'ROLE_GATED_COMMUNITY_MANAGER']) &&
      (record.status === 'BLOCKED' || record.status === 'IN_PROGRESS') && pendingStageId &&
       <ReassignExecutors stageId={pendingStageId} />}
    {(record.cancellable && UserService.hasRoles(['ROLE_PLATFORM_MANAGER'])) && <CancelJob jobId={record.id} name={record.name} /> }
    {UserService.hasRoles(['ROLE_PLATFORM_MANAGER', 'ROLE_GATED_COMMUNITY_MANAGER']) && <ChangeDeadlines job={record} />}
    {UserService.hasRoles(['ROLE_PLATFORM_MANAGER', 'ROLE_GATED_COMMUNITY_MANAGER']) && <SendReminder job={record} />}
    {record.rcas !== undefined && <OpenRCA jobId={record.id}/>}
  </Box>
}

export default React.memo(JobShowActions)