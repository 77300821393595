import React, { useState } from "react"

import { useNotify, useUnselectAll, useRefresh } from "ra-core"
import { useListContext, Identifier, Button } from "react-admin"

import SettingsIcon from '@mui/icons-material/Settings'
import { alpha, styled } from "@mui/material/styles"
import Dialog from "@mui/material/Dialog"
import DialogTitle from "@mui/material/DialogTitle"
import Input from "@mui/material/Input"
import DialogContent from "@mui/material/DialogContent"
import DialogContentText from "@mui/material/DialogContentText"
import DialogActions from "@mui/material/DialogActions"
import MuiButton from "@mui/material/Button"

import { changeJobRequester } from "api/jobs"

const DeleteButton = styled(Button)(({ theme }) => ({
  deleteButton: {
    color: theme.palette.error.main,
    '&:hover': {
      backgroundColor: alpha(theme.palette.error.main, 0.12),
      // Reset on mouse devices
      '@media (hover: none)': {
        backgroundColor: 'transparent'
      }
    }
  }
})
)

const noSelection: Identifier[] = []

const defaultIcon = <SettingsIcon />

const BulkDeleteWithConfirmButton = () => {
  const { selectedIds = noSelection } = useListContext()
  const [isOpen, setOpen] = useState(false)
  const [userId, setUserId] = useState<string | undefined>(undefined)
  const notify = useNotify()
  const unselectAll = useUnselectAll('jobs')
  const refresh = useRefresh()

  if(!selectedIds) return null

  const changeRequesters = () => {
    if(!userId) return

    handleDialogClose()

    Promise.all(
      selectedIds.map((jobId: any) => changeJobRequester(jobId, userId))
    ).then(() => {
      refresh()
      notify('ra.notification.updated', {
        type: 'info',
        messageArgs: { smart_count: selectedIds.length }
      })
      unselectAll()
      setUserId(undefined)
    }).catch(error => {
      notify(
        typeof error === 'string' ?
            error :
            error.message || 'ra.notification.http_error',
        {
          type: 'warning',
          messageArgs: {
            _:
              typeof error === 'string' ?
                  error :
                  error && error.message ?
                  error.message :
                  undefined
          }
        }
      )
      setOpen(false)
    })
  }

  const handleClick = (e: any) => {
    setOpen(true)
    e.stopPropagation()
  }

  const handleDialogClose = () => {
    setOpen(false)
  }

  const handleDelete = () => {
    changeRequesters()
  }

  const onUserInputChange = (value: string) => {
    setUserId(value)
  }

  return <>
    <DeleteButton onClick={handleClick}>
      <>{defaultIcon}CHANGE REQUESTER</>
    </DeleteButton>
    <Dialog
      open={isOpen}
      onClose={handleDialogClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle>
        Change Requester
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          User ID: <Input value={userId} onChange={(event) => onUserInputChange(event.target.value)} />
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <MuiButton onClick={handleDialogClose}>Close</MuiButton>
        <MuiButton variant='contained' disabled={!userId} onClick={handleDelete}>
            Submit
        </MuiButton>
      </DialogActions>
    </Dialog>
  </>
}

export default BulkDeleteWithConfirmButton