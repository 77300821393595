import React, { useCallback } from 'react'
import { BooleanField, Datagrid, FunctionField, List, ListContextProvider, Loading, useGetList, useListContext } from 'react-admin'

import Divider from '@mui/material/Divider'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'

import CustomDateField from 'common/CustomDateField'
import CustomLinkField from 'common/CustomLinkField'
import { currencyCodeToSymbol, parsePrice } from 'utils/helpers'
import CustomPagination from 'common/CustomPagination'

import 'styles.scss'
import { Box, Chip } from '@mui/material'

const tabs = [
  { id: 'EXPENSES', name: 'Expenses' },
  { id: 'EARNINGS', name: 'Earnings' }
]

const useGetTotals = (filterValues: any) => {
  const { total: totalExpenses } = useGetList('invoices', {
    pagination: { perPage: 1, page: 1 },
    sort: { field: 'creationDate', order: 'ASC' },
    filter: { ...filterValues, type: 'EXPENSES' }
  }
  )
  const { total: totalEarnings } = useGetList('invoices', {
    pagination: { perPage: 1, page: 1 },
    sort: { field: 'creationDate', order: 'ASC' },
    filter: { ...filterValues, type: 'EARNINGS' }
  }
  )

  return {
    EXPENSES: totalExpenses,
    EARNINGS: totalEarnings
  }
}

const TabbedDatagrid = () => {
  const listContext = useListContext()
  const { filterValues, setFilters, displayedFilters, isLoading, isFetching } = listContext

  const totals = useGetTotals(filterValues) as any

  const handleChange = useCallback(
    (_: React.ChangeEvent<any>, value: any) => {
      setFilters &&
        setFilters(
          { ...filterValues, type: value },
          displayedFilters
        )
    },
    [displayedFilters, filterValues, setFilters]
  )

  const table = <Datagrid bulkActionButtons={false} className='assigments-datagrid'>
    <CustomLinkField source="id" targetFieldId='id' label='Invoice Number' basePath='invoices' show sortable={false} />
    <CustomDateField source="startDate" label={'Start Date'} displaySeconds />
    <CustomDateField source="endDate" label={'End Date'} displaySeconds />
    <CustomDateField source="creationDate" label={'Created'} displaySeconds />
    <FunctionField source='sum' render={(row: any) => <b>{currencyCodeToSymbol(row.sum.currency)}{parsePrice(row.sum.value)}</b>} textAlign='right' />
    <BooleanField source='pdf' label={'PDF'} />
    <BooleanField source='accepted' label={'Accepted'} />
    <CustomDateField source="acceptanceDate" label={'Acceptance Date'} displaySeconds />
  </Datagrid>

  return <>
    <Tabs
      variant="fullWidth"
      className='gray-skin'
      value={filterValues.type}
      onChange={handleChange}
    >
      {tabs.map(choice => (
        <Tab
          key={choice.id}
          label={<>
            <Box height="30px">
              {choice.name}
              <Chip label={totals[choice.id]} className='squere-chip' />
            </Box>
          </>}
          value={choice.id}
        />
      ))}
    </Tabs>
    <Divider />
    <div>
      <ListContextProvider value={{ ...listContext }}>
        {(isLoading || isFetching) ? <Loading /> : table}
      </ListContextProvider>
    </div>
  </>
}

const InvoiceList = () => (
  <Box margin="0px 64px">
    <List
      filterDefaultValues={{ type: 'EXPENSES' }}
      sort={{ field: 'creationDate', order: 'DESC' }}
      perPage={50}
      pagination={<CustomPagination />}
      className='no-shadow'
    >
      <TabbedDatagrid />
    </List>
  </Box>
)

export default React.memo(InvoiceList)