import FeatureSwitchIcon from '@mui/icons-material/ToggleOn'

import FeatureSwitchesEdit from './FeatureSwitchesEdit'
import FeatureSwitchesList from './FeatureSwitchesList'

const resource = {
  list: FeatureSwitchesList,
  edit: FeatureSwitchesEdit,
  icon: FeatureSwitchIcon
}

export default resource