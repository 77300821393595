import React from 'react'

import ActionButton from 'components/ActionButton'

interface Props {
  ticketId: number
}

const ReminderTicket = ({ ticketId }: Props) => {

  return <a href={"https://exfluency.zendesk.com/agent/tickets/"+ticketId} rel="noreferrer" target="_blank" >
    <ActionButton>Reminder Ticket</ActionButton>
  </a>
}

export default ReminderTicket