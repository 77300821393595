import React, { ReactElement, ReactNode } from 'react'
import { useTranslate, useSidebarState } from 'react-admin'

import { styled } from '@mui/material/styles'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import MenuItem from '@mui/material/MenuItem'
import MuiListItemIcon from '@mui/material/ListItemIcon'
import Typography from '@mui/material/Typography'
import List from '@mui/material/List'
import Collapse from '@mui/material/Collapse'
import Tooltip from '@mui/material/Tooltip'

const ListItemIcon = styled(MuiListItemIcon)(({ theme }) => `
  min-width: ${theme.spacing(5)}  
`)

interface Props {
  dense: boolean;
  handleToggle: () => void;
  icon: ReactElement;
  isOpen: boolean;
  name: string;
  children: ReactNode;
}

const SubMenu = (props: Props) => {
  const { handleToggle, isOpen, name, icon, children, dense } = props
  const translate = useTranslate()
  const [sidebarIsOpen] = useSidebarState()

  const header = (
    <MenuItem dense={dense} onClick={handleToggle}>
      <ListItemIcon sx={{ minWidth: 5 }}>
        {isOpen ? <ExpandMoreIcon /> : icon}
      </ListItemIcon>
      <Typography variant="inherit" color="textSecondary">
        {translate(name)}
      </Typography>
    </MenuItem>
  )

  return <>
    {sidebarIsOpen || isOpen ? header :
      <Tooltip title={translate(name)} placement="right">
        {header}
      </Tooltip>
    }
    <Collapse in={isOpen} timeout="auto" unmountOnExit>
      <List
        dense={dense}
        component="div"
        disablePadding
        sx={{
          '& a': {
            transition: 'padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
            paddingLeft: sidebarIsOpen ? 4 : 2
          }
        }}
      >
        {children}
      </List>
    </Collapse>
  </>
}

export default SubMenu