import React, { useEffect } from 'react'
import { useRecordContext } from 'react-admin'
import { CircularProgress } from '@mui/material'
import { ExtendedJobRecord } from 'api/jobs'
import ErrorIcon from '@mui/icons-material/Error'

interface Props {
  source: (record: any) => Promise<React.ReactNode>
  label?: string
}
/**
 * Example usage
 * ```tsx
 * <CustomTextField source={async (record) => {
 *     // wait from 1 to 5 seconds to get the value
 *     return await new Promise<string>(resolve => setTimeout(() => resolve(record.name), Math.random() * 4000 + 1000))
 *  }} label={'Loads after few seconds...'} />
 * ```
 */
const CustomTextField = ({ source }: Props) => {
  const record = useRecordContext<ExtendedJobRecord>()

  const [isLoading, setIsLoading] = React.useState(true)
  const [isError, setIsError] = React.useState(false)
  const [data, setData] = React.useState<any>(null)

  useEffect(() => {
    if (!record) return

    fetchData()
      .catch(() => setIsError(true))
      .finally(() => setIsLoading(false))

    async function fetchData () {
      setData(await source(record))
    }
  }, [record])

  const error = (<div><ErrorIcon /></div>)
  const progress = (<div><CircularProgress size={14} /></div>)
  const dataDiv = (<div>{data}</div>)

  return isError ? error : (record ? <div> {isLoading ? progress : dataDiv} </div> : null)
}

export default CustomTextField