import { AdminStageDashboardRecord } from 'api/stages'

const rowStyle = (record: AdminStageDashboardRecord) => {
  const style = {}
  if (!record) {
    return style
  }
  if (record.status === 'DONE')
  {return {
    ...style,
    backgroundColor: 'rgba(232, 245, 233, 0.3)',
    backgroundColorAplha: 0.1
  }}
  if (['IN_PROGRESS', 'PENDING_ACCEPTANCE', 'STARTED', 'NO_EXECUTORS'].includes(record.status))
  {return {
    ...style,
    backgroundColor: 'rgba(255, 243, 224, 0.5)'
  }}
  if (['CANCELLED', 'ERROR'].includes(record.status))
  {return {
    ...style,
    backgroundColor: 'rgba(255, 235, 238, 0.5)'
  }}
  return style
}

export default rowStyle