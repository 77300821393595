import React from 'react'
import { Checkbox } from '@mui/material'
import { Done, Close } from '@mui/icons-material'

interface CrossCheckboxProps {
  checked?: boolean;
  defaultChecked?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  disableRipple?: boolean;
  id?: string;
  name?: string
}

export const CrossCheckbox = ({ checked, onChange, disabled, disableRipple, defaultChecked, id, name }: CrossCheckboxProps) => {
  return <Checkbox
    checked={checked}
    className="action-checkbox"
    onChange={onChange}
    defaultChecked={defaultChecked}
    disabled={disabled}
    disableRipple={disableRipple}
    id={id}
    name={name}
    icon={<div><Close /></div>}
    checkedIcon={<div><Done /></div>}
    sx={{
      marginRight: "18px"
    }}
  />
}