import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import { styled } from '@mui/material/styles'
import { ExtendedJobRecord, UserFile, allAttachmentsDescription, getProjectFiles } from 'api/jobs'
import { useNotify } from 'ra-core'
import React, { useEffect, useState } from 'react'
import StageFileDownload from './StageFileDownload'
import ActionButton from 'components/ActionButton'
import AttachmentFileDownload from './AttachmentFileDownload'

const FileDialogContainer = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const FileDialogSection = styled('div')`
  display: block;
  width: 100%;
  margin: 5px;
  padding: 10px;
  text-align: center;
`

const StyledDialogContent = styled(DialogContent)`
  min-width: 700px;
  min-height: 500px;
`
interface Props {
  job: ExtendedJobRecord
}

const FileDownloadModal: React.FC<Props> = ({ job }) => {
  const [isModalDownloadOptions, setIsModalDownloadOptions] = useState<boolean>(false)
  const [jobAttachments, setJobAttachments] = useState<UserFile[]>([])

  const notify = useNotify()

  const handleDownloadOptionsOpen = () => {
    setIsModalDownloadOptions(true)
  }

  const handleDownloadAll = async () => {
    try {
      await getProjectFiles(job.id)
    } catch (e) {
      notify('notifications.error', { type: 'error' })
    }
  }

  useEffect(() => {
    allAttachmentsDescription(job.id).then((attachments) => {
      setJobAttachments(attachments)
    })
  }, [])

  const showAttachmentSection = jobAttachments.length > 0

  const attachmentSection = <>
    <FileDialogSection>
      <h3>Project Files</h3>
      <AttachmentFileDownload label='Reference Files' files={jobAttachments} />
    </FileDialogSection>
  </>

  const stageSection = <>
    <FileDialogSection>
      <h3>Stage files</h3>
      {job.stages
        .filter(({ type }) => !['TABLE_PARSING', 'DOCUMENT_PARSING', 'ANONYMIZATION', 'FILE_PREPARATION', 'FILE_PARSING'].includes(type))
        .filter(({ status }) => status !== 'SUPERSEDED')
        .map(stage => <StageFileDownload stage={stage} key={stage.id} targetLang={job.targetLang}/>)
      }
      <hr />
      <Button variant='contained' onClick={handleDownloadAll}>Download all</Button>
    </FileDialogSection>
  </>

  return <>
    <ActionButton onClick={() => handleDownloadOptionsOpen()}>Download files</ActionButton>
    <Dialog open={isModalDownloadOptions} onClose={() => setIsModalDownloadOptions(false)} maxWidth={'lg'}>
      <StyledDialogContent>
        <FileDialogContainer>
          {showAttachmentSection && attachmentSection}
          {stageSection}
        </FileDialogContainer>
      </StyledDialogContent>
      <DialogActions>
        <Button color='secondary' onClick={() => { setIsModalDownloadOptions(false) }}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  </>

}

export default FileDownloadModal