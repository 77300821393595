import axios, { CancelTokenSource } from "axios"
import { Currency, Genre } from "./common"

export interface CompanyInvoiceGenerate {
  invoicingPeriod: {
    startDate: string
    endDate: string
  },
  companyId: string
}

export interface CompanyRecord {
  id: string
  name: string
  membersCount: number,
  filled: boolean,
  ngo: boolean,
  foldingEnabled: boolean,
  paymentThreshold?: number,
  minHourlyRate?: number,
  maxHourlyRate?: number,
}

export interface CompanySearchRecord {
  id: string
  name: string
}

export interface CompanyBillingInformation {
  financialInfo?: CompanyFinancialInfo,
  contactPerson?: ContactPerson,
  paymentMethods: CompanyPaymentMethodVariant[]
}

export interface CompanyFinancialInfo {
  name: string,
  vatNum: string,
  bankAccount: string,
  address: Address,
  currency: Currency,
  paymentThreshold?: number
}

export interface Address {
  country: string,
  city: string,
  street: string,
  streetNum: string,
  postalCode: string,
  state?: string
}

export interface ContactPerson {
  name: string,
  surname: string,
  mail: string,
  phone: string
}

export type PaypalMethod = {
  paymentMethodId: string | null,
  paypalUser: string,
  isDefault: boolean,
}
export type BankMethod = {
  paymentMethodId: string | null,
  bankName: string,
  accountNumber: string,
  bic: string,
  routingNumber: string | null,
  isDefault: boolean,
}
export type IbanMethod = {
  paymentMethodId: string | null,
  bankName: string,
  iban: string,
  accountHolder: string,
  isDefault: boolean,
}

export type CompanyPaymentMethodVariant = {
  paypalPayment: PaypalMethod,
} | {
  bankPayment: BankMethod,
} | {
  ibanPayment: IbanMethod,
}

export interface HourlyRateLimits {
  min: number,
  max: number,
  currency: Currency
}

export async function generateCompanyInvoice (invoiceData: CompanyInvoiceGenerate) {
  await axios.put<void>(`/api/admin/companies/${invoiceData.companyId}/generateInvoice`, invoiceData)
}

export async function getCompanyFinancialData (companyId: string) {
  const response = await axios.get<CompanyBillingInformation>(`/api/admin/companies/${companyId}/billing`)
  return response.data
}

export async function getCompanyHourlyRateLimits (companyId: string) {
  const response = await axios.get<HourlyRateLimits>(`/api/admin/companies/${companyId}/hourlyRateLimits`)
  return response.data
}

export async function getSearchCompany (search: string) {
  const response = await axios.get<CompanySearchRecord[]>(`/api/admin/companies/search`, {
    params: { name: search }
  })
  return response.data
}

export type CompanyMemberRole = "ORGANIZATION_ADMIN" | "ORGANIZATION_MEMBER"

export async function updateCompanyMember (memberId: string, newRole: CompanyMemberRole) {
  await axios.put<void>(`/api/admin/companies/members/${memberId}`, { newRole: newRole })
}

export interface CompanyPortalProperties {
  companyId: string | null,
  showInstant: boolean,
  showEnhance: boolean,
  showTrustmine: boolean,
  showDiscoverMe: boolean,
  showBi: boolean,
  allowedLanguageList: string[],
  defaultLanguageCombinationTranslateAndCreateFrom: string,
  defaultLanguageCombinationTranslateAndCreateTo: string,
  defaultLanguageCombinationFileFrom: string,
  defaultLanguageCombinationFileTo: string,
  genre: Genre,
  taxonomy: string
}

export async function getCompanyPortalProperties (companyId: string) {
  const response = await axios.get<CompanyPortalProperties>(`/api/admin/companies/${companyId}/portal-properties`)
  return response.data
}

export async function setCompanyPortalProperties (companyPortalProperties: CompanyPortalProperties) {
  await axios.put<void>(`/api/admin/companies/${companyPortalProperties.companyId}/portal-properties`, companyPortalProperties )
}

export async function setCompanyPortalLogo (companyId: string, svgFile: any) {
  const form = new FormData()
  form.append("file", svgFile)
  await axios.put<void>(`/api/admin/companies/${companyId}/portal-logo`, form)
}

export async function getCopanyPortalLogoPath (companyId: string) {  
  const response = await axios.get<string>(`/api/admin/companies/${companyId}/portal-logo-path`)
  return response.data
}

export async function searchTaxonomy (query: string, sourceRef: { current: CancelTokenSource } | undefined) {  
  let token = undefined
  if (sourceRef) {
    const { CancelToken } = axios
    const source = CancelToken.source()
    token = { cancelToken: source.token }
    sourceRef.current = source
  }
  const response = await axios.get<any>(`/api/workflow/my_exfluency/taxonomy?query=${query}`, token)
  return response.data
}