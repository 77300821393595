import React from 'react'
import { Pagination } from 'react-admin'

interface Props {
  rowsPerPageOptions?: number[]
}

const CustomPagination = (props: Props) => 
  <Pagination rowsPerPageOptions={[5, 10, 25, 50]} {...props} className='custom-pagination'/>

export default CustomPagination