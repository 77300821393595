import CompanyIcon from '@mui/icons-material/Business'

import CompanyShow from './CompanyShow'
import CompanyList from './CompanyList'

const resource: Record<string,any> = {
  list: CompanyList,
  show: CompanyShow,
  icon: CompanyIcon
}

export default resource