import { createTheme } from "@mui/material/styles"

export const theme = createTheme({
  palette: {
    primary: {
      main: '#1976d2',
      contrastText: '#fff'
    },
    secondary: {
      main: '#2B3990',
      contrastText: '#fff'
    }
  },
  typography: {
    fontFamily: '"Inter", sans-serif'
  },
  components: { 
    MuiLink: {
      styleOverrides: {
        root: {
          color: '#3f45f7'
        }
      }
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          marginTop: '10px'
        }
      },
      defaultProps: {
        InputLabelProps: { shrink: true }
      }
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          marginTop: '10px'
        }
      },
      defaultProps: {
        InputLabelProps: { shrink: true }
      }
    }
  }
} as any)
