import axios from 'axios'

export enum AggregationType {
  SUM = 'SUM',
  AVG = 'AVG',
  CNT = 'CNT'
}

export interface MetricAggregationDto {
  name: string;
  recentValue: number | undefined;
  totalValue: number | undefined;
}

export async function getMetric(metricName: string, companyId: string, aggregationType: AggregationType, filterPropertyName: string | null = null, filterPropertyValue: string | null = null): Promise<MetricAggregationDto> {
  
  const params = {
    metricName,
    aggregationType,
    companyId,
    filterPropertyName: filterPropertyName,
    filterPropertyValue: filterPropertyValue 
  }

  const response = await axios.get<MetricAggregationDto>('/api/admin/metrics', { params })
  return response.data
}