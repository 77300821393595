import React from 'react'
import { Datagrid, FieldProps, List, RaRecord, useRecordContext, useTranslate } from 'react-admin'

import CustomLinkField from 'common/CustomLinkField'
import CustomPagination from 'common/CustomPagination'
import { Chip } from '@mui/material'
import { EditIcon } from 'common/Icons'
import { MemberModal } from './companyModals/MemberModal'
import { Chipify } from 'common/Chipify'
import { OutlinedSearchInput } from 'common/OutlinedSearchInput'
import { FilterExportToolbar } from 'common/FilertExportToolbar'

const orderFilters = [
  <OutlinedSearchInput source='q' alwaysOn label="Search"/>
]

type CompanyRoleProps = FieldProps & {
  callback: (record: RaRecord) => void
}

const CompanyRole = ({ callback }: CompanyRoleProps) => {
  const record = useRecordContext()
  const t = useTranslate()

  const row = <Chip
    className='squere-chip'
    deleteIcon={<EditIcon />}
    label={t(`company.profile.${record?.profile}`)}
    onDelete={() => {
      callback(record)
    }}
  />

  return record ? row : null
}

const Members = () => {
  const [open, setOpen] = React.useState<RaRecord | undefined>()

  return <>
    {open && <MemberModal record={open} open={open !== undefined} onClose={() => { setOpen(undefined) } } />}
    <Datagrid bulkActionButtons={false} className='assigments-datagrid'>
      <CustomLinkField source="userName" basePath='users' targetFieldId='id' show label={'Username'} />
      <CompanyRole callback={setOpen} />
      <Chipify source='roles' translation="dict.role." variant='squere-chip' />
    </Datagrid>
  </>
}

const MemberList = () => {
  const record = useRecordContext()

  if (!record) return null

  return <List
    resource={'companies/members'}
    title={'/Members'}
    filterDefaultValues={{ companyId: record.id }}
    sort={{ field: 'userName', order: 'ASC' }}
    perPage={50}
    actions={<FilterExportToolbar filters={orderFilters} showFilter showExport />}
    pagination={<CustomPagination />}
  >
    <Members />
  </List>
}

export default React.memo(MemberList)