import axios from "axios"

import { ProfileStatus, Role } from "api/common"

export enum UsersSortBy {
  USER_NAME = 'USER_NAME',
  LAST_NAME = 'LAST_NAME',
  CREATION_DATE = 'CREATION_DATE'
}
export interface UserSimpleRecord {
  id: string,
  userName: string,
  firstName?: string,
  lastName?: string,
  timezone: string,
  company?: UserCompanyRecord,
  signupDate?: string,
  outOfOffice: boolean,
  isActive: boolean,
  isNdaSigned: boolean,
  isAccountConfirmed: boolean,
  roles: Role[],
  score: number,
}

export interface UserRecord {
  userName: string,
  id: string,
  firstName?: string,
  lastName?: string,
  email?: string,
  timezone: string,
  isNdaSigned: boolean,
  outOfOffice: boolean,
  isActive: boolean,
  isAccountConfirmed: boolean,
  linkedIn?: string,
  communityManager?: CommunityManagerShortRecord,
  company?: UserCompanyRecord,
  roles: Role[],
  lastActivityDate?: string,
  acceptsCharity: boolean,
  latenessStats?: LatenessStats,
  rcaStats?: RCAStats
}

export interface LatenessStats{
  latest: number,
  total: number
}

export interface RCAStats{
  latest: number,
  total: number
}

export interface UserDetailsRecord {
  id: string,
  gatedCommunities: UserGatedCommunityRecord[],
  enhancementRejected: number,
  enhancementClaimed: number,
  trustMiningRejected: number,
  trustMiningClaimed: number,
  assetsDone?: number,
}

export interface CommunityManagerShortRecord {
  id: string,
  name: string
}
export interface CommunityManager {
  id: string,
  login: string, 
  name: string,
  surname: string,
}

export interface UserCompanyRecord {
  id: string,
  name: string,
  profile: ProfileStatus
}

export interface UserGatedCommunityRecord {
  id: string,
  name: string,
  profile: ProfileStatus
}

export interface UserTrustChainData {
  id: string,
  exfluenscore?: number,
  languagePairs: LanguagePairRecord[],
  taxonomyKeys: TaxonomyKeyRecord[]
}

export interface LanguagePairRecord {
  languageFrom: string,
  languageTo: string,
  assetCount: number,
  score: number
}

export interface TaxonomyKeyRecord {
  taxonomyKey: string,
  assetCount: number,
  score: number
}

export async function changeUserCompany (userId: string, companyId: string) {
  await axios.put<void>(`/api/admin/users/${userId}/company/${companyId}`)
}

export async function getUserTrustChain (userName: string) {
  const response = await axios.get<UserTrustChainData>(`/api/workflow/assignment/trustChain/${userName}`)
  return response.data
}