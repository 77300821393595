import React from 'react'
import { Toolbar, SaveButton, DeleteWithConfirmButton } from "react-admin"

interface Props {
  isDeleteBtn?: boolean
  isSaveButton?: boolean
  deleteConfirmTitle?: string
}

const CustomEditToolBar = ({ isSaveButton = true, isDeleteBtn = false, deleteConfirmTitle = '' }: Props) => {
  return <Toolbar style={{ display: 'flex', justifyContent: 'space-between' }}>
    {isSaveButton && <SaveButton />}
    {isDeleteBtn && <DeleteWithConfirmButton confirmTitle={deleteConfirmTitle} />}
  </Toolbar>
}

export default CustomEditToolBar