export const stageTranslations = {
  stage: {
    type: {
      ENHANCEMENT: "Enhancement",
      OCR: "OCR",
      RECYCLING: "Immediate Translation",
      DOCUMENT_PARSING: "Document Parsing",
      TABLE_PARSING:"Table Parsing",
      PARSING: "Parsing",
      ANONYMIZATION: "Anonymization",
      REDACTION: "Redaction",
      TRUST_MINING: "Trust Mining",
      TRUST_MINING_2: "Trust Mining II",
      CORRECTION: "Correction",
      FINAL_REDACTION: "Final Redaction",
      FILE_PREPARATION: "File Preparation",
      FILE_PARSING: "File Parsing"
    },
    status: {
      DONE: "Done",
      ERROR: "Error",
      IN_PROGRESS: "In progress",
      PENDING_ACCEPTANCE: "Pending acceptance",
      WAITING: "Waiting",
      STARTED: "Started",
      NO_EXECUTORS: "Lacking Exfluencers",
      ACCEPTED: "Accepted",
      PENDING: "Pending",
      CANCELLED: "Cancelled",
      SUPERSEDED: "Superseded",
      INVALIDATED: "Invalidated"
    },
    selection: {
      auto: "automatic selection",
      manual: "manual selection",
      insourcing: "Insourcing",
      return: "previous Exfluencer"
    },
    selectionRow: {
      auto: "Automatic",
      manual: "Manual",
      insourcing: "Insourcing",
      return: "Previous Exfluencer"
    }
  }
}