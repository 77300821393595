export const rcaTranslations = { 
  rca: {
    status: {
      REQUESTED: "REQUESTED",
      IN_PROGRESS: "IN PROGRESS",
      COMPLETED: "COMPLETED",
      APPROVED: "APPROVED",
      REJECTED: "REJECTED"
    },
    trueTranslationError: {
      ADDITIONS_AND_DELETIONS: "Additions & Deletions", 
      MISTRANSLATIONS: "Mistranslations",
      TERMINOLOGY: "Terminology",
      FORMATTING: "Formatting",
      GRAMMAR: "Grammar"
    },
    correctiveAndPreventiveAction: {
      DIRECT_EMAIL: "Direct e-mail",
      GLOSSARY_UPDATED: "Glossary updated",
      INDIVIDUAL_RETRAINING: "Individual retraining",
      NEWSLETTER: "Newsletter",
      LANG_COMBINATION_REMOVED_FROM_GC: "Lang. combination removed from GC",
      LINGUISTIC_ASSET_UPDATED: "Linguistic asset updated",
      QUIZ: "Quiz",
      R_AND_D: "R&D",
      ROLE_DEACTIVATED: "Role deactivated",
      WARNING: "Warning",
      WEBINAR: "Webinar",
      OTHER: "Other"
    },
    rcaResposibleUserByStageType: {
      REDACTION: "Redactor",
      ENHANCEMENT: "Enhancer",
      TRUST_MINING: "Trust Miner",
      FINAL_REDACTION: "Final Redactor"
    }
  }
}