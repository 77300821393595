import React from 'react'
import { Edit, useTranslate, BooleanInput, useRecordContext, SimpleForm } from 'react-admin'

import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import CustomEditToolBar from 'common/CustomEditToolbar'

const FeatureSwitchesEdit = () => {
  return <Edit title={<FeatureSwitchesTitle />} component="div">
    <FeatureSwitchesForm />
  </Edit>
}

const FeatureSwitchesTitle = () => {
  const record = useRecordContext()
  return <span>{record?.name}</span>
}

const FeatureSwitchesForm = () => {
  const record = useRecordContext()
  const translate = useTranslate()

  return <SimpleForm toolbar={<CustomEditToolBar />} > 
    <Card style={{ width: '100%' }}>
      <CardContent>
        <Box display={{ md: 'block', lg: 'flex' }}>
          <Box flex={2} mr={{ md: 0, lg: '1em' }}>
            <Typography variant="h6" gutterBottom>
              {translate(`dict.featureSwitch.${record.id}`)}
            </Typography>
            <Box display={{ xs: 'block', sm: 'flex' }}>
              <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                <BooleanInput source="value" label="Off/On" fullWidth />
              </Box>
            </Box>
          </Box>
        </Box>
      </CardContent>
    </Card>
  </SimpleForm>
}

export default FeatureSwitchesEdit