import React, { useEffect, useState } from 'react'

import { MaxExecutorAssignments, MaxExecutorAssignmentsPerLanguagePair, getLimitsOfMaxExecutorAssignments, getLimitsOfMaxExecutorAssignmentsPerLanguagePair } from 'api/gatedCommunity'

import { PerStageTypeLimit } from './PerStageTypeLimit'
import { PerLanguagePairLimit } from './PerLanguagePairLimit'
import RoundedPanel from 'common/RoundedPanel'
import { useDataProvider } from 'react-admin'

export interface StageLimitsParams {
  stageType: 'REDACTION' | 'ENHANCEMENT' | 'TRUST_MINING'
  executorRole: 'Redactor' | 'Enhancer' | 'Trust Miner'
  featureParam: 'RedactorMaxProjects' | 'EnhancerMaxProjects' | 'TrustMinerMaxProjects'
}

const redaction: StageLimitsParams = { stageType: 'REDACTION', executorRole: 'Redactor', featureParam: 'RedactorMaxProjects' }
const enhancement: StageLimitsParams = { stageType: 'ENHANCEMENT', executorRole: 'Enhancer', featureParam: 'EnhancerMaxProjects' }
const trustmining: StageLimitsParams = { stageType: 'TRUST_MINING', executorRole: 'Trust Miner', featureParam: 'TrustMinerMaxProjects' }

export interface MaxExecutorAssignmentsForStageLimitsParams {
  stageLimitParams: StageLimitsParams
  communityId: string
  perStageLimits: MaxExecutorAssignments[];
  perLanguagePairLimits: MaxExecutorAssignmentsPerLanguagePair[];
  onChange: () => void
  onLanguageSpecificChange: () => void
}

const MaxExecutorAssignmentsForStageLimits = (params: MaxExecutorAssignmentsForStageLimitsParams) => {
  const [paramValue, setParamValue] = useState<number | null>(null)
  const { perStageLimits, stageLimitParams, onLanguageSpecificChange } = params
  const maxAmountPerStage = perStageLimits.filter(limit => limit.stageType == stageLimitParams.stageType)[0]?.maxAmount
  const defaultMaxAmount = maxAmountPerStage || paramValue  

  const dataProvider = useDataProvider()

  useEffect(() => {
    dataProvider
      .getOne(`features/parameters`, { id: `${stageLimitParams.featureParam}` })
      .then(({ data }) => { setParamValue(data.value) })
  }, [])

  return <> {
    defaultMaxAmount && <RoundedPanel padding='10px' margin='5px'>
      <PerStageTypeLimit {...params} maxAmountPerStage={maxAmountPerStage} defaultMaxAmount={defaultMaxAmount} />
      <PerLanguagePairLimit {...params} onChange={onLanguageSpecificChange} defaultMaxAmount={defaultMaxAmount} />
    </RoundedPanel>
  } </>
}

const LimitsTab = ({ communityId }: { communityId: string }) => {
  const [maxExecutorAssignmentsPerLanguagePair, setMaxExecutorAssignmentsPerLanguagePair] = useState<MaxExecutorAssignmentsPerLanguagePair[]>([])
  const [maxExecutorAssignments, setMaxExecutorAssignments] = useState<MaxExecutorAssignments[]>([])

  const fetchLimitsOfMaxExecutorAssignments = () => {
    getLimitsOfMaxExecutorAssignments(communityId)
      .then((response) => { setMaxExecutorAssignments(response) })
  }

  const fetchLimitsOfMaxExecutorAssignmentsPerLanguagePair = () => {
    getLimitsOfMaxExecutorAssignmentsPerLanguagePair(communityId)
      .then((response) => { setMaxExecutorAssignmentsPerLanguagePair(response) })
  }

  useEffect(fetchLimitsOfMaxExecutorAssignments, [])
  useEffect(fetchLimitsOfMaxExecutorAssignmentsPerLanguagePair, [])

  const commonParams = {
    communityId: communityId,
    perStageLimits: maxExecutorAssignments,
    perLanguagePairLimits: maxExecutorAssignmentsPerLanguagePair,
    onChange: () => { fetchLimitsOfMaxExecutorAssignments() },
    onLanguageSpecificChange: () => { fetchLimitsOfMaxExecutorAssignmentsPerLanguagePair() }
  }

  return <>
    <MaxExecutorAssignmentsForStageLimits stageLimitParams={enhancement} {...commonParams} />
    <MaxExecutorAssignmentsForStageLimits stageLimitParams={redaction} {...commonParams} />
    <MaxExecutorAssignmentsForStageLimits stageLimitParams={trustmining} {...commonParams} />
  </>
}

export default React.memo(LimitsTab)