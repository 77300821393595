import React, { useState } from 'react'
import { useNotify, useRefresh } from 'react-admin'

import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogActions from '@mui/material/DialogActions'
import Input from '@mui/material/Input'

import { manuallyChangeExecutor } from 'api/assignments'
import { hasErrorCode } from 'utils/helpers'
import CustomButton from 'components/CustomButton'

interface Props {
  assignmentId: string
  fileName: string
}

const ChangeExecutor = ({ assignmentId, fileName }: Props) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [userId, setUserId] = useState<string | undefined>(undefined)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const notify = useNotify()
  const refresh = useRefresh()

  const handleExecutorReassign = async () => {
    if(!userId) return
    setIsLoading(true)
    try {
      await manuallyChangeExecutor(assignmentId, userId)
      notify('Successfully changed executor', { type: 'success' })
    } catch (error) {
      if(hasErrorCode(404, error)) {
        notify('There is no such user with that id.', { type: 'error' })
      } else if(hasErrorCode(409, error)) {
        notify("User doesn't have permissions to access assignment.", { type: 'error' })
      } else if(hasErrorCode(403, error)) {
        notify("You don't have permissions to change executor of this assignment", { type: 'error' })
      } else {
        notify('Something went wrong', { type: 'error' })
      }
    }
    handleClose()
    setIsLoading(false)
  }

  const handleClose = () => {
    setIsOpen(false)
    refresh()
  }

  const handleOpen = () => {
    setIsOpen(true)
  }

  const onUserInputChange = (value: string) => {
    setUserId(value)
  }

  return <div>
    <CustomButton onClick={handleOpen}>Change Executor</CustomButton>
    <Dialog open={isOpen}>
      <DialogTitle>
        Change Exfluencer
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <p>Project {fileName}</p>
          User ID: <Input value={userId} onChange={(event) => onUserInputChange(event.target.value)} />
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
        <Button variant='contained' disabled={!userId || isLoading} onClick={handleExecutorReassign}>
            Submit
        </Button>
      </DialogActions>
    </Dialog>
  </div>
}

export default ChangeExecutor