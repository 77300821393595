import axios from "axios"
import { AssignmentStatus, Currency } from "api/common"

export interface AdminAssignmentDashboardRecord {
  id: string,
  stageId: string,
  executorId: string,
  executor: string,
  status: AssignmentStatus,
  sourceLanguage: string,
  targetLanguage: string,
  progress: number,
  uploadDate: string,
  dueDate: string,
  claimDate?: string,
  deliveryDate?: string,
  fiat: Currency,
  price: number,
  estimatedSeconds: number,
  workedSeconds: number,
  trustChainScore?: number,
  type: string,
  paidInputWordCount: number | null
}

export interface AssignmentFull {
  id: string,
  stageId: string,
  executorId: string,
  executor: string,
  status: AssignmentStatus,
  sourceLanguage: string,
  targetLanguage: string,
  progress: number,
  uploadDate: string,
  dueDate: string,
  claimDate?: string,
  deliveryDate?: string,
  fiat: Currency,
  price: number,
  estimatedSeconds: number,
  workedSeconds: number,
  trustChainScore?: number,
  type: string,
  reminderTicketId?: number,
  priceOverrideAllowed: boolean
}

export interface AssignmentJobSection{
  id: string,
  name: string,
  totalAssets?: number,
  completedAssets?: number
}

export async function changeAssignmentDeadline (assignmentId: string, deadline: string) {
  await axios.post(`/api/admin/assignments/${assignmentId}/changeDeadline`, deadline)
}

export async function manuallyChangeExecutor (assignmentId: string, userId: string) {
  await axios.post(`/api/admin/assignments/${assignmentId}/reassign`, undefined, {
    params: { newExecutor: userId }
  })
}

export async function rejectAssignment (assignmentId: string, explanation: string) {
  await axios.put(`/api/workflow/workflow/${assignmentId}/pass`, undefined, {
    params: { forceAutoAssign: true, explanation }
  })
}

export async function getAssignmentInterruptionPriceOverrideSuggestion (assignmentId: string) {
  const response = await axios.get<number>(`/api/admin/assignments/${assignmentId}/interruptionPriceOverrideSuggestion`)
  return response.data
}

export async function interruptAssignment (assignmentId: string, price: number) {
  await axios.post(`/api/admin/assignments/${assignmentId}/interrupt`, undefined, {
    params: { price }
  })
}

export async function changeAssignmentPrice (assignmentId: string, price: number) {
  await axios.post(`/api/admin/assignments/${assignmentId}/changePrice`, undefined, {
    params: { price }
  })
}