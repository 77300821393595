import React, { useState } from 'react'
import moment from 'moment'
import { useListContext } from 'react-admin'

import DatePicker from '@mui/lab/DesktopDatePicker'
import TextField from '@mui/material/TextField'

interface Props {
  label: string
  source: string
  enableFuture?: boolean
}

const CustomDateInput = ({ label, source, enableFuture }: Props) => {
  const listContext = useListContext()
  const { filterValues, setFilters, displayedFilters } = listContext
  const [date, setDate] = useState<string | null>(filterValues[source] || null)
  const [dateChangeDelay, setDateChangeDelay] = useState<ReturnType<typeof setTimeout> | undefined>(undefined)

  const handleDateChange = (newDate: string | null) => {
    setDate(newDate)
  }

  const handleInputDateChange = (newDate: string | null) => {
    setDate(newDate)
    if(dateChangeDelay) {
      clearTimeout(dateChangeDelay)
    }
    setDateChangeDelay(setTimeout(() => handleAccept(newDate), 500))
  }

  const handleAccept = (newDate: any) => {
    if(!source) return
    const filterDate = moment(newDate).isValid() ? moment(newDate).format('YYYY-MM-DD') : null 
    setFilters({ ...filterValues, [source]: filterDate }, displayedFilters)
  }
  
  return <div style={{ marginBottom: '8px' }}>
    <DatePicker
      renderInput={(props) => <TextField value={date} onChange={({ target }) => handleInputDateChange(target.value)} variant='filled' {...props} />}
      label={label}
      inputFormat='YYYY-MM-DD'
      mask='____-__-__' 
      clearable
      value={date}
      disableFuture={!enableFuture}
      onChange={handleDateChange}
      onAccept={handleAccept}
    />
  </div>
}
  
export default CustomDateInput