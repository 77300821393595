import React, { useState } from 'react'
import { useNotify } from 'react-admin'
import { createLimitOfMaxExecutorAssignmentsPerLanguagePair, deleteLimitOfMaxExecutorAssignmentsPerLanguagePair, updateLimitOfMaxExecutorAssignmentsPerLanguagePair } from 'api/gatedCommunity'
import { StageType } from 'api/common'
import RoundedPanel from 'common/RoundedPanel'
import { Box, Grid, Modal } from '@mui/material'
import { SumbitButtons } from 'common/SubmitButtons'
import { NormalTextField } from 'common/TextField'
import { LanguageAutocomplete } from 'common/LanguageFilter'

interface EditLanguagePairLimitModalProps {
  communityId: string
  limitId?: string
  stageType: StageType
  open: boolean
  fixedSourceLanguage?: string
  fixedTargetLanguage?: string
  executorRole: string
  defaultValue?: number
  onClose: () => void
  onSave: () => void
}

export const EditLanguagePairLimitModal = ({ limitId, communityId, stageType, open, onClose, onSave, fixedSourceLanguage, fixedTargetLanguage, executorRole, defaultValue }: EditLanguagePairLimitModalProps) => {
  const notify = useNotify()
  const [sourceLanguage, setSourceLanguage] = useState<string>(fixedSourceLanguage || "")
  const [targetLanguage, setTargetLanguage] = useState<string>(fixedTargetLanguage || "")

  const handleSave = (e: any) => {
    e.preventDefault()
    const handleSuccess = () => {
      onSave()
      onClose()
      notify('Limit for language pair updated', { type: 'success' })
    }
    const handleFailure = () => { notify('Failed to update language pair limit', { type: 'warning' }) }
    limitId && updateLimitOfMaxExecutorAssignmentsPerLanguagePair(communityId, limitId, {
      stageType, sourceLanguage, targetLanguage, maxAmount: e.target.maxAmount.value
    })
      .then(handleSuccess)
      .catch(handleFailure) ||
      createLimitOfMaxExecutorAssignmentsPerLanguagePair(communityId, {
        stageType, sourceLanguage, targetLanguage, maxAmount: e.target.maxAmount.value
      })
        .then(handleSuccess)
        .catch(() => {
          notify('Failed to update limit', { type: 'warning' })
        })
  }

  const handleDelete = () => {
    limitId && deleteLimitOfMaxExecutorAssignmentsPerLanguagePair(communityId, limitId)
      .then(() => {
        onSave()
        onClose()
        notify('Limit for language pair deleted', { type: 'success' })
      })
  }

  return <Modal
    open={open}
    onClose={onClose}
    disableAutoFocus={true}
  >
    <RoundedPanel modal width="600px">
      <h1>Edit max amount of projects for {executorRole}</h1>
      <Box height={13} />
      <form onSubmit={handleSave}>
        <Grid container spacing={'20px'}>
          <Grid item xs={6}>
            <LanguageAutocomplete label='Source Lang' onChange={(newValue) => setSourceLanguage(newValue.id)}
              initValue={fixedSourceLanguage && { id: fixedSourceLanguage, name: `dict.lang.${fixedSourceLanguage}` } || undefined}
              disabled={!!fixedSourceLanguage} />
          </Grid>
          <Grid item xs={6}>
            <LanguageAutocomplete label='Target Lang' onChange={(newValue) => setTargetLanguage(newValue.id)}
              initValue={fixedTargetLanguage && { id: fixedTargetLanguage, name: `dict.lang.${fixedTargetLanguage}` } || undefined}
              disabled={!!fixedTargetLanguage} />
          </Grid>
          <Grid item xs={12}>
            <NormalTextField
              id="maxAmount"
              label="Max amount of projects"
              fullWidth
              defaultValue={defaultValue}
              type='number' 
              InputProps={{ inputProps: { min: 1 } }}/>
          </Grid>
        </Grid>
        <Box height={13} />
        <SumbitButtons onClose={onClose} onSaveLabel="Submit" onDelete={limitId && handleDelete || undefined} />
      </form>
    </RoundedPanel>
  </Modal>
}
