export const gatedCommunityTranslations = { 
  gatedCommunity: {
    profile: {
      GATED_COMMUNITY_ADMIN: "Manager",
      GATED_COMMUNITY_MEMBER: "Member"
    },
    status: {
      MEMBER: "Active",
      PENDING: "Pending",
      REJECTED: "Rejected",
      SUSPENDED: "Suspended"
    }
  }
}