import React, { useEffect, useState } from 'react'
import { MenuItemLink, useSidebarState, MenuProps, useResourceDefinitions } from 'react-admin'
import Box from '@mui/material/Box'

import FeaturesIcon from '@mui/icons-material/Settings'
import PricingMultiIcon from '@mui/icons-material/MonetizationOn'
import PricesIcon from '@mui/icons-material/AccountBalance'
import LanguageMinerIcon from '@mui/icons-material/Translate'
import TermsIcon from '@mui/icons-material/TitleRounded'
import AssessmentIcon from '@mui/icons-material/Assessment'

import users from 'user'
import invoices from 'invoice'
import featureSwitches from 'featureSwitch'
import featureParameters from 'featureParameter'
import pricingRules from 'pricingRule'
import companies from 'company'
import gatedCommunities from 'gatedCommunity'
import jobs from 'job'
import assets from 'assets'

import SubMenu from './SubMenu'
import UserService from 'UserService'
import { getTableauToken } from 'bi/BiApi'

type MenuName = 'menuGroups' | 'menuPricing' | 'menuFeatures' | 'menuGCM' | 'menuLanguageMiner'

const Menu = ({ dense = false }: MenuProps) => {
  const [state, setState] = useState({
    menuGroups: false,
    menuPricing: false,
    menuFeatures: false,
    menuGCM: false,
    menuLanguageMiner: false
  })
  const [open] = useSidebarState()
  const [isAssetManager, setIsAssetManager] = useState<boolean>(false)
  const [isBIEnabled, setBIEnabled] = useState<boolean>(false)
  const resourcesDefinitions = useResourceDefinitions()

  const checkIfBIEnabled = async () => {
    try {
      await getTableauToken()
      setBIEnabled(true)
    } catch (error) {
      console.log('Error fetching Tableau token, disabling BI menu:', error)
    }
  }

  useEffect(() => {
    setIsAssetManager(Object.keys(resourcesDefinitions).includes("assets"))
    checkIfBIEnabled()
  }, [resourcesDefinitions])

  const handleToggle = (menu: MenuName) => {
    setState(state => ({ ...state, [menu]: !state[menu] }))
  }

  return <Box
    sx={{
      width: open ? 200 : 50,
      marginTop: 1,
      marginBottom: 1,
      transition: theme =>
        theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen
        })
    }}
  >
    {' '}
    <MenuItemLink   
      to='/users'
      state={{ _scrollToTop: true }}
      primaryText='Users'
      leftIcon={<users.icon />}
      dense={dense}
    />
    
    {UserService.hasRoles(['ROLE_PLATFORM_MANAGER', 'ROLE_SUPPORT', 'ROLE_COMMUNITY_MANAGER']) && <MenuItemLink
      to='/invoices'
      state={{ _scrollToTop: true }}
      primaryText='Invoices'
      leftIcon={<invoices.icon />}
      dense={dense}
    />}

    <MenuItemLink
      to='/jobs'
      state={{ _scrollToTop: true }}
      primaryText={'Projects'}
      leftIcon={<jobs.icon />}
      dense={dense}
    />

    {UserService.hasRoles(['ROLE_PLATFORM_MANAGER', 'ROLE_SUPPORT', 'ROLE_COMMUNITY_MANAGER', 'ROLE_GATED_COMMUNITY_MANAGER']) && <MenuItemLink
      to='/companies'
      state={{ _scrollToTop: true }}
      primaryText='Companies'
      leftIcon={<companies.icon />}
      dense={dense}
    />}

    <MenuItemLink
      to='/gatedCommunities'
      state={{ _scrollToTop: true }}
      primaryText="Gated Comm's"
      leftIcon={<gatedCommunities.icon />}
      dense={dense}
    />

    {UserService.hasRoles(['ROLE_PLATFORM_MANAGER', 'ROLE_SUPPORT']) && <SubMenu
      handleToggle={() => handleToggle('menuPricing')}
      isOpen={state.menuPricing}
      name="Pricing"
      icon={<PricesIcon />}
      dense={dense}
    >
      {UserService.hasRoles(['ROLE_PLATFORM_MANAGER', 'ROLE_SUPPORT']) && <MenuItemLink
        to='/pricingrules/dynamic'
        state={{ _scrollToTop: true }}
        primaryText='Dynamic'
        leftIcon={<PricingMultiIcon />}
        dense={dense}
      /> }
      {UserService.hasRoles(['ROLE_SUPPORT']) && <MenuItemLink
        to='/pricingrules/fixed'
        state={{ _scrollToTop: true }}
        primaryText='Fixed'
        leftIcon={<PricingMultiIcon />}
        dense={dense}
      />}
    </SubMenu>}
    {UserService.hasRoles([]) && <SubMenu
      handleToggle={() => handleToggle('menuFeatures')}
      isOpen={state.menuFeatures}
      name="Features"
      icon={<FeaturesIcon />}
      dense={dense}
    >
      <MenuItemLink 
        to='/features/parameters'
        state={{ _scrollToTop: true }}
        primaryText='Parameters'
        leftIcon={<featureParameters.icon />}
        dense={dense}
      />
      <MenuItemLink
        to='/features/switches'
        state={{ _scrollToTop: true }}
        primaryText='Switches'
        leftIcon={<featureSwitches.icon />}
        dense={dense}
      />
    </SubMenu>
    }
    {isAssetManager && UserService.hasRoles(['ROLE_PLATFORM_MANAGER']) && <SubMenu
      handleToggle={() => handleToggle('menuLanguageMiner')}
      isOpen={state.menuLanguageMiner}
      name="dict.role.ROLE_LANGUAGE_MINER"
      icon={<LanguageMinerIcon />}
      dense={dense}
    >
      <MenuItemLink
        to='/assets'
        state={{ _scrollToTop: true }}
        primaryText='Assets'
        leftIcon={<assets.icon />}
        dense={dense}
      />
      <MenuItemLink
        to='/subassets'
        state={{ _scrollToTop: true }}
        primaryText='Terms'
        leftIcon={<TermsIcon />}
        dense={dense}
      />
    </SubMenu>}
    {isBIEnabled && UserService.hasRoles(['ROLE_BI']) && <MenuItemLink
      to="/bi"
      primaryText="BI"
      leftIcon={<AssessmentIcon />}
    />}
  </Box>
}

export default Menu