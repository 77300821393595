import AssetsIcon from '@mui/icons-material/SpellcheckRounded'

import SubAssetsList, { SubAssetCreate } from './SubAssetsList'

const resource = {
  list: SubAssetsList,
  icon: AssetsIcon,
  create: SubAssetCreate
}

export default resource