import React from 'react'
import polyglotI18nProvider from 'ra-i18n-polyglot'

import { Admin, fetchUtils } from 'react-admin'
import xTotalCountFixDataProvider from './xTotalCountFixDataProvider'
import { CssBaseline } from '@mui/material'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import AdapterMoment from '@mui/lab/AdapterMoment'

import englishMessages from 'translations/en'
import { theme } from 'utils/theme/theme'
import authProvider from './authProvider'
import UserService from 'UserService'
import AppLayout from 'layout/AppLayout'
import reroutingDataProvider from './reroutingDataProvider'

interface Props {
  children: React.ReactNode
}

const i18nProvider = polyglotI18nProvider(() => {
  return englishMessages
}, 'en')

const httpClient = async (url: string, options: fetchUtils.Options = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: 'application/json' })
  }

  const token = await UserService.getToken();
  (options.headers as any).set('Authorization', `Bearer ${token}`)
  return fetchUtils.fetchJson(url, options)
}

const Providers = ({ children }: Props) => <LocalizationProvider dateAdapter={AdapterMoment}>
  <CssBaseline />
  <Admin dataProvider={reroutingDataProvider(xTotalCountFixDataProvider, httpClient)} authProvider={authProvider} i18nProvider={i18nProvider} theme={theme} layout={AppLayout} disableTelemetry>
    {children}
  </Admin>
</LocalizationProvider>

export default Providers
