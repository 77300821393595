import axios from "axios"
import FileSaver from "file-saver"

import { StageType, StageStatus, StageParameter, AssignmentStatus } from "api/common"
import { UserFile } from "./jobs"

export interface AdminStageAssignmentRecord {
  id: string,
  executorUsername: string,
  executorId: string,
  status: AssignmentStatus,
  uploadDate: string
}

export interface AdminStageDashboardRecord {
  id: string,
  jobId: string,
  type: StageType,
  status: StageStatus,
  order: number,
  assignments: AdminStageAssignmentRecord[],
  parameters: Record<StageParameter, string>,
  dueDate: string
}

export type FileType = 'RESULT' | 'XLIFF2' | 'XLIFF1' | 'BILANG_DOCX' | 'TRANSLATED_DOCX' | 'UPLOADED'

export class FileNotReadyException extends Error {
  constructor () { super() }
}

export class FileNotFoundException extends Error {
  constructor () { super() }
}

export async function downloadFile (path: string, params: any = {}, fileName?: string) {
  await axios.get<any>(path,
    { responseType: 'blob', params: params, validateStatus: status => status == 200 }
  ).then(response => {
    const contentType = response.headers['content-type']
    FileSaver.saveAs(new Blob([response.data], { type: contentType }), fileName)
  }).catch(error => {
    if (error.response.status == 202) throw new FileNotReadyException()
    if (error.response.status == 404) throw new FileNotFoundException()
  })
}

export async function propagateStage (stageId: string) {
  await axios.post(`/api/admin/stages/${stageId}/propagate`)
}

export async function changeStageDeadline (stageId: string, deadline: string) {
  await axios.post(`/api/admin/stages/${stageId}/changeDeadline`, deadline)
}

export async function downloadJobFile (stageId: string, type: FileType, jobSectionId: string, fileName?: string) {
  await downloadFile(`/api/workflow/stage/${stageId}/outputFile`, { fileType: type, jobSectionId: jobSectionId }, fileName)
}

export async function requestFileGeneration (stageId: string, type: FileType, jobSectionId: string) {
  const response = await axios.post<void>(`/api/workflow/stage/${stageId}/requestOutputFileGeneration`, undefined, {
    params: {
      fileType: type,
      jobSectionId: jobSectionId
    }
  })
  return response.data
}

export async function getAllStageAttachmentsDescription (stageId: string) {
  const response = await axios.get<UserFile[]>(`/api/workflow/workflow/attachments/stages/${stageId}/attachments`)
  return response.data
}

export async function downloadStageAttachment (stageId: string, fileId: string, fileName?: string) {
  await downloadFile(`/api/workflow/workflow/attachments/stages/${stageId}/attachments/${fileId}`, {}, fileName)
}

export async function getLogs (stageId: string) {
  const response = await axios.get<string>(`/api/admin/stages/${stageId}/logs`)
  return response.data
}