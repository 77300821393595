import React from 'react'
import { useTranslate, Link, useRecordContext } from 'react-admin'

import Box from '@mui/material/Box'
import { Divider } from '@mui/material'

import { ExtendedJobRecord } from 'api/jobs'
import SideCard from 'components/SideCard'
import Status from 'components/Status'
import { CARD_WIDTH, NormText, SM_FONT_LINE_H28, STANDARD_PADDING } from './Common'

const JobBox = ({ children }: { children: React.ReactNode }) => <Box padding={STANDARD_PADDING} paddingRight='35px' paddingBottom='20px' display='flex' gap='10px' justifyContent='space-between'>{children}</Box>

const JobShowStages = () => {
  const record = useRecordContext<ExtendedJobRecord>()
  const translate = useTranslate()
  if (!record?.stages[0]?.type) return null

  return <SideCard width={CARD_WIDTH}>
    <JobBox>
      <NormText sx={{ width: '40%', font: SM_FONT_LINE_H28 }}><b>Stage</b></NormText>
      <NormText sx={{ width: '30%', font: SM_FONT_LINE_H28 }}><b>Status</b></NormText>
      <NormText sx={{ width: '30%', font: SM_FONT_LINE_H28 }}><b>Contributor</b></NormText>
    </JobBox>

    <Divider />

    <JobBox>
      <Box display='flex' flexDirection='column' gap='31px' width='40%'>
        {record?.stages.map(({ type, id }) => <NormText key={id}><Link to={`/stages/${id}/show`}>{translate(`stage.type.${type}`)}</Link></NormText>)}
      </Box>
  
      <Box display='flex' flexDirection='column' gap='30px' width='30%'>
        {record?.stages.map(({ status, id }) => <Status key={id} status={status} text={translate(`stage.status.${status}`)} />)}
      </Box>
        
      <Box display='flex' flexDirection='column' gap='31px' width='30%'>
        {record?.stages.map(({ assignments }) => {
          const assignment = assignments.find(({ status }) => ['ACCEPTED', 'DELIVERED', 'CANCELLED', 'SUPERSEDED', 'FINISHED', 'INTERRUPTED'].includes(status))
          return assignment ? <NormText><Link to={`/users/${assignment.executorId}/show`}>{assignment.executor}</Link></NormText> : <NormText>&nbsp;</NormText>
        })}
      </Box>
    </JobBox>
  </SideCard>
}

export default JobShowStages