import React from 'react'
import { BooleanField, EditButton, List } from 'react-admin'

import CustomTranslatedTextField from 'common/CustomTranslatedTextField'
import SortedByTranslatedIdDatagrid from 'featureParameter/SortedByTranslatedIdDatagrid'

const PriceList = () => {
  return <List exporter={false} pagination={false}>
    <SortedByTranslatedIdDatagrid translationPath="dict.featureSwitch.">
      <CustomTranslatedTextField source="id" label={'Feature'} sortable={false} translationPath="dict.featureSwitch." />
      <BooleanField source='value' label={'Value'} sortable={false} />
      <EditButton />
    </SortedByTranslatedIdDatagrid>
  </List>
}

export default React.memo(PriceList)