import InvoiceIcon from '@mui/icons-material/LibraryBooks'

import InvoiceShow from './InvoiceShow'
import InvoiceList from './InvoiceList'

const resource = {
  list: InvoiceList,
  show: InvoiceShow,
  icon: InvoiceIcon
}

export default resource