import { useDataProvider, useNotify, useRefresh, useTranslate } from "ra-core"
import React, { useEffect } from 'react'
import { Box, Chip, Modal } from '@mui/material'
import { Role } from 'api/common'
import { usersRoles } from 'common/dictonary'
import UserService from 'UserService'
import { FilterIcon } from 'common/Icons'
import RoundedPanel from 'common/RoundedPanel'
import { SumbitButtons } from 'common/SubmitButtons'
import { ModalProps } from './common'

import 'styles.scss'

interface ChipifyRolesProps {
  roles: Role[];
  onClick: (role: Role) => void;
  iconContains: JSX.Element;
}

const ChipifyRoles = ({ roles, onClick, iconContains }: ChipifyRolesProps) => {
  const translate = useTranslate()

  return <>
    {roles.map((role: Role) => <Chip
      className="big-chip"
      label={translate(`dict.role.${role}`)}
      onDelete={() => onClick(role)}
      deleteIcon={iconContains}
    />)}
  </>
}

const getAllRolesForUser = () => {
  if (UserService.hasRoles(['ROLE_EXFLUENCY_ADMIN'])) {
    return usersRoles
  }

  const roles: Role[] = []

  if (UserService.hasRoles(['ROLE_PLATFORM_MANAGER'] as Role[])) {
    roles.push(...(['ROLE_PLATFORM_MANAGER', 'ROLE_SUPPORT', 'ROLE_GATED_COMMUNITY_MANAGER'] as Role[]))
  }

  if (UserService.hasRoles(['ROLE_PLATFORM_MANAGER', 'ROLE_SUPPORT', 'ROLE_COMMUNITY_MANAGER'])) {
    roles.push(...(['ROLE_REDACTOR', 'ROLE_TRUST_MINER', 'ROLE_BUYER', 'ROLE_ENHANCER'] as Role[]))
  }

  return roles
}
export const ManageRolesModal = ({ record, open, onClose }: ModalProps) => {
  const [currentRoles, setCurrentRoles] = React.useState<Set<Role>>(new Set())
  const [availableRoles, setAvailableRoles] = React.useState<Set<Role>>(new Set())

  const dataProvider = useDataProvider()
  const refresh = useRefresh()
  const notify = useNotify()

  const allRoles = getAllRolesForUser()

  useEffect(() => {
    if (record && open) {
      setCurrentRoles(new Set(record.roles))
    }
  }, [record, open])

  useEffect(() => {
    setAvailableRoles(new Set(allRoles.filter((role: Role) => !currentRoles.has(role))))
  }, [currentRoles])

  const handleSave = () => {
    const querry = {
      id: record.id,
      data: {
        ...record,
        roles: Array.from(currentRoles)
      },
      previousData: record
    }

    dataProvider.update('users', querry)
      .then(() => {
        refresh()
        notify('Updated', {
          type: 'info'
        })
        onClose()
      })
      .catch(() => {
        notify('Failed', {
          type: 'warning'
        })
      })
  }

  const CurrentRoles = <ChipifyRoles
    roles={Array.from(currentRoles)}
    onClick={(role: Role) => {
      setCurrentRoles(new Set(Array.from(currentRoles).filter((r: Role) => r !== role)))
    }}
    iconContains={<FilterIcon type="red-x" />}
  />

  const AvailableRoles = <ChipifyRoles
    roles={Array.from(availableRoles)}
    onClick={(role: Role) => {
      setCurrentRoles(new Set(Array.from(currentRoles).concat([role])))
    }}
    iconContains={<FilterIcon type="green-plus" />}
  />

  return <Modal
    open={open}
    onClose={onClose}
    disableAutoFocus={true}
  >
    <RoundedPanel modal width='600px'>
      <h1>Manage roles</h1>
      <Box className="section" sx={{ minHeight: "120px" }}>
        Active roles:
        <Box height={20} />
        {CurrentRoles}
      </Box>
      <Box className="section gray" sx={{ minHeight: "120px", marginBottom: "-27px !important" }}>
        Add new role:
        <Box height={20} />
        {AvailableRoles}
      </Box>
      <hr />
      <SumbitButtons onClose={onClose} onSave={handleSave} />
    </RoundedPanel>
  </Modal>
}