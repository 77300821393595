export const assignmentTranslations = { 
  assignment: {
    status: {
      ACCEPTED: "Accepted",
      DELIVERED: "Delivered",
      FINISHED: "Finished",
      PENDING: "Pending",
      REJECTED: "Rejected",
      MISSED: "Missed",
      CANCELLED: "Cancelled",
      INTERRUPTED: "Interrupted",
      SUPERSEDED: "Superseded"
    }
  }
}