import React, { ReactNode, useState } from 'react'
import { Link } from "react-admin"
import { useRecordContext } from "ra-core"

import { UserRecord, getUserTrustChain } from 'api/users'
import UserService from 'UserService'
import { Box, Grid } from '@mui/material'

import { parseTimestamp } from 'utils/helpers'
import ActionButton from 'components/ActionButton'
import { EditProfileModal } from './profileModals/EditProfileModal'

import 'styles.scss'
import { ManageRolesModal } from './profileModals/ManageRolesModal'
import { ChangeCompanyModal } from './profileModals/ChangeCompanyModal'

import { ErrorOutline } from '@mui/icons-material'
import RoundedPanel, { CrossCheckbox, Label, NoOverflow } from 'common/RoundedPanel'
import { Chipify } from 'common/Chipify'
import { handleEsc } from 'common/closeOnEscape'

interface BlockProps {
  record: UserRecord
}

const UserBlock = ({ record }: BlockProps) => {
  interface UserScoreProps {
    children: ReactNode;
    note?: string;
  }
  const UserScore = ({ children, note }: UserScoreProps) => {
    const [show, setShow] = React.useState(false)

    const handleMouseEnter = () => {
      setShow(true)
    }

    const handleMouseLeave = () => {
      setShow(false)
    }

    const width = (note?.length || 0) * 5.5 + 24

    return <>
      <div className="user-score" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
        {children}
      </div>
      <Box component="span" className={`user-score-popup ${note && show ? 'show' : ''}`} width={width} sx={{ right: -width / 2 + 46 }}>
        <svg viewBox="0 0 100 100">
          <polygon points="50 50, 100 100, 0 100" fill="#222222" />
        </svg>
        {note}
      </Box>
    </>
  }

  const [exfluenscore, setExfluenscore] = useState<number | undefined | null>(undefined)

  React.useEffect(() => {
    getUserTrustChain(record.userName)
      .then((response) => {
        setExfluenscore(response.exfluenscore)
      })
      .catch((error) => {
        setExfluenscore(null)
        console.warn(error)
      })
  }, [])

  const trustChain = exfluenscore !== null ? exfluenscore?.toFixed(1) : <ErrorOutline />
  const message = exfluenscore !== null ? 'Overall Trust Chain' : 'Trust Chain is not available'

  return (
    <RoundedPanel width="600px">
      <UserScore note={message}>{trustChain}</UserScore>
      <Label noSelect>User Name: </Label><Link to="/">{record.userName}</Link><br />
      <hr />
      <Label noSelect>First Name: </Label>{record.firstName}<br />
      <Label noSelect>Last Name: </Label>{record.lastName}
      <Box height={13} />
      <Label noSelect>User ID: </Label>{record.id}<br />
      <Label noSelect>Time Zone: </Label>{record.timezone}
      <Box height={20} />
      <Label noSelect>Email: </Label>{record.email}<br />
      <Label noSelect>LinkedIn: </Label><NoOverflow noWrap><a href={record.linkedIn || ""}>{record.linkedIn}</a></NoOverflow><br />
      <hr />
      <Label noSelect>Company: </Label><Link to={`/companies/${record.company?.id}/show`}>{record.company?.name}</Link>
      <Box height={13} />
      <hr />
      <Label noSelect>Roles: </Label>
      <Chipify source='roles' translation="dict.role." variant='squere-chip' />
      <Box height={13} />
      <Label noSelect>Community Manager: </Label><Link to={`/users/${record.communityManager?.id}/show`}>{record.communityManager?.name}</Link>
    </RoundedPanel>
  )
}

const StatusBlock = ({ record }: BlockProps) => {
  const { timezone } = UserService.getTokenUserInfo()
  const lastSeen = parseTimestamp(record.lastActivityDate, timezone) ?? 'Unknown'

  return <RoundedPanel width="600px">
    <Label>Status</Label>
    <hr />
    <Label noSelect>Last seen: </Label>{lastSeen}
    <Box height={16} />
    <Label noSelect>Reminders (last 30 days): </Label>{record.latenessStats?.latest || 0}<br />
    <Label noSelect>Reminders (total): </Label>{record.latenessStats?.total || 0}<br />
    <Box height={16} />
    {record.rcaStats !== undefined && <>
      <Label noSelect>RCA (last 30 days): </Label>{record.rcaStats?.latest || 0}<br />
      <Label noSelect>RCA (total): </Label>{record.rcaStats?.total || 0}<br />
    </>}
    <hr />
    <CrossCheckbox checked={record.outOfOffice} disableRipple />Out Of Office
    <Box height={16} />
    <Grid container rowSpacing={2} columnSpacing={1} sx={{ whiteSpace: 'nowrap', width: '420px' }}>
      <Grid item xs>
        <CrossCheckbox checked={record.isActive} disableRipple />Active
      </Grid>
      <Grid item xs>
        <CrossCheckbox checked={record.isNdaSigned} disableRipple />NDA
      </Grid>
      <Grid item xs>
        <CrossCheckbox checked={record.isAccountConfirmed} disableRipple />Confirmed Account
      </Grid>
      <Grid item xs>
        <CrossCheckbox checked={record.acceptsCharity} disableRipple />Accept Charities
      </Grid>
    </Grid>
    <Box height={16} />
  </RoundedPanel>
}

type CurrentModal = "EDIT_PROFILE" | "MANAGE_ROLES" | "CHANGE_COMPANY"

const Profile = () => {
  const record = useRecordContext<UserRecord>()
  const [open, setOpen] = useState<CurrentModal | null>(null)

  const closeModal = () => { setOpen(null) }

  React.useEffect(handleEsc(closeModal), [])

  return (
    <>
      <EditProfileModal
        record={record}
        open={open === "EDIT_PROFILE"}
        onClose={closeModal} />
      <ManageRolesModal
        record={record}
        open={open === "MANAGE_ROLES"}
        onClose={closeModal}
      />
      <ChangeCompanyModal
        record={record}
        open={open === "CHANGE_COMPANY"}
        onClose={closeModal}
      />

      <Box className="edit-tab">
        <Box className='on-left'>
          <ActionButton
            onClick={() => {
              setOpen("EDIT_PROFILE")
            }}
          >Edit</ActionButton>
          <ActionButton
            onClick={() => {
              setOpen("MANAGE_ROLES")
            }}
          >Manage roles</ActionButton>
          {UserService.hasRoles(['ROLE_SUPPORT', 'ROLE_PLATFORM_MANAGER', 'ROLE_COMMUNITY_MANAGER']) && <ActionButton
            onClick={() => {
              setOpen("CHANGE_COMPANY")
            }}
          >Change Company</ActionButton>}
        </Box>
      </Box>
      <Box className="panel">
        <Box>
          <UserBlock record={record} />
        </Box>
        <Box>
          <StatusBlock record={record} />
        </Box>
      </Box>
    </>
  )
}

export default Profile