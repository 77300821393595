import { Chip } from '@mui/material'
import { PricingRule } from 'api/common'
import { Identifier, useTranslate } from 'ra-core'
import { List, Datagrid, FunctionField, NumberField } from 'ra-ui-materialui'
import React, { ReactNode } from 'react'

import 'styles.scss'
import { StageTypeWithId } from './CreatePricing'

interface PriceListProps {
  isSingle: boolean,
  companyId?: Identifier,
  children: ReactNode,
}

export const PriceList = ({ children, companyId }: PriceListProps) => {
  const translate = useTranslate()

  return <List
    resource={'pricingrules/stage-types'}
    exporter={false}
    actions={false}
    pagination={false}
    className='no-shadow'
  >
    <Datagrid
      bulkActionButtons={false}
      className='assigments-datagrid small'
    >
      <FunctionField
        label='Stage'
        render={({ stageType }: StageTypeWithId) => <Chip className='squere-chip' label={translate(`stage.type.${stageType}`)} />}
      />
      {children}
    </Datagrid>
  </List>
}