import { useDataProvider, useNotify, useRefresh } from "ra-core"
import React, { FormEvent, useEffect, useState } from 'react'
import { Link } from "react-admin"
import { Autocomplete, Box, Grid, Modal } from '@mui/material'
import { CommunityManagerShortRecord, UserRecord } from 'api/users'
import { Chipify } from 'common/Chipify'
import RoundedPanel, { CrossCheckbox, Label } from 'common/RoundedPanel'
import { SumbitButtons } from 'common/SubmitButtons'
import { timezones } from 'common/dictonary'
import 'styles.scss'
import { ModalProps } from './common'
import { NormalTextField } from "common/TextField"

export const EditProfileModal = ({ record, open, onClose }: ModalProps) => {
  const [managers, setManagers] = useState<CommunityManagerShortRecord[]>([])
  const [choosedMenager, setChoosedMenager] = useState<CommunityManagerShortRecord | null>(null)
  const [isLoading, setIsLoading] = useState<boolean>(true)

  const dataProvider = useDataProvider()
  const refresh = useRefresh()
  const notify = useNotify()

  interface ManagerInstance {
    id: string,
    login: string,
  }

  useEffect(() => {
    setIsLoading(true)

    dataProvider
      .getList<ManagerInstance>("users/managers", {
        pagination: { page: 1, perPage: 50 },
        sort: { field: "id", order: "ASC" },
        filter: {}
      })
      .then(({ data }) => {
        setManagers(data.map(({ id, login }) => ({ id: id, name: login })))
        setIsLoading(false)
      })
      .catch(() => {
        setIsLoading(false)
      })
  }, [])

  const handleSave = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    const data: UserRecord = {
      ...record,
      firstName: e.currentTarget.firstName.value,
      lastName: e.currentTarget.lastName.value,
      userName: e.currentTarget.userName.value,
      email: e.currentTarget.email.value,
      linkedIn: e.currentTarget.linkedIn.value,
      timezone: e.currentTarget.timezone.value,
      outOfOffice: e.currentTarget.outOfOffice.checked,
      isActive: e.currentTarget.isActive.checked,
      isNdaSigned: e.currentTarget.isNdaSigned.checked,
      isAccountConfirmed: e.currentTarget.isAccountConfirmed.checked,
      acceptsCharity: e.currentTarget.acceptsCharity.checked,
      communityManager: choosedMenager||undefined
    }

    const updateQuery = {
      id: record.id,
      data: data,
      previousData: record
    }

    dataProvider
      .update('users', updateQuery)
      .then(() => {
        refresh()
        onClose()
        notify('User updated', { type: 'success' })
      })
      .catch(() => {
        notify('Failed to update user', { type: 'warning' })
      })

  }

  return <Modal
    open={open}
    onClose={onClose}
    disableAutoFocus={true}
  >
    <RoundedPanel modal>
      <h1>User Details</h1><Box height={32} />
      <Label noSelect margin>Roles</Label><Chipify source='roles' translation="dict.role." variant='squere-chip' /><Box height={16} />
      <Label noSelect margin>Company</Label> <Link to={`/companies/${record.company?.id}/show`}>{record.company?.name}</Link> <Box height={32} />

      <form
        onSubmit={handleSave}
      >
        <Grid container spacing={'20px'}>
          <Grid item xs={6}>
            <NormalTextField
              id="firstName"
              label="First Name"
              defaultValue={record.firstName}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <NormalTextField
              id="lastName"
              label="Last Name"
              defaultValue={record.lastName}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <NormalTextField
              id="userName"
              label="Username"
              defaultValue={record.userName}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <NormalTextField
              id="email"
              label="Email"
              defaultValue={record.email}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <NormalTextField
              id="linkedIn"
              label="LinkedIn"
              defaultValue={record.linkedIn}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <Autocomplete
              id="timezone"
              options={timezones}
              renderInput={(params) => <NormalTextField {...params} label="Timezone" />}
              defaultValue={record.timezone}
              noOptionsText='No timezones found'
            />
          </Grid>
          <Grid item xs={6}>
            <Autocomplete
              id="communityManager"
              loading={isLoading}
              options={managers}
              onChange={(e, value) => setChoosedMenager(value)}
              getOptionLabel={option => option.name}
              defaultValue={record.communityManager}
              renderInput={(params) => <NormalTextField {...params} label="Community Manager" />}
            />
          </Grid>
        </Grid>
        <Box height={16} />
        <h1>Status</h1>
        <Grid container spacing='5px' style={{ whiteSpace: 'nowrap' }} columns={3}>
          <Grid item xs={1}>
            <CrossCheckbox defaultChecked={record.outOfOffice} id="outOfOffice" />Out of office
          </Grid>
          <Grid item xs={1}>
            <CrossCheckbox defaultChecked={record.isActive} id="isActive" />Active
          </Grid>
          <Grid item xs={1}>
            <CrossCheckbox defaultChecked={record.isNdaSigned} id="isNdaSigned" />NDA
          </Grid>
          <Grid item xs={1}>
            <CrossCheckbox defaultChecked={record.isAccountConfirmed} id="isAccountConfirmed" />Confirmed Account
          </Grid>
          <Grid item xs={1}>
            <CrossCheckbox defaultChecked={record.acceptsCharity} id="acceptsCharity" />Accept Charities
          </Grid>
        </Grid>
        <hr />
        <SumbitButtons onClose={onClose} />
      </form>
    </RoundedPanel>
  </Modal>
}