import JobIcon from '@mui/icons-material/FeaturedPlayList'

import JobShow from './JobShow'
import JobList from './JobList'

const resource = {
  list: JobList,
  show: JobShow,
  icon: JobIcon
}

export default resource