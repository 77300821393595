import React from 'react'
import ActionButton from 'components/ActionButton'
import { Link } from 'react-admin'

interface Props {
    jobId: string
}
const OpenRCA = ({ jobId }: Props) => {
  return (
    <Link to={`/jobs/${jobId}/rcas`}>
      <ActionButton>
        Show RCA
      </ActionButton>
    </Link>
  )
}

export default OpenRCA