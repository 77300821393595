import React, { ReactNode } from "react"

interface OverflowProps {
  children: ReactNode;
  noWrap?: boolean;
}

export const NoOverflow = ({ children, noWrap }: OverflowProps) => {
  return <div
    className={`overflow ${noWrap ? 'no-wrap' : ''}`}
  >
    {children}
  </div>
}