import React from 'react'
import { useRecordContext, useTranslate } from 'react-admin'

import Box from '@mui/material/Box'
import { Divider } from '@mui/material'
import moment from 'moment'

import { parseTimestamp } from 'utils/helpers'
import { ExtendedJobRecord } from 'api/jobs'
import UserService from 'UserService'

import SideCard from 'components/SideCard'

import { CARD_WIDTH, GAP, NormText, STANDARD_PADDING } from './Common'

const JobQuoteDates = () => {
  const record = useRecordContext<ExtendedJobRecord>()

  const { timezone } = UserService.getTokenUserInfo()

  return record?.jobQuote && (<SideCard width={CARD_WIDTH}>
    <Box display='flex' gap={GAP} padding={STANDARD_PADDING}>
      <Box display='flex' flexDirection='column' gap='31px' >
        <NormText><b>Quote Calculation Date:</b></NormText>
        { record?.jobQuote?.acceptanceDate && (<NormText><b>Acceptance Date:</b></NormText>) }
        { record?.jobQuote?.rejectionDate && (<NormText><b>Rejection Date:</b></NormText>) }
      </Box>

      <Box display='flex' flexDirection='column' gap='31px' >
        <NormText>{parseTimestamp(record?.jobQuote?.calculationDate, timezone)}</NormText>
        { record?.jobQuote?.acceptanceDate && (<NormText>{parseTimestamp(record?.jobQuote?.acceptanceDate, timezone)}</NormText>) }
        { record?.jobQuote?.rejectionDate && (<NormText>{parseTimestamp(record?.jobQuote?.rejectionDate, timezone)}</NormText>) }
      </Box>
    </Box>
  </SideCard>
  )
}

export default JobQuoteDates