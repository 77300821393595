import React from 'react'
import { BooleanField, Datagrid, ExportButton, FilterButton, FunctionField, Link, List, NullableBooleanInput, NumberField, NumberInput, TextField, TopToolbar } from 'react-admin'

import CustomDateField from 'common/CustomDateField'
import CustomLinkField from 'common/CustomLinkField'
import CustomPagination from 'common/CustomPagination'

import GatedCommunitySearchFilter from 'common/GatedCommunitySearchFilter'
import LanguageFilter from 'common/LanguageFilter'
import rowStyle from './rowStyle'
import OutlinedDateTimeInput from 'common/OutlinedDateTimeInput'
import { UserRecord , UsersSortBy } from 'api/users'
import UserExpand from './UserExpand'
import { Chipify } from 'common/Chipify'
import { OutlinedSearchInput } from 'common/OutlinedSearchInput'
import { OutlinedArrayInput } from 'common/OutlinedArrayInput'
import { getOptionsSelectFromArray } from 'utils/helpers'
import { Role } from 'api/common'

const roles: Role[] = ['ROLE_BUYER', 'ROLE_REDACTOR', 'ROLE_ENHANCER', 'ROLE_TRUST_MINER', 'ROLE_GATED_COMMUNITY_MANAGER', 'ROLE_PLATFORM_MANAGER', 'ROLE_COMMUNITY_MANAGER', 'ROLE_SUPPORT', 'ROLE_EXFLUENCY_ADMIN', 'ROLE_FINANCE']
const overrideWidth = { sx: { minWidth: '200px' } }
const filters = [ 
  <OutlinedSearchInput source='name' alwaysOn label="Name"/>,
  <GatedCommunitySearchFilter source='gatedCommunities' alwaysOn />,
  <OutlinedArrayInput label="Roles" source="roles" choices={getOptionsSelectFromArray(roles, 'dict.role.')} alwaysOn fieldProps={overrideWidth} />,
  <LanguageFilter alwaysOn source='sourceLanguage' label='Source Lang' />,
  <LanguageFilter alwaysOn source='targetLanguage' label='Target Lang' />,
  <OutlinedDateTimeInput source="lastActivityDateFrom" label='Activity From' />,
  <OutlinedDateTimeInput source="lastActivityDateTo" label='Activity To' />,
  <OutlinedDateTimeInput source="signupDateFrom" label='Signup From' />,
  <OutlinedDateTimeInput source="signupDateTo" label='Signup To' />,
  <NumberInput source="minAssetsDone" label={'Minimum Assets Done'} min={0} className='custom-array-input'/>,
  <NullableBooleanInput label="Active" source="isActive" className='custom-array-input' />,
  <NullableBooleanInput label="NDA" source="isNdaSigned" className='custom-array-input' />,
  <NullableBooleanInput label="Email Confirmed" source="isEmailConfirmed" className='custom-array-input' />,
  <NullableBooleanInput label="Out of office" source="outOfOffice" className='custom-array-input' />
]

const UserList = () => {
  return <List perPage={50} sort={{ field: UsersSortBy.USER_NAME, order: 'DESC' }} title={'Members'}
    pagination={<CustomPagination />} filters={filters} hasEdit
    actions={<TopToolbar>
      <ExportButton maxResults={100000} />
      <FilterButton />
    </TopToolbar>}>
    <Datagrid rowStyle={(row: UserRecord) => rowStyle(row)} expand={<UserExpand />} bulkActionButtons={false}> 
      <CustomLinkField source="userName" label={'Username'} show basePath='users' targetFieldId='id' sortBy={UsersSortBy.USER_NAME} />
      <TextField source='firstName' sortable={false} label={'First Name'} />
      <TextField source='lastName' label={'Last Name'} sortBy={UsersSortBy.LAST_NAME} />
      <TextField source='timezone' label={'Time Zone'} sortable={false} />
      <FunctionField label='Company' sortable={false} render={(record: UserRecord) => <Link to={`/companies/${record.company?.id}/show`}>{record.company?.name}</Link>} />
      <CustomDateField source='signupDate' label='Signup Date' sortBy={UsersSortBy.CREATION_DATE} />
      <BooleanField source='outOfOffice' label={'Out of Office'} sortable={false} />
      <BooleanField source='isActive' label={'Active'} sortable={false} />
      <BooleanField source='isNdaSigned' label={'NDA'} sortable={false} />
      <BooleanField source='isAccountConfirmed' label={'Email Confirmed'} sortable={false} />
      <NumberField source='score' label={'Trust Chain'} textAlign="right" options={{ maximumFractionDigits: 1, minimumFractionDigits: 1 }} sortable={false} />
      <Chipify source='roles' translation='dict.role.' variant='squere-chip' sortable={false} />
    </Datagrid>
  </List>
}

export default React.memo(UserList)