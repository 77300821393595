import { Box } from '@mui/material'
import React from 'react'
import { FieldProps, useRecordContext } from 'react-admin'
import UserService from 'UserService'

import { secondsToReadableUnits } from 'utils/helpers'

const CustomSecondsToReadabletimeField = ({ source }: FieldProps) => {
  const record = useRecordContext()
  const showLowValues = UserService.hasRoles(['ROLE_SUPPORT'])
  return (record && source) ? <Box sx={{ textAlign: 'left' }}>
    <span>{secondsToReadableUnits(record[source], showLowValues)}</span>
  </Box> : null
}

export default CustomSecondsToReadabletimeField