import React from 'react'
import { Edit, TextInput, useTranslate, SimpleForm, useRecordContext } from 'react-admin'

import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import CustomEditToolBar from 'common/CustomEditToolbar'

const PriceEdit = () => {
  return <Edit title={<FeatureParameter />} component="div">
    <VisitorForm />
  </Edit>
}

const FeatureParameter = () => {
  const record = useRecordContext()
  return record ? <span>{record.name}</span> : null
}

const VisitorForm = () => {
  const record = useRecordContext()
  const translate = useTranslate()

  return <SimpleForm toolbar={<CustomEditToolBar />} >
    <Card style={{ width: '100%' }}>
      <CardContent>
        <Box display={{ md: 'block', lg: 'flex' }}>
          <Box flex={2} mr={{ md: 0, lg: '1em' }}>
            <Typography variant="h6" gutterBottom>
              {translate(`dict.featureParam.${record.id}`)}
            </Typography>
            <Box display={{ xs: 'block', sm: 'flex' }}>
              <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                <TextInput type='number' source="value" fullWidth />
              </Box>
            </Box>
          </Box>
        </Box>
      </CardContent>
    </Card>
  </SimpleForm>
}

export default PriceEdit