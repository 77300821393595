
export const handleEsc = (callback: () => void) => {
  return () => {
    const handleEsc = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        callback()
      }
    }
    window.addEventListener('keydown', handleEsc)
    return () => {
      window.removeEventListener('keydown', handleEsc)
    }
  }
}