import React, { useEffect, useState } from 'react'
import { useTranslate, useRecordContext, useNotify, useRefresh } from 'react-admin'

import Button from '@mui/material/Button'
import Checkbox from '@mui/material/Checkbox'

import { LanguagePair } from 'api/common'
import { GatedCommunityMemberSmallRecord, GatedCommunityRecord, updateGatedCommunityMemberLanguagePairs } from 'api/gatedCommunity'
import { Box } from '@mui/material'

import 'styles.scss'

const REFRESH_LIST_AFTER = 1000

interface Props {
  member: GatedCommunityMemberSmallRecord
  onClose: () => void
}

const MemberLanguagePairChange = ({ member, onClose }: Props) => {
  const [allowedLanguagePairs, setAllowedLanguagePairs] = useState<LanguagePair[]>(member.allowedLanguagePairs)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const record = useRecordContext<GatedCommunityRecord>()
  const notify = useNotify()
  const refresh = useRefresh()
  const translate = useTranslate()

  useEffect(() => {    
    setAllowedLanguagePairs(() => (member.allowedLanguagePairs))
  }, [member])

  const handleMemberAllowedLanguageParChange = (languagePair: LanguagePair) => {
    if(allowedLanguagePairs.find(({ languageFrom, languageTo }) => languageFrom === languagePair.languageFrom && languageTo === languagePair.languageTo)) {
      setAllowedLanguagePairs(languagePairs => languagePairs.filter(({ languageFrom, languageTo }) => !(languageFrom === languagePair.languageFrom && languageTo === languagePair.languageTo)))
    } else {
      setAllowedLanguagePairs(languagePairs => [...languagePairs, { languageFrom: languagePair.languageFrom, languageTo: languagePair.languageTo }])
    }
  }

  const handleMemberAllowedLanguagePairUpdate = async () => {
    setIsLoading(true)
    try {
      await updateGatedCommunityMemberLanguagePairs(record.id, member.id as string, allowedLanguagePairs)
      notify('Allowed Language Pairs were successfully updated.', { type: 'success' })
    } catch (error) {
      notify('Something went wrong.', { type: 'error' })
    }
    
    onClose()
    setIsLoading(false)

    setTimeout(() => {
      refresh()
    }, REFRESH_LIST_AFTER)
  }

  return <Box sx={{ display: 'flex', flexDirection: 'column' }}>
    {member.languagePairs.map((languagePair) => {
      const pair = allowedLanguagePairs.find(({ languageFrom, languageTo }) => languageFrom === languagePair.languageFrom && languageTo === languagePair.languageTo)
      return <Box key={languagePair.languageFrom+languagePair.languageTo} sx={{ display: 'flex', alignItems: "baseline", marginBottom: "4px" }}>
        <Checkbox size='small' sx={{ paddingRight: "5px" }} checked={!!pair}
          onChange={() => handleMemberAllowedLanguageParChange(languagePair)}/>
        {translate(`dict.lang.${languagePair.languageFrom}`) + ' → ' + translate(`dict.lang.${languagePair.languageTo}`)}
      </Box>
    })}
    {!!member.languagePairs.length && 
    <Box margin="16px 0px" width={400}>
      <Button
        variant='contained'
        color='secondary'
        className='outlined-button'
        onClick={handleMemberAllowedLanguagePairUpdate} 
        disabled={isLoading || JSON.stringify(allowedLanguagePairs) === JSON.stringify(member.allowedLanguagePairs)}
      >
        Update Language Pairs
      </Button>
    </Box>}
  </Box>
}

export default MemberLanguagePairChange