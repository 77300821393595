import React, { useState, useEffect } from 'react'
import { Card, CardContent, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import { styled } from '@mui/material/styles'
import { AggregationType, MetricAggregationDto, getMetric } from 'api/metrics'

const MetricsTable = ({ companyId }: { companyId: string }) => {

  const [metrics, setMetrics] = useState<MetricAggregationDto[]>([])
  const [tabletVisitMetrics, setTabletVisitMetrics] = useState<MetricAggregationDto[]>([])

  useEffect(() => {

    const fetchData = async () => {
      try {
        const [tcCntMetric, tcAvgMetric, tcRecycledSum, tcMTSum, tcTargetTextCopyCntMetric, tcTargetTextCopyWithEditCntMetric, tcAssetCreatedMetric, tcAssetCreatedWithEditMetric, jobCreatedCntMetric, editorOpenedCntMetric] = await Promise.all([
          getMetric("PORTAL_TRANSLATE", companyId, AggregationType.CNT),
          getMetric("PORTAL_TRANSLATE", companyId, AggregationType.AVG),
          getMetric("PORTAL_TRANSLATE", companyId, AggregationType.CNT, "RECYCLED", "true"),
          getMetric("PORTAL_TRANSLATE", companyId, AggregationType.CNT, "RECYCLED", "false"),
          getMetric("PORTAL_TARGET_TEXT_COPY", companyId, AggregationType.CNT),
          getMetric("PORTAL_TARGET_TEXT_COPY", companyId, AggregationType.CNT, "EDITED_MANUALLY", "true"),
          getMetric("PORTAL_ASSET_CREATED", companyId, AggregationType.CNT),
          getMetric("PORTAL_ASSET_CREATED", companyId, AggregationType.CNT, "EDITED_MANUALLY", "true"),
          getMetric("PORTAL_JOB_CREATED", companyId, AggregationType.CNT),
          getMetric("PORTAL_EDITOR_OPENED", companyId, AggregationType.CNT)
        ])        
        const [biVisited, discoverMoreVisited, tcVisited, instantVisited, enhanceVisited, trustmineVisited] = await Promise.all([
          getMetric("PORTAL_TABLET_VISITED", companyId, AggregationType.CNT, "TABLET", "BI"),
          getMetric("PORTAL_TABLET_VISITED", companyId, AggregationType.CNT, "TABLET", "DISCOVER_MORE"),
          getMetric("PORTAL_TABLET_VISITED", companyId, AggregationType.CNT, "TABLET", "TRANSLATE_AND_CREATE"),
          getMetric("PORTAL_TABLET_VISITED", companyId, AggregationType.CNT, "TABLET", "INSTANT"),
          getMetric("PORTAL_TABLET_VISITED", companyId, AggregationType.CNT, "TABLET", "ENHANCE"),
          getMetric("PORTAL_TABLET_VISITED", companyId, AggregationType.CNT, "TABLET", "TRUSTMINE")
        ])
        const tcRecycledVsMTRatio: MetricAggregationDto =
        {
          name: "T&C recycled to MT ratio",
          recentValue: safeDivide(tcRecycledSum.recentValue, tcMTSum.recentValue),
          totalValue: safeDivide(tcRecycledSum.totalValue, tcMTSum.totalValue)
        }
        const allMetrics = [
          { ...tcCntMetric, name: "T&C translation count" },
          { ...tcAvgMetric, name: "T&C translation avg word count" },
          { ...tcRecycledVsMTRatio },
          { ...tcTargetTextCopyCntMetric, name: "T&C target text copy count" },
          { ...tcTargetTextCopyWithEditCntMetric, name: "T&C target text copy with edit count" },
          { ...tcAssetCreatedMetric, name: "T&C asset created count" },
          { ...tcAssetCreatedWithEditMetric, name: "T&C asset created with edit count" },
          { ...jobCreatedCntMetric, name: "Job created from Portal count" },
          { ...editorOpenedCntMetric, name: "Editor opened from Portal count" }
        ]
        const tabletVisitMetrics = [
          { ...instantVisited, name: "Instant translation tablet visit count" },
          { ...enhanceVisited, name: "Enhancement translation tablet visit count" },
          { ...trustmineVisited, name: "Trustmining translation tablet visit count" },
          { ...biVisited, name: "BI tablet visit count" },
          { ...discoverMoreVisited, name: "Discover More tablet visit count" },
          { ...tcVisited, name: "T&C tablet visit count" }
        ]
        setMetrics(allMetrics)
        setTabletVisitMetrics(tabletVisitMetrics)
      } catch (error) {
        console.error('Error fetching data: ', error)
      }
    }
    fetchData()
  }, [])

  const StyledTableHead = styled(TableHead)({
    backgroundColor: '#e4e4e4'
  })

  const safeDivide = (a: number | undefined, b: number | undefined): number | undefined => (b != 0 && a != undefined && b != undefined ? a / b : undefined)

  const metricsTable = (metrics: MetricAggregationDto[]) => 
    <Table>
      <StyledTableHead>
        <TableRow>
          <TableCell width="60%">Metric</TableCell>
          <TableCell width="20%" align="right">Last 30 days</TableCell>
          <TableCell width="20%" align="right">Total</TableCell>
        </TableRow>
      </StyledTableHead>
      <TableBody>
        {metrics.map((metric) => (
          <TableRow key={metric.name}>
            <TableCell>{metric.name}</TableCell>
            <TableCell align="right">{metric.recentValue?.toFixed(2)}</TableCell>
            <TableCell align="right">{metric.totalValue?.toFixed(2)}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>

  return (
    <>
      <Card style={{ marginBottom: '20px' }}>
        <CardContent>
          {metricsTable(metrics)}
        </CardContent>
      </Card>
      <Card>
        <CardContent>
          {metricsTable(tabletVisitMetrics)}
        </CardContent>
      </Card>
    </>
  )
}


export default MetricsTable
