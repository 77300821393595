export const jobTranslations = { 
  job: {
    status: {
      ERROR: "Error",
      FINISHED: "Completed",
      BLOCKED: "Blocked",
      IN_PROGRESS: "In progress",
      CANCELLED: "Cancelled",
      DELETED: "Deleted",
      QUOTED: "Quoted",
      REJECTED: "Rejected"
    },
    type: {
      COMMERCIAL: 'Commercial',
      CHARITY: 'Charity',
      EXAMINATION: 'Examination'
    }
  }
}