import React, { useEffect, useState } from 'react'
import { useTranslate, useRecordContext, useNotify, useRefresh } from 'react-admin'

import Button from '@mui/material/Button'
import FormControl from '@mui/material/FormControl'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'

import { ProfileStatus } from 'api/common'
import { GatedCommunityMemberSmallRecord, GatedCommunityRecord, setUserProfileInGatedCommunity } from 'api/gatedCommunity'
import { Box } from '@mui/material'

import 'styles.scss'

const REFRESH_LIST_AFTER = 1000

interface Props {
  member: GatedCommunityMemberSmallRecord
  onClose: () => void
}

const MemberProfileChange = ({ member, onClose }: Props) => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [userProfile, setUserProfile] = useState(member.profile)

  const record = useRecordContext<GatedCommunityRecord>()
  const notify = useNotify()
  const translate = useTranslate()
  const refresh = useRefresh()

  useEffect(() => {
    setUserProfile(() => member.profile)
  }, [member])

  const handleProfileChange = (event: SelectChangeEvent<ProfileStatus>) => {
    setUserProfile(event.target.value as ProfileStatus)
  }

  const handleChangeUserProfile = async () => {
    setIsLoading(true)

    try {
      await setUserProfileInGatedCommunity(record.id, member.id as string, userProfile)
      notify('User Profile was successfully changed', { type: 'success' })
    } catch (error: any) {
      if(error?.response?.status == 403) {
        notify('Cannot set gated community manager to user without a role Gated Community Manager.', { type: 'error' })
      } else {
        notify('Something went wrong', { type: 'error' })
      }
    }
    onClose()
    setIsLoading(false)

    setTimeout(() => {
      refresh()
    }, REFRESH_LIST_AFTER)
  }

  return <div style={{ display: 'flex', flexDirection: 'column', paddingTop: '20px' }}>
    <FormControl fullWidth>
      <InputLabel id="profile">Profile</InputLabel>
      <Select labelId="profilel" value={userProfile} label="Profile" onChange={handleProfileChange}>
        <MenuItem value={'GATED_COMMUNITY_MEMBER'}>{translate('gatedCommunity.profile.GATED_COMMUNITY_MEMBER')}</MenuItem>
        <MenuItem value={'GATED_COMMUNITY_ADMIN'}>{translate('gatedCommunity.profile.GATED_COMMUNITY_ADMIN')}</MenuItem>
      </Select>
    </FormControl>
    <Box margin="16px 0px" width={400}>
      <Button
        sx={{ height: "35px" }}
        variant='contained'
        color='secondary'
        className='outlined-button'
        disabled={isLoading || member.profile === userProfile}
        onClick={handleChangeUserProfile}
      >
        Update Profile
      </Button>
    </Box>
  </div>
}

export default MemberProfileChange