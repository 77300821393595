import React, { useState } from 'react'
import { useNotify, useRefresh } from 'react-admin'

import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogActions from '@mui/material/DialogActions'
import Input from '@mui/material/Input'

import { rejectAssignment } from 'api/assignments'
import CustomButton from 'components/CustomButton'

interface Props {
  assignmentId: string
}

const RevertExecutor = ({ assignmentId }: Props) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [explanation, setExplanation] = useState<string>()
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const notify = useNotify()
  const refresh = useRefresh()

  const handleExecutorRevert = async () => {
    if(!explanation) return
    setIsLoading(true)
    try {
      await rejectAssignment(assignmentId, explanation)
      notify('Successfully reverted executor', { type: 'success' })
    } catch (error) {
        notify('Something went wrong', { type: 'error' })      
    }
    handleClose()
    setIsLoading(false)
  }

  const handleClose = () => {
    setIsOpen(false)
    refresh()
  }

  const handleOpen = () => {
    setIsOpen(true)
  }

  return <div>
    <CustomButton onClick={handleOpen}>Revert & Reassign Executor</CustomButton>
    <Dialog open={isOpen}>
      <DialogTitle> Revert & Reassign Executor </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Reason: <Input value={explanation} onChange={(event) => setExplanation(event.target.value)} />
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
        <Button variant='contained' disabled={!explanation || isLoading} onClick={handleExecutorRevert}>
            Submit
        </Button>
      </DialogActions>
    </Dialog>
  </div>
}

export default RevertExecutor