import { red, orange, green } from '@mui/material/colors'
import { GatedCommunityMemberSmallRecord } from 'api/gatedCommunity'

const rowStyle = (record: GatedCommunityMemberSmallRecord) => {
  const style = {
    borderLeftWidth: 5,
    borderLeftStyle: 'solid'
  }
  if (!record) { return {} }
  if (!(record.isActive && record.isAccountConfirmed && record.isNdaSigned)) { return { ...style, borderLeftColor: red[500] } }
  if (record.outOfOffice) { return { ...style, borderLeftColor: orange[500] } }
  return { ...style, borderLeftColor: green[500] }
}

export default rowStyle