import React from 'react'
import { useRecordContext } from 'react-admin'

import { parseTimestamp } from 'utils/helpers'
import UserService from 'UserService'

interface Props {
  source: string
  label?: string
  sortable?: boolean
  sortBy?: string
  displaySeconds?: boolean
}

const CustomDateField = ({ source, displaySeconds }: Props) => {
  const record = useRecordContext()
  const { timezone } = UserService.getTokenUserInfo()

  return record ? <div>
    <span>{parseTimestamp(record[source], timezone, true, displaySeconds)}</span>
  </div> : null}

export default CustomDateField