import React, { useState } from "react"
import { Box, Grid, Modal } from "@mui/material"
import { useNotify } from "react-admin"
import { SumbitButtons } from "common/SubmitButtons"
import RoundedPanel from 'common/RoundedPanel'

import 'styles.scss'
import { NormalTextField } from "common/TextField"
import { addComment } from "api/jobs"

interface AddCommentModalProps {
  jobId: string
  open: boolean
  onClose: () => void
  onSave: () => void
}

export const AddCommentModal = ({ jobId, open, onClose, onSave }: AddCommentModalProps) => {
  const [text, setText] = useState<string>("")
  const notify = useNotify()

  const handleSave = (e: any) => {
    e.preventDefault()
    addComment(jobId, e.target.text.value)
      .then(() => {
        onSave()
        onClose()
        setText("")
        notify('New comment added', { type: 'success' })
      })
      .catch(() => {
        notify('Failed to add new comment', { type: 'warning' })
      })
  }

  return <Modal
    open={open}
    onClose={onClose}
    disableAutoFocus={true}
  >
    <RoundedPanel modal width="500px">
      <h1>New comment</h1>
      <Box height={13} />
      <form onSubmit={handleSave} >
        <Grid container spacing={'20px'}>
          <Grid item xs={12}>
            <NormalTextField
              id="text"
              label="Add a comment ..."
              defaultValue={text}
              onChange={(e) => { setText(e.target.value) }}
              fullWidth
              minRows={4}
              multiline
            />
          </Grid>
        </Grid>
        <Box height={13} />
        <SumbitButtons onClose={onClose} onSaveLabel="Submit" saveDisabled={!text}/>
      </form>
    </RoundedPanel>
  </Modal>
}
