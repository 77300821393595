import React from 'react'
import { Layout, LayoutProps, AppBar } from 'react-admin'
import Menu from './Menu'

const chooseStyle = () => {
  const env = process.env.REACT_APP_ENVIRONMENT
  
  if (env === 'test') return { backgroundColor: '#FFBF00', color: '#000000' }
  if (env === 'dev') return { backgroundColor: '#A52A2A' }
  if (env === 'prod-main') return { backgroundColor: '#001734' }
  if (env === 'demo') return { backgroundColor: '#3164ff' }
  if (env === 'prodlike') return { backgroundColor: '#A46D49' }
  
  return undefined
}

const AppLayout = (props: LayoutProps) => <Layout {...props} appBar={() => <AppBar style={chooseStyle()}/>} menu={Menu} />

export default AppLayout