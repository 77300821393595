import React from 'react'
import { styled } from "@mui/material/styles"

import { AssignmentStatus, JobStatus, StageStatus } from 'api/common'
import { RCAStatus } from 'api/rca'

const Container = styled('span', { shouldForwardProp: (prop) => prop !== 'backgroundColor' && prop !== 'color' })<StatusColors>(({ backgroundColor, color }) => ({
  backgroundColor,
  color,
  borderRadius: '3px',
  padding: '6.5px 15px',
  width: 'max-content',
  textTransform: 'uppercase',
  marginLeft: '5px',
  font: 'normal normal bold 14px / 14px Inter'
}))

interface StatusColors {
  backgroundColor: string
  color: string
}

interface StatusProps {
  status?: StageStatus | AssignmentStatus | JobStatus | RCAStatus,
  text: string
}

const Status = ({ status, text }: StatusProps) => {

  const getColors = (status?: StageStatus | AssignmentStatus | JobStatus | RCAStatus): StatusColors => {
    if(!status) {
      return { backgroundColor: '#F2F2F2', color: '#3B3B3B' } 
    }
    if(['DONE', 'FINISHED', 'DELIVERED', 'COMPLETED', 'APPROVED'].includes(status)) {
      return { backgroundColor: '#A8DDC2', color: '#064A27' }
    }
    if(['IN_PROGRESS', 'SUPERSEDED', 'ACCEPTED'].includes(status)) {
      return { backgroundColor: '#84D0FD', color: '#043958' }
    }
    if(['STARTED', 'CANCELLED', 'BLOCKED'].includes(status)) {
      return { backgroundColor: '#D8D8D8', color: '#3B3B3B' }
    }
    if(['WAITING', 'NO_EXECUTORS', 'PENDING_ACCEPTANCE', 'PENDING', 'REQUESTED'].includes(status)) {
      return { backgroundColor: '#F6D887', color: '#5D4609' }
    }
    if(['REJECTED', 'MISSED', 'ERROR', 'INTERRUPTED', 'DELETED'].includes(status)) {
      return { backgroundColor: '#FF9296', color: '#682527' }
    }
    return { backgroundColor: '#F2F2F2', color: '#3B3B3B' } //default
  }

  return <Container {...getColors(status)}>{text}</Container>
}

export default Status