import React from 'react'
import { useNotify } from 'react-admin'

import { ExtendedJobRecord, sendReminder } from 'api/jobs'
import { hasErrorCode } from 'utils/helpers'
import ActionButton from 'components/ActionButton'

interface Props {
  job: ExtendedJobRecord
}
const SendReminder = ({ job }: Props) => {
  const notify = useNotify()
  
  const handleSendReminder = async () => {
    try {
      await sendReminder({ 
        jobId: job.id
      })
      notify('Reminder successfully sent', { type: 'success' })
    } catch (error: any) {
      if (hasErrorCode(409, error)) {
        notify(error.response.data.message, { type: 'error' })
      } else { 
        notify('Something went wrong', { type: 'error' }) 
      }
    }
  }

  return <ActionButton onClick={handleSendReminder}>Send Reminder</ActionButton>
}

export default SendReminder