import React, { useEffect, useState } from 'react'
import { useTranslate } from 'ra-core'

import { styled } from '@mui/material/styles'
import IconButton from '@mui/material/IconButton'
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded'
import KeyboardArrowUpRoundedIcon from '@mui/icons-material/KeyboardArrowUpRounded'
import { Table, TableCell, TableRow, TableHead, TableBody } from '@mui/material'

import { SimpleStageRecord, UserFile } from 'api/jobs'
import SectionFileDownload from './SectionFileDownload'
import { SectionAttachmentDownload } from './SectionAttachmentDownload'
import { getAllStageAttachmentsDescription } from 'api/stages'

export const StageRowWrapper = styled('div')`
  display:flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding-bottom: 10px;
`
export const StageRow = styled('div')`
  display:flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
`
const labelStyle = {
  margin: 0,
  textAlign: 'left' as const
}

interface Props {
  stage: SimpleStageRecord,
  targetLang: string
}
const StageFileDownload = ({ stage, targetLang }: Props) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(false)
  const [attachments, setAttachments] = useState<UserFile[]>([])

  useEffect(() => {
    getAllStageAttachmentsDescription(stage.id).then((attachments) => {
      setAttachments(attachments)
    }
    )
  }, [])

  const translate = useTranslate()

  return <StageRowWrapper>
    <StageRow>
      <IconButton onClick={() => setIsExpanded(prevState => !prevState)}>
        {isExpanded ? <KeyboardArrowUpRoundedIcon /> : <KeyboardArrowDownRoundedIcon />}
      </IconButton>
      <p style={labelStyle}>{translate(`stage.type.${stage.type}`)}</p>
    </StageRow>
    {isExpanded && <Table>
      <TableHead>
        <TableRow>
          <TableCell>File name:</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {stage.stageJobSections.map((section) => (
          <SectionFileDownload key={section.id} stage={stage} section={section} targetLang={targetLang}/>
        ))}
        {
          attachments.map((attachment) => (
            <SectionAttachmentDownload attachment={attachment} key={attachment.fileId} stageId={stage.id} /> 
          ))
        }
      </TableBody>
    </Table>
    }
  </StageRowWrapper>
}

export default React.memo(StageFileDownload) 