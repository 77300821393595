import React from 'react'
import { useTranslate, Show, useRecordContext, Link } from 'react-admin'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { AdminAssignmentDashboardRecord } from 'api/assignments'
import { parseTimestamp } from 'utils/helpers'
import UserService from 'UserService'
import { Divider } from '@mui/material'
import SideCard from 'components/SideCard'
import Status from 'components/Status'
import moment from 'moment'
import AssignmentShowActions from './AssignmentShowActions'
import { CARD_WIDTH, CardFooter, CardHeader, GAP, NormText } from 'job/jobShow/Common'
import { Arrow } from 'job/jobShow/Arrow'

const DELTA_GREEN = '#33C26C'
const DELTA_RED = '#DB474C'

const AssignmentShow = () => {
  return <Show title={<VisitorTitle />} component="div" sx={{ bgcolor: '#FAFAFA', paddingLeft: '4%', paddingRight: '1%', height: '100%', '.RaShow-main': { marginTop: '71px' } }}>
    <AssignmentContent />
  </Show>
}

const AssignmentInfo = () => {
  const record = useRecordContext<AdminAssignmentDashboardRecord>()
  const translate = useTranslate()

  if (!record) return null

  return <Box display='flex' gap='35px'>
    <SideCard width={CARD_WIDTH}>
      <Box padding='33px 26px 26px 54px' display='flex' alignItems='center' gap='18px'>
        <CardHeader>
          <b>
            {translate(`stage.type.${record?.type}`)}
          </b>
        </CardHeader>
      </Box>
      <Divider />
      <Box padding='47px 2px 30px 54px' display='flex' flexDirection='column' gap='30px'>
        <NormText>
          <b>Stage Id:</b> <Link to={`/stages/${record?.stageId}/show`}>{record?.stageId}</Link>
        </NormText>

        <div>
          <NormText>
            <b>Source Language:</b> {translate(`dict.lang.${record?.sourceLanguage}`)}
          </NormText>
          <NormText>
            <b>Target Language:</b> {translate(`dict.lang.${record?.targetLanguage}`)}
          </NormText>
        </div>

        <div>
          <NormText>
            <b>Word Count For Calculations: </b>{record?.paidInputWordCount}
          </NormText>
        </div>

        <div>
          <NormText>
            <b>Contributor:</b> <Link to={`/users/${record?.executorId}/show`}>{record?.executor}</Link>
          </NormText>
          <NormText>
            <b>Exfluenscore:</b> {record?.trustChainScore}
          </NormText>
        </div>
      </Box>

      <CardFooter>
        <Box display='flex' alignItems='center' paddingLeft='57px'>
          <NormText><b>Price:</b> €{record?.price.toFixed(2)}</NormText>
        </Box>
      </CardFooter>
    </SideCard>
  </Box>
}

function areMinutesInvalid (minutes?: number): minutes is undefined {
  return minutes === undefined || Number.isNaN(minutes)
}

function minutesAsHours (minutes?: number) {
  if (areMinutesInvalid(minutes)) return <></>

  const hours = Math.floor(minutes / 60)
  const remainingMinutes = minutes % 60
  
  return `${hours}h ${remainingMinutes}m`
}

/**
 * 
 * @param from from date
 * @param to to date
 * @param colored 'green-positive' | 'red-positive' | false (default) - if 'green-positive' then green if delta is positive, if 'red-positive' then red if delta positive
 * @param zeroIncluded if true then 0 will be colored according to colored param, 0 is treated as positive.
 */
export function timeDelta (from?: moment.Moment, to?: moment.Moment, colored: "green-positive" | "red-positive" | false = false, zeroIncluded = false) {
  const minutes = to?.diff(from, 'minutes')

  if (areMinutesInvalid(minutes)) return <></>

  if (colored === false) return minutesAsHours(minutes)

  const color_direction = colored === 'green-positive' ? minutes >= 0 : minutes <= 0
  const color = color_direction ? DELTA_GREEN : DELTA_RED
  const sign = minutes > 0 ? '+' : '-'

  return <NormText sx={{ color: minutes != 0 || zeroIncluded ? color : "none", fontWeight: '600' }}>
    {minutes != 0 ? sign : <>&nbsp;</>}&nbsp;{minutesAsHours(Math.abs(minutes))}
  </NormText>  
}

const AssignmentDates = () => {
  const record = useRecordContext<AdminAssignmentDashboardRecord>()
  const translate = useTranslate()
  const { timezone } = UserService.getTokenUserInfo()

  const dueDate = moment(record?.dueDate)
  const uploadDate = moment(record?.uploadDate)
  const deliveryDate = moment(record?.deliveryDate)
  const claimDate = moment(record?.claimDate)

  const dateToDisplay = (date?: string) => {
    return date ? parseTimestamp(date, timezone) : 'NONE'
  }

  return <SideCard width={CARD_WIDTH}>
    <Box padding='30px 25px 20px 35px' display='flex' alignItems='center' gap='18px'>
      <CardHeader>
        <b>Status: </b>
      </CardHeader>
      <Status status={record?.status} text={translate(`assignment.status.${record?.status}`)} />
    </Box>

    <Divider />

    <Box display='flex' gap={GAP} padding='40px 26px 40px 39px' width={CARD_WIDTH}>
      <Box display='flex' flexDirection='column' gap="32px" width="120px">
        <NormText><b>Upload Date:</b></NormText>
        <NormText><b>Due Date:</b></NormText>
      </Box>

      <Box display='flex' flexDirection='column' gap="32px" width="215px">
        <NormText>{parseTimestamp(record?.uploadDate, timezone)}</NormText>
        <NormText>{parseTimestamp(record?.dueDate, timezone)}</NormText>
      </Box>

      <Arrow />

      <Box display='flex' justifyContent='center' alignItems='center'>
        <NormText sx={{ fontWeight: '600' }}>&nbsp;&nbsp;{timeDelta(uploadDate, dueDate)}</NormText>
      </Box>
    </Box>

    <Divider />

    <Box padding='26px 26px 46px 39px' display='flex' gap={GAP} width={CARD_WIDTH}>
      <Box display='flex' gap="32px" flexDirection='column' width="120px">
        <NormText><b>Claimed:</b></NormText>
        <NormText><b>Delivery Date:</b></NormText>
      </Box>
      <Box display='flex' gap="32px" flexDirection='column' width="215px">
        <NormText>{dateToDisplay(record?.claimDate)}</NormText>
        <NormText>{dateToDisplay(record?.deliveryDate)} </NormText>
      </Box>

      <div style={{ width:"2px" }}/>

      <Box display='flex' gap="32px" flexDirection='column'>
        <NormText sx={{ fontWeight: '600' }}>&nbsp;&nbsp;{timeDelta(uploadDate, claimDate)}</NormText>
        <NormText sx={{ fontWeight: '600' }}>{timeDelta(dueDate, deliveryDate, 'red-positive', true)}</NormText>
      </Box>
    </Box>

  </SideCard>
}

const VisitorTitle = () => {
  const record = useRecordContext<AdminAssignmentDashboardRecord>()
  return <span>{record?.id}</span>
}

const AssignmentContent = () => {
  return <Box display={{ md: 'block', lg: 'flex' }} flexDirection='column' gap="35px" flexWrap='wrap'>
    <Typography sx={{ font: 'normal normal bold 26px/31px Inter' }}>
      Assignment Information
    </Typography>
    <AssignmentShowActions />
    <Box display='flex' gap="30px" flexWrap='wrap' alignItems='flex-start'>
      <AssignmentInfo />
      <AssignmentDates />
    </Box>
  </Box>
}

export default AssignmentShow