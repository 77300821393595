import React from 'react'
import { Datagrid, ReferenceManyField, BooleanField, FunctionField } from 'react-admin'
import Box from '@mui/material/Box'
import CustomDateField from 'common/CustomDateField'
import CustomLinkField from 'common/CustomLinkField'
import { currencyCodeToSymbol, parsePrice } from 'utils/helpers'
import CustomPagination from 'common/CustomPagination'

import 'styles.scss'

const CompanyInvoices = () => {
  return <Box>
    <Box height='50px' />
    <ReferenceManyField
      reference="invoices"
      target="companyId"
      pagination={<CustomPagination />}
      sort={{ field: 'creationDate', order: 'DESC' }}
      perPage={50}
      fullWidth
      className='gray-skin'
    >
      <Datagrid resource='invoices' bulkActionButtons={false} className='assigments-datagrid small-row'>
        <CustomLinkField source="id" targetFieldId='id' show label={'Invoice Number'} sortable={false} basePath='invoices' />
        <CustomDateField source="startDate" label={'Start Date'} sortable={false} />
        <CustomDateField source="endDate" label={'End Date'} sortable={false} />
        <CustomDateField source="creationDate" label={'Creation Date'} />
        <FunctionField source='sum' render={(row: any) => <b>{currencyCodeToSymbol(row.sum.currency)}{parsePrice(row.sum.value)}</b>} textAlign='right' />
        <BooleanField source='pdf' label={'PDF'} />
        <BooleanField source='accepted' />      
        <CustomDateField source="acceptanceDate" label={'Acceptance Date'} />
      </Datagrid>
    </ReferenceManyField>
  </Box>
}

export default CompanyInvoices