import React from 'react'
import { BooleanField, Datagrid, List, NullableBooleanInput, NumberField } from 'react-admin'

import CustomLinkField from 'common/CustomLinkField'
import CustomPagination from 'common/CustomPagination'
import UserService from 'UserService'
import { Box } from '@mui/material'
import { OutlinedSearchInput } from 'common/OutlinedSearchInput'
import { FilterExportToolbar } from 'common/FilertExportToolbar'

import 'styles.scss'
import ActionButton from 'components/ActionButton'
import { CreateGatedCommunityModal } from './gcModals/CreateGC'
import { Chipify } from 'common/Chipify'
import { OutlinedArrayInput } from 'common/OutlinedArrayInput'
import { getOptionsSelectFromArray } from 'utils/helpers'

const recyclableTranslationLevels = ['ENHANCED', 'TRUST_MINED']

const filters = [
  <OutlinedSearchInput source='q' alwaysOn label="Search" />,
  <OutlinedSearchInput source='manager' alwaysOn label="Manager" />,
  <NullableBooleanInput source="anonymizationEnabled" className='custom-array-input' alwaysOn label="Anonymization" />,
  <OutlinedArrayInput source="recyclingLevel" alwaysOn label="Recycling level"
    choices={getOptionsSelectFromArray(recyclableTranslationLevels, 'dict.recyclableTranslationLevels.')} />

]

const GatedCommunityList = (props: any) => {
  const [open, setOpen] = React.useState<"CREATE" | undefined>(undefined)

  const canCreate = UserService.hasRoles(['ROLE_PLATFORM_MANAGER', 'ROLE_SUPPORT', 'ROLE_GATED_COMMUNITY_MANAGER'])
  const createButton = <ActionButton onClick={() => { setOpen('CREATE') }}>Create</ActionButton> 

  return <Box margin="56px 60px">
    {open&&<CreateGatedCommunityModal open={open === 'CREATE'} onClose={() => { setOpen(undefined) }} record={undefined} />}
    <List {...props}
      perPage={50}
      sort={{ field: 'name', order: 'ASC' }}
      actions={<FilterExportToolbar 
        filters={filters} 
        showFilter 
        showExport 
        right={ canCreate && createButton || undefined }
      />}
      pagination={<CustomPagination />}
      className='gray-skin'
    >
      <Datagrid bulkActionButtons={false} className='assigments-datagrid'>
        <CustomLinkField source="name" label='Gated Community' show sortable={false} basePath='gatedCommunities' targetFieldId='id' />
        <NumberField source='membersCount' label='Members' sortBy='count' />
        <NumberField source='activeMembersCount' label='Members Active Last 30 days' sortable={false}/>
        <NumberField source='jobsCount' label='Projects Total' sortable={false}/>
        <NumberField source='latestJobsCount' label='Projects Requested Last 30 days' sortable={false}/>
        <BooleanField source='anonymizationEnabled' label='Anonymization' sortable={false}/>
        <Chipify label='Recycling level' source='recyclableTranslationLevels' translation='dict.recyclableTranslationLevels.' variant='squere-chip' sortable={false} />
        <Chipify label='GC managers' source='managers' subsource='userName' variant='squere-chip' sortable={false} />
      </Datagrid>
    </List>
  </Box>
}

export default React.memo(GatedCommunityList)