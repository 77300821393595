import React, { useState } from 'react'
import { useNotify } from 'react-admin'

import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogActions from '@mui/material/DialogActions'

import { cancelJob } from 'api/jobs'
import ActionButton from 'components/ActionButton'

interface Props {
  jobId: string
  name: string
}

const CancelJob = ({ jobId, name }: Props) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const notify = useNotify()

  const handleCancelJob = async () => {
    try {
      await cancelJob(jobId)
      notify('Successfully canceled job', { type: 'success' })
    } catch (error: any) {
      notify(error.response.data.message || 'Something went wrong', { type: 'error' })
    }
    handleClose()
  }

  const handleClose = () => {
    setIsOpen(false)
  }

  const handleOpen = () => {
    setIsOpen(true)
  }

  return <div>
    <ActionButton onClick={handleOpen}>Cancel Project</ActionButton>
    <Dialog open={isOpen} onClose={handleClose}>
      <DialogTitle>
        Cancel Project
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to cancel job: <b>{name}</b>?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
        <Button variant='contained' onClick={handleCancelJob} autoFocus>
            Submit
        </Button>
      </DialogActions>
    </Dialog>
  </div>
}

export default CancelJob