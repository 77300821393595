import React from 'react'
import CustomButton, { ButtonProps } from './CustomButton'

const ActionButton = ({ onClick, disabled, children }: ButtonProps) => {
  return (
    <CustomButton onClick={onClick} disabled={disabled} sx={{
      padding: '8px 20px',
      minWidth: 'auto'
    }} >
      {children}
    </CustomButton>
  )
}

export default ActionButton
