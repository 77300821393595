import React from 'react'
import { EditProps, Identifier, useRecordContext, useGetOne } from 'react-admin'

import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'

import { GatedCommunityMemberSmallRecord, GatedCommunityRecord } from 'api/gatedCommunity'
import MemberRolesChange from './MemberRolesChange'
import MemberDelete from './MemberDelete'
import MemberProfileChange from './MemberProfileChange'
import MemberLanguagePairChange from './MemberLanguagePairChange'
import RoundedPanel from 'common/RoundedPanel'

import 'styles.scss'
import { Title } from 'common/RoundedPanel/Label'

interface Props extends EditProps<GatedCommunityMemberSmallRecord> {
  onClose: () => void
  id: Identifier
  gcName: string
}

const MemberEdit = ({ onClose, id, gcName }: Props) => {
  const record = useRecordContext<GatedCommunityRecord>()
  const { data } = useGetOne<GatedCommunityMemberSmallRecord>(`gatedCommunities/${record.id}/members`, { id: id })

  if(!data) return null

  return <Box pt={5} width={{ xs: '100vw', sm: 400 }} mt={{ xs: 2, sm: 1 }}>
    <RoundedPanel noBorder width="100%" padding="16px 16px">
      <Stack direction="row" p={2}>
        <Title>{data.userName}</Title>
        <IconButton onClick={onClose} className="close-button">
          <CloseIcon />
        </IconButton>
      </Stack>
      <hr/>
      <MemberRolesChange member={data} onClose={onClose} />
      <hr/>
      <MemberProfileChange member={data} onClose={onClose} />
      <hr/>
      <MemberLanguagePairChange member={data} onClose={onClose} />
      <hr/>
      <MemberDelete member={data} onClose={onClose} gcName={gcName} />
      <Box height={20} />
    </RoundedPanel>
  </Box>
}

export default MemberEdit

