import Keycloak from "keycloak-js"

import { Role } from "api/common"

const _kc = new Keycloak({
  url: process.env.REACT_APP_AUTHORIZATION_SERVER_URL ? process.env.REACT_APP_AUTHORIZATION_SERVER_URL : window.location.origin + "/auth",
  clientId: "exfluency-admin-dashboard-frontend",
  realm: "exfluency-realm"
})

const initKeycloak = (onAuthenticatedCallback: () => void) => {
  _kc.init({
    onLoad: 'check-sso',
    pkceMethod: 'S256'
  })
    .then((authenticated) => {
      if (!authenticated && !unauthenticatedPath()) {
        doLogin()
      } else {
        onAuthenticatedCallback()
      }
    })
    .catch(console.error)
}

const unauthenticatedPath = () => ['/logout', '/terms-of-service', '/privacy-notice'].some(e => e === window.location.pathname) ||
  window.location.pathname.includes('/verify-email') || window.location.pathname.includes('/reset-password') || 
  window.location.pathname.includes('/auth')

const doLogin = _kc.login

const doLogout = _kc.logout

const getToken = async () => {
  await updateToken(() => (console))
  return _kc.token
}

const isLoggedIn = () => !!_kc.token

const updateToken = (successCallback: () => void) =>
  _kc.updateToken(10)
    .then(successCallback)
    .catch(doLogin)

const getUsername = () => _kc.tokenParsed?.preferred_username

interface TokenUserInfo {
  id: string,
  login: string,
  name: string,
  surname: string,
  email: string,
  currency: string,
  timezone: string
}

const getTokenUserInfo = (): TokenUserInfo => {
  const token = _kc.tokenParsed as any
  return {
    id: token?.exfluencyId || '',
    login: token?.preferred_username || '',
    name: token?.given_name || '',
    surname: token?.family_name || '',
    email: token?.email || '',
    currency: token?.exfluencyCurrency || '',
    timezone: token?.exfluencyTimezone || 'Europe/Warsaw'
  }}

const hasRoles = (checkRoles: Role[], exact = false): boolean => {
  const roles = (_kc.tokenParsed?.resource_access?.['exfluency-workflow']?.roles || []) as Role[]

  if(roles.includes('ROLE_EXFLUENCY_ADMIN')) return true

  if(exact) {
    return roles.every(role => checkRoles.includes(role))
  }
  return roles.some(role => checkRoles.includes(role))
}

const getRoles = () => (_kc.tokenParsed?.resource_access?.['exfluency-admin-dashboard-api']?.roles || []) as Role[]

const isAuthenticated = () => _kc.authenticated &&
  _kc.token ? Promise.resolve() : Promise.reject("Failed to obtain access token.")

const UserService = {
  initKeycloak,
  doLogin,
  doLogout,
  isLoggedIn,
  getToken,
  updateToken,
  getUsername,
  hasRoles,
  unauthenticatedPath,
  getRoles,
  isAuthenticated,
  getTokenUserInfo
}

export default UserService
