import React, { FormEvent, useEffect, useState } from 'react'
import { Title, Link, Button, Loading, useTranslate, useNotify, useGetList, useRefresh, useCreate, useUpdate, useGetOne, Translate } from 'react-admin'
import { CARD_WIDTH, Container, GAP, NoSel, NormText, SM_FONT_LINE_H28, STANDARD_PADDING } from './jobShow/Common'
import { Box, Typography, Card, CardContent, Checkbox, Divider, TextField, Stack, styled, Paper, IconButton, Tooltip } from '@mui/material'
import { useParams } from 'react-router'
import SideCard from 'components/SideCard'
import Status from 'components/Status'
import RoundedPanel from 'common/RoundedPanel'
import { Done } from '@mui/icons-material'
import { RCA, RCAType, TrueTranslationError, CorrectiveAndPreventiveAction, RCAResponsibleUser, TRUE_TRANSLATION_ERRORS, CORRECTIVE_AND_PREVENTIVE_ACTIONS, RCAStatus } from 'api/rca'
import { MobileDatePicker } from '@mui/lab'
import { ExtendedJobRecord } from 'api/jobs'
import { UserRecord } from 'api/users'
import moment from 'moment'
import UserService from 'UserService'
import { pdf } from '@react-pdf/renderer'
import { RCARaportPDF } from './RCARaportPDF'
import CustomButton from 'components/CustomButton'
import SaveAltIcon from '@mui/icons-material/SaveAlt'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import { parseTimestamp } from 'utils/helpers'

const maxContent = 'max-content'
const backgroundColor = (disabled: boolean | undefined) => disabled ? '#f0f0f0' : 'white'
const buttonStyle: React.CSSProperties | undefined = {                  
  borderRadius: '3px',
  padding: '7.5px 15px',
  width: maxContent,
  textTransform: 'uppercase',
  marginLeft: '5px',
  font: 'normal normal bold 14px / 14px Inter'
}

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: '#e4e4e4',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'left',
  color: theme.palette.text.secondary
}))

export const RCACheckbox = (props: {
  id?: string
  checked?: boolean
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
  disabled?: boolean
  style?: React.CSSProperties | undefined
  }) => {
  const { id, checked, onChange, disabled, style } = props
  return <Checkbox
    id={id}
    className="action-checkbox"
    style={{ width: "18px", height: "18px", backgroundColor: backgroundColor(disabled), marginRight: "0px", padding: "0px", ...style }}
    icon={<></>}
    checkedIcon={<Done color='action' style={{ width: "17px", height: "17px" }}/>}
    checked={checked}
    onChange={onChange}
    disableRipple={true}
    disabled={disabled}
  />
}

export const saveFile = (blob: any, fileName: string) => {
  const a = document.createElement('a')
  a.download = fileName
  a.href = URL.createObjectURL(blob)
  a.addEventListener('click', () => {
    setTimeout(() => URL.revokeObjectURL(a.href), 30 * 1000)
  })
  a.click()
}

const handleDownload = async (rcaRecord: RCA, job: ExtendedJobRecord, requester: UserRecord, translate: Translate) => {
  const blob = await pdf(RCARaportPDF(rcaRecord, job, requester, translate)).toBlob()
  saveFile(blob, `RCA - Zen. ${rcaRecord.zendeskTicketNumber || ''} - Proj. ${job.externalProjectId || job.name}.pdf`)
}

const RCAHead = (props : {
  name: string
  job: ExtendedJobRecord
  rcaRecord: RCA | undefined
  rcaInitialStatus: RCAStatus
  rcaMode: string
  setRcaMode: any
  requester: UserRecord
}) => {
  const { name, job, rcaRecord, rcaInitialStatus, rcaMode, setRcaMode, requester } = props
  const translate = useTranslate()

  return <Box padding={STANDARD_PADDING} paddingRight='35px' paddingBottom='20px' display='flex' gap='3px' 
    justifyContent='space-between' style={{ backgroundColor: 'white', borderRadius: rcaRecord ? '8px 8px 0px 0px' : '8px' }}>
    <Box display='flex' width={maxContent}>
      <Typography sx={{ width: '100%', font: 'normal normal bold 26px/31px Inter' }}>
        {name}
      </Typography>
    </Box>
    {(rcaRecord || rcaMode=='add') ? 
    <Box display='flex' flexDirection='row' justifyContent='end' gap='3px' width={maxContent} alignItems='center'>
      {rcaRecord && ['APPROVED', 'REJECTED'].includes(rcaRecord.rcaStatus) &&
        <CustomButton onClick={() => handleDownload(rcaRecord, job, requester, translate)} sx={{ padding: '3px 15px', minWidth: 'auto' }} >
          <SaveAltIcon sx={{ verticalAlign: 'bottom', height: '20px' }}/> Download PDF
        </CustomButton>
      }
      <Status status={rcaRecord?.rcaStatus || rcaInitialStatus} text={translate(`rca.status.${rcaRecord?.rcaStatus || rcaInitialStatus}`)} />
    </Box> :
    <Box display='flex' width={maxContent}>
      {UserService.hasRoles(['ROLE_EXFLUENCY_ADMIN', 'ROLE_PLATFORM_MANAGER']) && 
        <CustomButton onClick={() => setRcaMode('add')} sx={{ padding: '4.5px 20px', minWidth: 'auto' }} >
          Add RCA
        </CustomButton>
      }
    </Box>}
  </Box>
}

const RCADateAndZendeskTicket = (props: {
  requestDate: string | undefined
  handleChangeRequestDate: any
  zendeskTicketNumber: number | undefined
  handleChangeZendeskTicketNumber: any
  rcaMode: string
}) => {
  const { requestDate, handleChangeRequestDate, zendeskTicketNumber, handleChangeZendeskTicketNumber, rcaMode } = props
  return (
    <Box display='flex' >
      <Box display='flex' sx={{ width: '47%' }} alignItems= 'center' padding='25px' paddingLeft='35px'>
        <NormText sx={{ font: SM_FONT_LINE_H28, paddingRight: '5px' }}>
          <b>Request date:</b>
        </NormText>
        <MobileDatePicker
          showToolbar={false}
          renderInput={(props) => <TextField id='requestDate' sx={{ "& .MuiInputBase-input": { width: '85px', padding: '5px' } }} {...props} />}
          inputFormat='DD-MM-YYYY'
          mask='__-__-____'
          value={requestDate}
          onChange={(newValue) => handleChangeRequestDate(newValue)}
          disabled={rcaMode=='read'}
        />
      </Box>
      <Divider orientation='vertical' flexItem />
      <Box display='flex' sx={{ width: '53%' }} alignItems= 'center' padding='25px' paddingRight='35px'>
        <NormText sx={{ font: SM_FONT_LINE_H28, paddingRight: '5px' }}>
          <b>Zendesk ticket #:</b>
        </NormText>
        <TextField
          id='zendeskTicketNumber'
          inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', style: { width: '65px', padding: 5 } }}
          value={zendeskTicketNumber || ''}
          onChange={(event) => handleChangeZendeskTicketNumber(event.target.value)}
          disabled={rcaMode=='read'}
        />
        {zendeskTicketNumber && <a href={`https://exfluency.zendesk.com/agent/tickets/${zendeskTicketNumber}`} target="_blank" rel="noreferrer" style={{ textDecoration: "none" }}>
          <IconButton aria-label='go to zendesk ticket' sx={{ padding: '4.5px' }} >
            <Tooltip id="button-go-to-zendesk-ticket" title="Go to Zendesk ticket">
              <OpenInNewIcon />
            </Tooltip>
          </IconButton>
        </a>} 
      </Box>
    </Box>
  )
}

const RCAJobInfo = ({ job, requester } : { 
  job: ExtendedJobRecord
  requester: UserRecord
}) => {
  const { name, sourceLang, targetLang, externalProjectId, requesterId } = job
  const translate = useTranslate()

  return (
    <Container sx={{ paddingRight:'35px' }}>
      <div>
        <NormText>
          <NoSel><b>Project Name:</b></NoSel> {name}
        </NormText>
        <NormText>
          <b>Language combination: </b>{translate(`dict.lang.${sourceLang}`)}<span> -{'>'} </span>{translate(`dict.lang.${targetLang}`)}
        </NormText>
        <NormText>
          <b>External ID:</b> {externalProjectId}
        </NormText>
        <NormText>
          <b>Requester:</b>  <Link to={`/users/${requesterId}/show`}>{requester.firstName} {requester.lastName}</Link>
        </NormText>
      </div>
    </Container>
  )
}

const RCAResponsibleUsers = (props: {
  job: ExtendedJobRecord
  rcaResponsibleUsersByStageIds: RCAResponsibleUser[]
  handleChangeResposibleUsersByStageIds: any
  rcaMode: string
}) => {
  const { job, rcaResponsibleUsersByStageIds, handleChangeResposibleUsersByStageIds, rcaMode } = props
  const translate = useTranslate()

  return (
    <Container sx={{ paddingRight:'35px' }}>
      <div>
        <NormText>
          <NoSel><b>Responsible user: </b></NoSel>
        </NormText>
        <RoundedPanel noBorder padding='5px 0px' backgroundColor={backgroundColor(rcaMode=='read')}>
          {job.stages.filter(stage => ['REDACTION', 'ENHANCEMENT', 'TRUST_MINING', 'FINAL_REDACTION'].includes(stage.type)).map(
            ({ type, id: stageId, assignments }) => {
              const assignment = assignments.find(({ status }) => ['ACCEPTED', 'DELIVERED', 'CANCELLED', 'FINISHED', 'INTERRUPTED'].includes(status))
              return assignment?.executorId && <NormText key={stageId}>
                <RCACheckbox
                  id={stageId}
                  style={{ marginRight: "10px" }}
                  checked={rcaResponsibleUsersByStageIds.find(it => it.stageId == stageId)? true : false}
                  onChange={e => handleChangeResposibleUsersByStageIds(e, stageId, assignment.executorId)}
                  disabled={rcaMode=='read'}
                />
                { }{translate(`rca.rcaResposibleUserByStageType.${type}`)}: {
                  <Link to={`/users/${assignment.executorId}/show`}>
                    {assignment.executor.length > 12 ? `${assignment.executor.substring(0,12)}...` : assignment.executor}
                  </Link>
                }
              </NormText>
            }
          )}
        </RoundedPanel>
      </div>
    </Container>
  )
}

const RCATrueTranslationsErrorsWithIssue = (props: {
  trueTranslationsErrors: TrueTranslationError []
  handleChangeTTE: any
  issue: string | undefined
  setIssue: any
  rcaMode: string
}) => {
  const { trueTranslationsErrors, handleChangeTTE, issue, setIssue, rcaMode } = props
  const translate = useTranslate()

  return (
    <Container sx={{ paddingRight:'35px' }}>
      <div>
        <NormText>
          <NoSel><b>True Translation Errors (TTE): </b></NoSel>
        </NormText>
        <RoundedPanel noBorder padding='5px 0px' backgroundColor={backgroundColor(rcaMode=='read')}>
          <Stack direction="row" flexWrap="wrap" gap='10px 10px'>
            {TRUE_TRANSLATION_ERRORS.map((tte) => {
              return (
                <Item elevation={0} key={tte}> 
                  <NormText>{translate(`rca.trueTranslationError.${tte}`)}&nbsp;
                    <RCACheckbox
                      id={tte}
                      checked={trueTranslationsErrors.includes(tte)}
                      onChange={e => handleChangeTTE(e, tte)}
                      disabled={rcaMode=='read'}
                    />
                  </NormText>
                </Item>
              )
            })}
          </Stack>
        </RoundedPanel>
        <NormText>
          <NoSel><b>Issue: </b></NoSel>
        </NormText>
        <TextField
          id='issue'
          fullWidth
          multiline
          rows={4}
          value={issue || ''}
          onChange={e => setIssue(e.target.value)}
          placeholder="Add description"
          disabled={rcaMode=='read'}
        />
      </div>
    </Container>
  )
}

const RCAJustificationWithCAPA = (props: {
  justification: string | undefined
  setJustification: any
  correctiveAndPreventiveActions: CorrectiveAndPreventiveAction []
  handleChangeCAPA: any
  capaOtherContent: string | undefined
  setCapaOtherContent: any
  comments: string | undefined
  setComments: any
  rcaMode: string
}) => {
  const { justification, setJustification, correctiveAndPreventiveActions, handleChangeCAPA, capaOtherContent, setCapaOtherContent, 
    comments, setComments, rcaMode } = props
  const translate = useTranslate()
  return (<>
    <hr />
    <Container sx={{ paddingRight:'35px' }}>
      <div>
        <NormText>
          <NoSel><b>Root cause</b></NoSel>
        </NormText>
        <TextField
          id='justification'
          fullWidth
          multiline
          rows={4}
          value={justification || ''}
          onChange={e => setJustification(e.target.value)}
          placeholder="Add description"
          disabled={rcaMode=='read'}
        />
      </div>
      <div>
        <NormText>
          <NoSel><b>CAPA</b></NoSel>
        </NormText>
        <RoundedPanel noBorder padding='5px 0px' backgroundColor={backgroundColor(rcaMode=='read')}>
          <Stack direction="row" flexWrap="wrap" gap='10px 10px'>
            {CORRECTIVE_AND_PREVENTIVE_ACTIONS.filter(capa => capa !== 'OTHER').map((capa) => {
              return (
                <Item elevation={0} key={capa}> 
                  <NormText>{translate(`rca.correctiveAndPreventiveAction.${capa}`)}&nbsp;
                    <RCACheckbox
                      id={capa}
                      checked={correctiveAndPreventiveActions.includes(capa)}
                      onChange={e => handleChangeCAPA(e, capa)}
                      disabled={rcaMode=='read'}
                    />
                  </NormText>
                </Item>
              )
            })}
          </Stack>
        </RoundedPanel>
        <hr />
        <RoundedPanel noBorder padding='5px 0px' backgroundColor={backgroundColor(rcaMode=='read')}>
          <Box padding={0} display='flex' gap='10px' justifyContent='flex-start'>
            <Box display='flex' flexDirection='column' gap='31px' >
              <Item sx={{ width: maxContent }} elevation={0}> 
                <NormText>{translate(`rca.correctiveAndPreventiveAction.${'OTHER'}`)}&nbsp; 
                  <RCACheckbox
                    id='OTHER'
                    checked={correctiveAndPreventiveActions.includes('OTHER')}
                    onChange={e => handleChangeCAPA(e, 'OTHER')}
                    disabled={rcaMode=='read'}
                  />
                </NormText>
              </Item>
            </Box>
            {correctiveAndPreventiveActions.includes('OTHER') && 
              <TextField
                id='capaOtherContent'
                fullWidth
                multiline
                rows={2}
                value={capaOtherContent || ''}
                onChange={e => setCapaOtherContent(e.target.value)}
                placeholder="Add description"
                disabled={rcaMode=='read'}
                required
              />
            } 
          </Box>
        </RoundedPanel>
      </div>
      <div>
        <NormText>
          <NoSel><b>Comments</b></NoSel>
        </NormText>
        <TextField
          id='comments'
          fullWidth
          multiline
          rows={4}
          value={comments || ''}
          onChange={e => setComments(e.target.value)}
          placeholder="Add comments"
          disabled={rcaMode=='read'}
        />
      </div>
    </Container>
  </>)
}

const RCAButtonsSection = (props: {
  rcaRecord: RCA | undefined
  setButtonType: any
}) => {
  const { rcaRecord, setButtonType } = props
  return(
    <Container sx={{ paddingRight:'35px' }}>
      <Box padding={0} display='flex' gap='10px' justifyContent='space-between'>
        <Box>
          <Button
            className='outlined-button'
            style={{ backgroundColor: 'white', color: '#3B3B3B', border: '1px solid #CCCCCC' }}
            label={'Save'}
            type="submit"
            onClick={() => setButtonType('save')}
          />

          {rcaRecord?.rcaType == 'INTERNAL_RCA' && <Button
            style={{ ...buttonStyle, backgroundColor: '#A8DDC2', color: '#064A27' }}
            label={'COMPLETE'}
            type="submit"
            onClick={() => setButtonType('complete')}
          />}
        </Box>
        {rcaRecord?.rcaType == 'REQUESTER_RCA' && <Box>
          <Button
            style={{ ...buttonStyle, backgroundColor: '#A8DDC2', color: '#064A27' }}
            label={'APPROVE'}
            type="submit"
            onClick={() => setButtonType('approve')}
          />
          <Button
            style={{ ...buttonStyle, backgroundColor: '#FF9296', color: '#682527' }}
            label={'REJECT'}
            type="submit"
            onClick={() => setButtonType('reject')}
          />
        </Box>}
      </Box>
    </Container>
  )
}

const RCAImplementationDateAndContactPerson = (props: {
  rcaRecord: RCA | undefined
}) => {
  const { rcaRecord } = props
  const { timezone } = UserService.getTokenUserInfo()
  return (<>
    <Divider />
    <Container sx={{ paddingRight:'35px' }}>
      <div>
        <NormText>
          <b>Resolution / implementation date:</b> {parseTimestamp(rcaRecord?.implementationDate, timezone)}
        </NormText>
        <NormText>
          <b>Exfluency contact person:</b> {rcaRecord?.exfluencyContactPersonId ? rcaRecord?.exfluencyContactPerson : ""}
        </NormText>
      </div>
    </Container>
  </>)
}

const RCAShow = ({ job, rcaType, rcaRecord, requester } : {
  job: ExtendedJobRecord
  rcaType: RCAType
  rcaRecord: RCA | undefined
  requester: UserRecord
}) => {
  const [create] = useCreate()
  const [update] = useUpdate()
  const refresh = useRefresh()
  const notify = useNotify()
  const rcaInitialStatus = rcaType=='INTERNAL_RCA' ? 'IN_PROGRESS' : 'REQUESTED'

  useEffect(() => {
    if(rcaRecord){
      setZendeskTicketNumber(rcaRecord.zendeskTicketNumber)
      setRequestDate(rcaRecord.requestDate)
      setRcaResponsibleUsersByStageIds(rcaRecord.rcaResponsibleUsersByStageIds)
      setTrueTranslationsErrors(rcaRecord.trueTranslationErrors)
      setIssue(rcaRecord.issue)
      setJustification(rcaRecord.justification)
      setCorrectiveAndPreventiveActions(rcaRecord.correctiveAndPreventiveActions)
      setCapaOtherContent(rcaRecord.capaOtherContent)
    }
  }, [rcaRecord])

  const [rcaMode, setRcaMode] = useState('read')
  const [buttonType, setButtonType] = useState('save')

  const [zendeskTicketNumber, setZendeskTicketNumber] = useState(rcaRecord?.zendeskTicketNumber)
  const [requestDate, setRequestDate] = useState(rcaRecord?.requestDate)
  const [rcaResponsibleUsersByStageIds, setRcaResponsibleUsersByStageIds] = useState<RCAResponsibleUser[]>([])
  const [trueTranslationsErrors, setTrueTranslationsErrors] = useState<TrueTranslationError[]>([])
  const [issue, setIssue] = useState(rcaRecord?.issue)

  const [justification, setJustification] = useState(rcaRecord?.justification)
  const [correctiveAndPreventiveActions, setCorrectiveAndPreventiveActions] = useState<CorrectiveAndPreventiveAction[]>([])
  const [capaOtherContent, setCapaOtherContent] = useState(rcaRecord?.capaOtherContent)
  const [comments, setComments] = useState(rcaRecord?.comments)

  const handleChangeZendeskTicketNumber = (value: string) => {
    if(value === '') setZendeskTicketNumber(undefined)
    if(typeof parseInt(value) === 'number' && !isNaN(parseInt(value)) && parseInt(value) >= 0) {
      setZendeskTicketNumber(parseInt(value))
    }
  }

  const handleChangeRequestDate = (newDate: string | null | undefined) => {
    if(!newDate) return
    setRequestDate(newDate)
  }

  const handleChangeTTE = (event: React.ChangeEvent<HTMLInputElement>, tte: TrueTranslationError) => {
    event.target.checked ? setTrueTranslationsErrors([...trueTranslationsErrors, tte]) : setTrueTranslationsErrors(trueTranslationsErrors.filter(v => v !== tte))
  }

  const handleChangeCAPA = (event: React.ChangeEvent<HTMLInputElement>, capa: CorrectiveAndPreventiveAction) => {
    event.target.checked ? 
    setCorrectiveAndPreventiveActions([...correctiveAndPreventiveActions, capa]) : 
    setCorrectiveAndPreventiveActions(correctiveAndPreventiveActions.filter(v => v !== capa))
  }

  const handleChangeResposibleUsersByStageIds = (event: React.ChangeEvent<HTMLInputElement>, stageId: string, responsibleUserId: string) => {
    event.target.checked ? 
    setRcaResponsibleUsersByStageIds([...rcaResponsibleUsersByStageIds, { stageId, responsibleUserId }]) : 
    setRcaResponsibleUsersByStageIds(rcaResponsibleUsersByStageIds.filter(it => it.stageId !== stageId))
  }

  const handleCreateRCA = () => {
    create(
      'rca',
      { data: { 
        jobId: job.id, 
        zendeskTicketNumber: zendeskTicketNumber || null,
        requestDate: requestDate || moment().toISOString(),//e.currentTarget.requestDate.value,
        rcaType,
        rcaStatus: rcaInitialStatus,
        rcaResponsibleUsersByStageIds: rcaResponsibleUsersByStageIds,
        trueTranslationErrors: trueTranslationsErrors,
        issue: issue || null
      } }, 
      {
        onSuccess: () => {
          refresh()
          notify('Successfully added RCA', { type: 'success' })
          setRcaMode('read')
        },
        onError: () => {
          notify('Something went wrong while adding RCA', { type: 'error' })
          setRcaMode('read')
        }
      })
  }

  const handleSave = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    if(!rcaRecord){
      handleCreateRCA()
      return
    }

    let status = ''
    switch(buttonType) {
      case "complete":
        status = 'COMPLETED'
        break
      case "approve":
        status = 'APPROVED'
        break
      case "reject":
        status = 'REJECTED'
        break
      default:
        status = ['COMPLETED','APPROVED','REJECTED'].includes(rcaRecord.rcaStatus) ? rcaRecord.rcaStatus : 'IN_PROGRESS'
    }

    const data = {
      ...rcaRecord,
      zendeskTicketNumber: zendeskTicketNumber || null,
      requestDate: requestDate,//e.currentTarget.requestDate.value,
      rcaStatus: status,
      rcaResponsibleUsersByStageIds: rcaResponsibleUsersByStageIds,
      trueTranslationErrors: trueTranslationsErrors, //TRUE_TRANSLATION_ERRORS.filter(name => e.currentTarget[name].checked),
      issue: issue || null,

      justification: justification || null,
      correctiveAndPreventiveActions: correctiveAndPreventiveActions,
      capaOtherContent: correctiveAndPreventiveActions.includes('OTHER') ? e.currentTarget.capaOtherContent.value : null,
      comments: comments
    }

    const updateQuery = {
      id: rcaRecord.id,
      data: data,
      previousData: rcaRecord
    }

    update('rca', updateQuery)
      .then(() => {
        refresh()
        notify('RCA updated', { type: 'success' })
        if(!correctiveAndPreventiveActions.includes('OTHER')){
          setCapaOtherContent('')
        }
        setRcaMode('read')
      })
      .catch(() => {
        notify('Failed to update RCA', { type: 'warning' })
        setRcaMode('read')
      })

  }

  return (
    <SideCard width={CARD_WIDTH} backgroundColor={backgroundColor(rcaMode=='read')}>
      <RCAHead 
        name={rcaType=='INTERNAL_RCA' ? "Internal RCA" : "Requester's RCA"} 
        job={job}
        rcaRecord={rcaRecord} 
        rcaInitialStatus={rcaInitialStatus} 
        rcaMode={rcaMode}
        setRcaMode={setRcaMode}
        requester={requester}
      />
      {(rcaRecord || rcaMode=='add') && <>
        <form
          onSubmit={handleSave}
        >
          <Divider />
          <RCADateAndZendeskTicket 
            requestDate={requestDate} 
            handleChangeRequestDate={handleChangeRequestDate} 
            zendeskTicketNumber={zendeskTicketNumber} 
            handleChangeZendeskTicketNumber={handleChangeZendeskTicketNumber}
            rcaMode={rcaMode}
          />
          <Divider />
          <RCAJobInfo job={job} requester={requester}/>
          <Divider />
          <RCAResponsibleUsers 
            job={job} 
            rcaResponsibleUsersByStageIds={rcaResponsibleUsersByStageIds} 
            handleChangeResposibleUsersByStageIds={handleChangeResposibleUsersByStageIds}
            rcaMode={rcaMode}
          />
          <Divider />
          <RCATrueTranslationsErrorsWithIssue 
            trueTranslationsErrors={trueTranslationsErrors} 
            handleChangeTTE={handleChangeTTE} 
            issue={issue} 
            setIssue={setIssue}
            rcaMode={rcaMode}
          />
          {rcaRecord && (rcaRecord.rcaStatus != 'REQUESTED' || rcaMode=='edit') && 
            <RCAJustificationWithCAPA 
              justification={justification}
              setJustification={setJustification}
              correctiveAndPreventiveActions={correctiveAndPreventiveActions}
              handleChangeCAPA={handleChangeCAPA}
              capaOtherContent={capaOtherContent}
              setCapaOtherContent={setCapaOtherContent}
              comments={comments}
              setComments={setComments}
              rcaMode={rcaMode}
            />
          }
          <Divider />
          {rcaMode!='read' && <RCAButtonsSection rcaRecord={rcaRecord} setButtonType={setButtonType} />}
        </form>

        {rcaMode=='read' && UserService.hasRoles(['ROLE_EXFLUENCY_ADMIN', 'ROLE_PLATFORM_MANAGER']) && <Container sx={{ paddingRight:'35px' }}>
          <Box>
            <Button
              className='outlined-button'
              style={{ backgroundColor: 'white', color: '#3B3B3B', border: '1px solid #CCCCCC' }}
              label={'Edit'}
              type='button'
              onClick={() => setRcaMode('edit')}
            />
          </Box>
        </Container>}

        {rcaRecord && ['COMPLETED', 'APPROVED', 'REJECTED'].includes(rcaRecord.rcaStatus) && 
            <RCAImplementationDateAndContactPerson rcaRecord={rcaRecord}/>}
        
      </>}
    </SideCard>
  )
}

const RCAListBox = ({ job, rcas } : {
  job: ExtendedJobRecord
  rcas: RCA[]
}) => {
  const { data: requester, isLoading: isLoadingRequester, error: error } = useGetOne<UserRecord>('users', { id: job.requesterId || '' })
  if (isLoadingRequester) { return <Loading /> }
  if (error) { return <p>Requester ERROR</p> }
  if (requester === undefined) { return <p>ERROR: requester is undefined</p> }
  return (
    <Box display='flex' gap={GAP} flexWrap='wrap' alignItems='flex-start'>
      {job && <RCAShow job={job} rcaType='INTERNAL_RCA' rcaRecord={rcas.find(record => record.rcaType == 'INTERNAL_RCA')} requester={requester}/>}
      {job && <RCAShow job={job} rcaType='REQUESTER_RCA' rcaRecord={rcas.find(record => record.rcaType == 'REQUESTER_RCA')} requester={requester}/>}
    </Box>
  )
}

const RCAList = () => {
  const { jobId } = useParams()

  const { data: job, isLoading: isLoadingJob, error: error } = useGetOne<ExtendedJobRecord>('jobs', { id: jobId || '' })
  const { data: rcas, isLoading: isLoadingRcas, error: error2 } = useGetList<RCA>('rca', { filter: { jobId } })

  if (!jobId) { return <p>ERROR: jobId is empty</p> }
  if (isLoadingJob || isLoadingRcas) { return <Loading /> }
  if (error) { return <p>JOB ERROR</p> }
  if (job === undefined) { return <p>ERROR: job is undefined</p> }
  if (error2) { return <p>RCA ERROR</p> }
  if (rcas === undefined) { return <p>ERROR: rca is undefined</p> }

  return (
    <Card>
      <Title title={jobId} />
      <CardContent component="div" sx={{ bgcolor: '#FAFAFA', paddingLeft: '4%', paddingRight: '1%' }}>
        <Box className='gray-skin' display={{ md: 'block', lg: 'flex' }} flexDirection='column' gap={GAP} flexWrap='wrap' paddingBottom='30px' paddingTop='50px' alignItems='flex-start'>
          <Typography sx={{ font: 'normal normal bold 26px/31px Inter' }}>
            Root Cause Analysis
          </Typography>
          <RCAListBox job={job} rcas={rcas}/>
        </Box>
      </CardContent>
    </Card>
  )
}

export default RCAList