import React, { useState } from 'react'
import { MaxExecutorAssignmentsPerLanguagePair } from 'api/gatedCommunity'
import UserService from 'UserService'
import ActionButton from 'components/ActionButton'
import { Box } from '@mui/material'
import { LanguagePairException } from './LanguagePairException'
import { EditLanguagePairLimitModal } from './EditLanguagePairLimitModal'
import { StageLimitsParams } from './Limits'

interface PerLanguagePairLimitProps {
  stageLimitParams: StageLimitsParams
  communityId: string
  defaultMaxAmount: number | undefined
  perLanguagePairLimits: MaxExecutorAssignmentsPerLanguagePair[]
  onChange: () => void
}

export const PerLanguagePairLimit = ({ stageLimitParams: { stageType, executorRole }, defaultMaxAmount, communityId, perLanguagePairLimits, onChange }: PerLanguagePairLimitProps) => {
  const [openLanguagePair, setOpenLanguagePair] = useState<boolean>(false)

  const canEdit = UserService.hasRoles(['ROLE_PLATFORM_MANAGER', 'ROLE_GATED_COMMUNITY_MANAGER'])

  return <>
    {<EditLanguagePairLimitModal
      communityId={communityId} stageType={stageType}
      open={openLanguagePair}
      executorRole={executorRole}
      defaultValue={defaultMaxAmount}
      onClose={() => setOpenLanguagePair(false)}
      onSave={() => { setOpenLanguagePair(false); onChange() }} />}
    <Box display='flex' justifyContent='space-between'>

      <Box display='inline' width='70%'>
        {perLanguagePairLimits
          .filter(limit => limit.stageType == stageType)
          .map(limit => <LanguagePairException key={limit.limitId} onChange={onChange} executorRole={executorRole} {...limit} />)}
      </Box>
      {canEdit &&
        <Box display='inline'>
          <ActionButton onClick={() => { setOpenLanguagePair(true) }}>Create Language Pair Exception</ActionButton>
        </Box>}

    </Box>
  </> || <></>
}
