import React from 'react'
import { useRecordContext, useTranslate } from 'react-admin'

import Box from '@mui/material/Box'
import { Divider } from '@mui/material'
import moment from 'moment'

import { parseTimestamp } from 'utils/helpers'
import { ExtendedJobRecord } from 'api/jobs'
import UserService from 'UserService'

import SideCard from 'components/SideCard'
import Status from 'components/Status'

import { CARD_WIDTH, GAP, NormText, STANDARD_PADDING } from './Common'
import { Arrow } from './Arrow'
import { timeDelta } from 'assignment/AssignmentShow'

const JobShowDates = () => {
  const record = useRecordContext<ExtendedJobRecord>()

  const translate = useTranslate()
  const { timezone } = UserService.getTokenUserInfo()

  const dueDate = moment(record?.dueDate)
  const uploadDate = moment(record?.uploadDate)
  const deliveryDate = moment(record?.deliveryDate)

  return <SideCard width={CARD_WIDTH}>
    <Box padding='26px 26px 26px 39px'>
      <NormText><b>Status: </b>
        <Status status={record?.status} text={translate(`job.status.${record?.status}`)} />
      </NormText>
    </Box>

    <Divider />

    <Box display='flex' gap={GAP} padding={STANDARD_PADDING}>
      <Box display='flex' flexDirection='column' gap='31px' >
        <NormText><b>Upload Date:</b></NormText>
        <NormText><b>Due Date:</b></NormText>
      </Box>

      <Box display='flex' flexDirection='column' gap='31px' >
        <NormText>{parseTimestamp(record?.uploadDate, timezone)}</NormText>
        <NormText>{parseTimestamp(record?.dueDate, timezone)}</NormText>
      </Box>

      <Arrow/>

      <Box display='flex' justifyContent='center' alignItems='center'>
        <NormText sx={{ fontWeight: '600' }}>{timeDelta(uploadDate, dueDate)}</NormText>
      </Box>
    </Box>

    <Divider />

    <Box padding={STANDARD_PADDING} display='flex' gap={GAP}>
      <NormText><b>Delivery Date:</b>&nbsp;&nbsp;&nbsp;{parseTimestamp(record?.deliveryDate, timezone)}</NormText>
      <Box display='flex' justifyContent='center' alignItems='center'>
        {timeDelta(dueDate, deliveryDate, 'red-positive')}
      </Box>
    </Box>
  </SideCard>
}

export default JobShowDates