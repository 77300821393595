import { Autocomplete, Box, Modal, TextField } from '@mui/material'
import React, { SyntheticEvent, useState } from 'react'
import { CandidateSearchResult, SimpleGatedCommunityRecord, addUserToGatedCommunity, searchCandidatesToGatedCommunity } from 'api/gatedCommunity'
import { useNotify, useTranslate } from 'ra-core'
import { SumbitButtons } from 'common/SubmitButtons'
import RoundedPanel from 'common/RoundedPanel'
import { ModalProps } from 'gatedCommunity/gcModals/common'

export const AddMember = ({ record, open, onClose }: ModalProps<SimpleGatedCommunityRecord>) => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [users, setUsers] = useState<CandidateSearchResult[]>([])
  const [searchDelay, setSearchDelay] = useState<ReturnType<typeof setTimeout> | undefined>(undefined)
  const [chosenUser, setChosenUser] = useState<CandidateSearchResult | undefined>(undefined)

  const notify = useNotify()
  const translate = useTranslate()

  const fetchUsers = (search: string) => {
    setIsLoading(true)

    searchCandidatesToGatedCommunity(record.id, search)
      .then((response) => {
        setUsers(response)
      })
      .catch(() => {
        notify('Something went wrong during searching for users.', { type: 'error' })
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const handleAddUser = () => {
    if (!chosenUser) return
    setIsLoading(true)

    addUserToGatedCommunity(record.id, chosenUser.id)
      .then(() => {
        notify('New member was successfully added.', { type: 'success' })
      })
      .catch(() => {
        notify('Something went wrong', { type: 'error' })
      })
      .finally(() => {
        handleClose()
        setIsLoading(false)
      }) 
  }

  const handleTextChange = (_: SyntheticEvent | null, value: string | null) => {
    if (searchDelay) {
      clearTimeout(searchDelay)
    }
    setSearchDelay(setTimeout(() => fetchUsers(value || ''), 500))
  }

  const handleOptionChange = (_: SyntheticEvent | null, value: CandidateSearchResult | null) => {
    setChosenUser(value || undefined)
  }

  const handleClose = () => {
    setChosenUser(undefined)
    onClose()
  }
  
  return <Modal
    open={open}
    onClose={onClose}
    disableAutoFocus={true}
  >
    <RoundedPanel modal width="580px">
      <h1>Add Member to {record.name}</h1>
      <Box height={20} />
      <Autocomplete
        options={users}
        getOptionLabel={(option) => option.login}
        value={chosenUser}
        onClickCapture={() => fetchUsers('')}
        onInputChange={handleTextChange}
        onChange={handleOptionChange}
        isOptionEqualToValue={(e1, e2) => e1.id === e2.id}
        renderOption={(props, option) => (
          <li {...props} style={{ display: 'block', padding: '8px 16px' }}>
            <div>{option.login}</div>
            <div style={{ fontSize: '0.8rem' }}>{option.roles.map((role: string) => translate(`dict.role.${role}`)).join(', ')}</div>
            <div style={{ fontSize: '0.8rem' }}>{option.communities.map((gc: string) => gc).join(', ')}</div>
          </li>
        )}
        style={{ width: 500 }}
        renderInput={(params) => <TextField {...params} label="Search" />}
        noOptionsText='No members found'
      />
      <Box height={30} />
      <SumbitButtons
        isLoading={isLoading}
        onClose={handleClose}
        onSave={handleAddUser}
      />
    </RoundedPanel>
  </Modal>
}