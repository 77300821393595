import React, { useState } from 'react'
import { Toolbar, FilterButton, ExportButton, useListContext, useNotify } from "react-admin"
import GetAppIcon from '@mui/icons-material/GetApp'
import Button from '@mui/material/Button'
import { styled } from '@mui/material/styles'

import UserService from 'UserService'
import { exportJobs } from 'api/jobs'
import { remapFilter } from 'providers/xTotalCountFixDataProvider'

const WrapperBtn = styled('div')`
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 4;
`
const WrapperHiddenBtn = styled('div')`
  position: absolute;
  top: 100%;
  right: 0;
  background: #fff;
`

const ListToolbar = () => {
  const [isHovering, setIsHovering] = useState(false)

  const handleMouseOver = () => {
    setIsHovering(true)
  }
  const handleMouseOut = () => {
    setIsHovering(false)
  }

  const isPmOrGcm = () => UserService.hasRoles(['ROLE_PLATFORM_MANAGER', 'ROLE_GATED_COMMUNITY_MANAGER', 'ROLE_COMMUNITY_MANAGER'])
  const isAdminOrSupport = () => UserService.hasRoles(['ROLE_EXFLUENCY_ADMIN', 'ROLE_SUPPORT'])
  
  const { filterValues, sort } = useListContext()
  const notify = useNotify()

  const fetchJobsExport = async (exportType: string) => {
    const filters = remapFilter(filterValues)
    try {
      await exportJobs({ ...filters, _limit: 10000, _sort: sort.field, _order: sort.order, exportType: exportType })
    } catch (error) {
      notify('Something went wrong during jobs export.', { type: 'error' })
    }
  }

  return <Toolbar disableGutters style={{ backgroundColor: 'rgba(0, 0, 0, 0)' }}>
    <FilterButton />
    {(isPmOrGcm() || isAdminOrSupport()) ? 
    <WrapperBtn onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}> 
      <Button variant="text" startIcon={<GetAppIcon />} size="small" onClick={() => !isAdminOrSupport() ? fetchJobsExport("FULL") : fetchJobsExport("DETAILED")} sx={isAdminOrSupport() ? { cursor:'default' } : {}}>{isAdminOrSupport() ? `EXPORT DETAILED` : `EXPORT`}</Button>
      
      {isHovering && isAdminOrSupport() && (
        <WrapperHiddenBtn>
          <Button variant="text" startIcon={<GetAppIcon />} size="small" onClick={() => fetchJobsExport("FULL")}>EXPORT FULL</Button>
          <Button className="hiddenBtn" variant="text" startIcon={<GetAppIcon />} size="small" onClick={() => fetchJobsExport("SIMPLE")}>EXPORT<br/>SIMPLIFIED</Button>
        </WrapperHiddenBtn>
      )}
    </WrapperBtn> : 
    <ExportButton maxResults={10000} />}
  </Toolbar>
}

export default ListToolbar