import React from 'react'
import { Show, useRecordContext } from 'react-admin'
import { InvoicesRecord } from 'api/common'
import { currencyCodeToSymbol, parseTimestamp } from 'utils/helpers'
import UserService from 'UserService'

import 'styles.scss'
import RoundedPanel, { Label } from 'common/RoundedPanel'

const InvoiceShow = () => {
  return <Show title={<VisitorTitle />} component="div" className='user-panel'>
    <VisitorForm />
  </Show>
}

const VisitorTitle = () => {
  const record = useRecordContext<InvoicesRecord>()
  return <span>{record?.id}</span> 
}

const VisitorForm = () => {
  
  const { timezone } = UserService.getTokenUserInfo()
  const record = useRecordContext<InvoicesRecord>()

  return <RoundedPanel width="500px" margin="standard">
    <h1>Invoice Information</h1>
    <hr />
    <Label noSelect>ID: </Label>{record?.id}<br />
    <Label noSelect>Invoice type: </Label>{record?.type}<br />
    <Label noSelect>Accepted: </Label>{record?.accepted ? 'Yes' : 'No'}<br />
    <Label noSelect>Total: </Label>{currencyCodeToSymbol(record?.sum.currency)} {record?.sum.value}<br />
    <hr/>
    <Label noSelect>Creation date: </Label>{parseTimestamp(record?.creationDate, timezone, true, true)}<br />
    <Label noSelect>Start date: </Label>{parseTimestamp(record?.startDate, timezone, true, true)}<br />
    <Label noSelect>End date: </Label>{parseTimestamp(record?.endDate, timezone, true, true)}<br />
  </RoundedPanel>
}
export default InvoiceShow