import React, { SyntheticEvent, useState } from 'react'
import { useListContext, useNotify } from 'react-admin'

import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import FormControl from '@mui/material/FormControl'

import { GatedCommunitySearchRecord, getSearchGatedCommunity } from 'api/gatedCommunity'

import 'styles.scss'

export interface GatedCommunitySearchOptionRecord {
  id: string
  label: string
}

interface GatedCommunitySearchFilterProps {
  source: string
  alwaysOn?: boolean
}

const GatedCommunitySearchFilter = ({ source }: GatedCommunitySearchFilterProps) => {
  const { filterValues, setFilters, displayedFilters } = useListContext()
  if (!source) return null

  return <GatedCommunitySearch defaultValue={filterValues[source]} onChange={value => setFilters({ ...filterValues, [source]: value }, displayedFilters)}/>
}

interface GatedCommunitySearchProps {
  alwaysOn?: boolean,
  onChange?: (value: GatedCommunitySearchOptionRecord[] | undefined) => void,
  defaultValue?: GatedCommunitySearchOptionRecord[],
  fullWidth?: boolean,
}

export const GatedCommunitySearch = ({ onChange, defaultValue }: GatedCommunitySearchProps) => {
  const [searchDelay, setSearchDelay] = useState<ReturnType<typeof setTimeout> | undefined>(undefined)
  const [gatedCommunities, setGatedCommunities] = useState<GatedCommunitySearchOptionRecord[]>(defaultValue || [])
  const [selectedValues, setSelectedValues] = useState<GatedCommunitySearchOptionRecord[] | undefined>(defaultValue || [])
  const [isLoading, setIsLoading] = useState<boolean>(true)

  const notify = useNotify()

  const fetchGatedCommunities = async (search: string) => {
    setIsLoading(true)
    try {
      const response = await getSearchGatedCommunity(search)
      setGatedCommunities(convertRecordsToOptions(response))
    } catch (error) {
      notify('Something went wrong during searching for gated communities.', { type: 'error' })
    }
    setIsLoading(false)
  }

  const convertRecordsToOptions = (records: GatedCommunitySearchRecord[]): GatedCommunitySearchOptionRecord[] =>
    records.map(({ id, name }) => ({ label: name, id }))

  const handleTextChange = (_: SyntheticEvent<Element, Event>|null, value: string | null) => {
    if (searchDelay) {
      clearTimeout(searchDelay)
    }
    setSearchDelay(setTimeout(() => fetchGatedCommunities(value || ''), 500))
  }

  const handleOptionChange = (_: SyntheticEvent<Element, Event>|null, value: GatedCommunitySearchOptionRecord[] | undefined) => {
    setSelectedValues(value)
    onChange && onChange(value)
  }

  const initOnClick = () => {
    handleTextChange(null, '')
  }

  return <FormControl
    className="custom-array-input" 
  >
    <Autocomplete
      size='small'
      style={{ minWidth: '15rem' }}
      multiple
      limitTags={2}
      options={gatedCommunities}
      onInputChange={handleTextChange}
      loading={isLoading}
      onChange={handleOptionChange}
      value={selectedValues}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      disableCloseOnSelect
      renderInput={(params) => <FormControl fullWidth>
        <TextField
          {...params}
          onClick={initOnClick}
          variant='outlined'
          InputLabelProps={{ shrink: true }}
          label="Gated Communities" />
      </FormControl>}
    />
  </FormControl>
}

export default GatedCommunitySearchFilter