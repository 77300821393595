import React, { useEffect, useState } from 'react'
import { useRecordContext, Link, useTranslate, useDataProvider } from 'react-admin'

import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Box from '@mui/material/Box'
import TableContainer from '@mui/material/TableContainer'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableBody from '@mui/material/TableBody'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import { Typography } from '@mui/material'

import { UserDetailsRecord, UserRecord } from 'api/users'
import UserSkills from './userTabs/Skills'

const UserExpand = () => {
  const record = useRecordContext<UserRecord>()
  if (!record) return null

  return <Card sx={{ width: 1200, margin: 'auto' }}>
    <CardContent>
      <Box height={20}>&nbsp;</Box>
      <Box margin="10px 0">
        <UserDetails />
      </Box>
      
      <Box margin="10px 0">
        <Typography variant='h5'>
          Skills
        </Typography>
        <UserSkills disableSyncWithLocation title='/expanded' />
      </Box>
    </CardContent>
  </Card>
}

const UserDetails = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [userDetails, setUserDetails] = useState<UserDetailsRecord | undefined>(undefined)
  const record = useRecordContext<UserRecord>()
  const translate = useTranslate()

  const dataProvider = useDataProvider()
  
  useEffect(() => {
    if(userDetails || isLoading) return
    setIsLoading(() => true)
    dataProvider
      .getOne(`users`, { id: `${record?.id}/details` })
      .then(({ data }) => {
        setUserDetails(data)
      }).catch(() => {
        setIsLoading(false)
      })
  }, [])

  return <>
    <Typography variant='h5'>
      Details
    </Typography>

    <p>Assets Done: {userDetails?.assetsDone}</p>
    <Typography variant='h6'>
      Enhancement
    </Typography>
    <p>Claimed: {userDetails?.enhancementClaimed}</p>
    <p>Rejected: {userDetails?.enhancementRejected}</p>

    <Typography variant='h6'>
      Trust Mining
    </Typography>
    <p>Claimed: {userDetails?.trustMiningClaimed}</p>
    <p>Rejected: {userDetails?.trustMiningRejected}</p>
    
    <Typography variant='h5'>
      Gated Communities
    </Typography>

    <TableContainer>
      <Table sx={{ minWidth: 650 }}>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Profile</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {userDetails?.gatedCommunities.map(({ name, profile, id }) => <TableRow key={id}>
            <TableCell>
              <Link to={`/gatedCommunities/${id}/show`}>{name}</Link>
            </TableCell>
            <TableCell>
              {translate(`gatedCommunity.profile.${profile}`)}
            </TableCell>
          </TableRow>)}
        </TableBody>
      </Table>
    </TableContainer>
  </>
}

export default UserExpand