import React from 'react'
import { Show, TabbedShowLayout, Tab, useRecordContext } from 'react-admin'
import Profile from './showTabs/Profile'
import { SimpleGatedCommunityRecord } from 'api/gatedCommunity'
import Members from './showTabs/members/MembersList'

import 'styles.scss'
import LimitsTab from './showTabs/limits/Limits'

const GatedCommunityShow = () => {
  return <Show title={<GatedCommunityTitle />} component="div" className='user-panel'>
    <GatedCommunityTabbed />
  </Show>
}
const GatedCommunityTabbed = () => {  
  const record = useRecordContext<SimpleGatedCommunityRecord>()
  if(!record || !record.recyclableTranslationLevels) return null
  
  return <TabbedShowLayout
    className='gray-skin'
  >
    <Tab label="Profile">
      <Profile />
    </Tab>
    <Tab label="Members" path="members">
      <Members />
    </Tab>
    <Tab label="Limits" path="limits">
      <LimitsTab communityId={record.id}/>
    </Tab>
  </TabbedShowLayout>
}

const GatedCommunityTitle = () => {
  const record = useRecordContext<SimpleGatedCommunityRecord>()
  return record ? <span>{record.name}</span> : null
}

export default GatedCommunityShow