import React, { useState } from "react"
import { Box, Grid, Modal } from "@mui/material"
import { useNotify } from "react-admin"
import { CompanyPortalProperties, searchTaxonomy, setCompanyPortalLogo, setCompanyPortalProperties } from "api/company"
import { SumbitButtons } from "common/SubmitButtons"
import { CrossCheckbox, Label } from 'common/RoundedPanel'

import 'styles.scss'
import { NormalTextField } from "common/TextField"
import { LanguageAutocomplete, LanguageMultiAutocomplete } from "common/LanguageFilter"
import FileUpload from "common/file/FileUpload"
import { languages } from "common/dictonary"
import { getOptionsSelectFromArray } from "utils/helpers"
import { CustomSelectInput } from "common/OutlinedSelectInput"
import { Genre, SelectOption } from "api/common"
import { stripCategoryPrefix } from "../PortalProperties"
import { CancelTokenSource } from "axios"

const genres: Genre[] = ['it', 'technical', 'sports', 'legal', 'life_sciences', 'marketing', 'other']

interface EditPortalPropertiesProps {
  companyId: string
  portalProperties?: CompanyPortalProperties,
  portalLogoPath: string | null,
  open: boolean,
  onClose: () => void
  onSave: () => void
}

export const validateTaxonomy = async (value: any, sourceRef: { current: CancelTokenSource } | undefined) => {
  const body = await searchTaxonomy(value, sourceRef)
  return body.results[0]?.data
    .flatMap((g: { graph: { nodes: any } }) => g.graph.nodes)
    .map((n: { properties: { title: any } }) => n.properties.title).find((t: string) => t.toLowerCase() == `Category:${value}`.toLowerCase())
}

export const EditPortalProperties = ({ companyId, portalProperties, open, onClose, onSave, portalLogoPath }: EditPortalPropertiesProps) => {
  const allLanguageOptions = getOptionsSelectFromArray(languages, 'dict.lang.')
  const notify = useNotify()
  const [file, setFile] = useState<File | null | undefined>()
  const [defaultLanguageCombinationFileFrom, setDefaultLanguageCombinationFileFrom] = useState<string>(portalProperties?.defaultLanguageCombinationFileFrom || "EN_GB")
  const [defaultLanguageCombinationFileTo, setDefaultLanguageCombinationFileTo] = useState<string>(portalProperties?.defaultLanguageCombinationFileTo || "DA")
  const [defaultLanguageCombinationTranslateAndCreateFrom, setDefaultLanguageCombinationTranslateAndCreateFrom] = useState<string>(portalProperties?.defaultLanguageCombinationTranslateAndCreateFrom || "EN_GB")
  const [defaultLanguageCombinationTranslateAndCreateTo, setDefaultLanguageCombinationTranslateAndCreateTo] = useState<string>(portalProperties?.defaultLanguageCombinationTranslateAndCreateTo || "PL")
  const [allowedLanguages, setAllowedLanguages] = useState<SelectOption[]>(portalProperties?.allowedLanguageList?.map(l => allLanguageOptions.find(o => o.id == l) as SelectOption) || [])
  const [genre, setGenre] = useState<Genre>(portalProperties?.genre || 'it')
  const [taxonomy, setTaxonomy] = useState<string | null>(portalProperties?.taxonomy || 'Category:Law')

  const handleSave = (e: any) => {
    e.preventDefault()
    if (!taxonomy) return

    const data: CompanyPortalProperties = {
      ...portalProperties,
      companyId: companyId,
      genre: genre,
      taxonomy: taxonomy,
      showInstant: e.target.showInstant.checked,
      showEnhance: e.target.showEnhance.checked,
      showTrustmine: e.target.showTrustmine.checked,
      showBi: e.target.showBi.checked,
      showDiscoverMe: e.target.showDiscoverMe.checked,
      allowedLanguageList: allowedLanguages.map((o: SelectOption) => o.id),
      defaultLanguageCombinationFileFrom: defaultLanguageCombinationFileFrom,
      defaultLanguageCombinationFileTo: defaultLanguageCombinationFileTo,
      defaultLanguageCombinationTranslateAndCreateFrom: defaultLanguageCombinationTranslateAndCreateFrom,
      defaultLanguageCombinationTranslateAndCreateTo: defaultLanguageCombinationTranslateAndCreateTo
    }

    setCompanyPortalProperties(data)
      .then(() => {
        const shouldUpdateLogo = file !== undefined || !portalLogoPath
        onClose()
        shouldUpdateLogo && setCompanyPortalLogo(companyId, file).then(() => {
          notify('Company Portal Logo updated', { type: 'success' })
          onSave()
        }) || onSave()
        notify('Company Portal Properties updated', { type: 'success' })
      })
      .catch(() => {
        notify('Failed to update Company Portal Properties', { type: 'warning' })
      })
  }

  return <Modal
    open={open}
    onClose={onClose}
    disableAutoFocus={true}
    sx={{ overflow: 'scroll' }}
  >
    <Box className="rounded-panel edit-modal">
      <h1>Portal</h1>
      <Box height={13} />
      <form
        onSubmit={handleSave}
      >

        <div className="flex">
          <FileUpload
            key={companyId}
            onFileUploaded={(f) => { setFile(f) }}
            onError={(size) => notify("You can't upload file bigger than {{val}}!", { type: 'warning', messageArgs: { val: size } })}
            initFilePath={portalLogoPath || undefined}
          ></FileUpload>
        </div>

        <Grid container spacing={'20px'}>
          <Grid item xs={6}>
            <NormalTextField
              id="taxonomy"
              label="Skillset node"
              defaultValue={portalProperties?.taxonomy && stripCategoryPrefix(portalProperties.taxonomy) || 'Law'}
              fullWidth
              sx={{ marginTop: "32px" }}
              onChange={async e => setTaxonomy(await validateTaxonomy(e.target.value, undefined))}
              error={!taxonomy}
              helperText={!taxonomy && "Provide valid taxonomy"}
            />
          </Grid>
          <Grid item xs={6}>
            <CustomSelectInput
              label="Genre"
              choices={getOptionsSelectFromArray(genres, 'dict.genre.')}
              alwaysOn
              initValue={portalProperties?.genre || 'legal'}
              onChange={(v) => setGenre(v as Genre)}
            />
          </Grid>
        </Grid>

        <Grid container spacing={'20px'}>
          <Grid item xs={12}>
            <LanguageMultiAutocomplete
              label="Allowed Languages"
              onChange={setAllowedLanguages}
              initValue={portalProperties?.allowedLanguageList?.map(l => allLanguageOptions.find(o => o.id == l) as SelectOption) || []}
            />
          </Grid>
        </Grid>

        <Grid container spacing={'20px'}>
          <Grid item xs={6}>
            <LanguageAutocomplete label='Translate&Create Source Lang' onChange={newValue => setDefaultLanguageCombinationTranslateAndCreateFrom(newValue.id)}
              allowedOptions={allowedLanguages}
              initValue={allowedLanguages.find(o => o.id == portalProperties?.defaultLanguageCombinationTranslateAndCreateFrom) || undefined}
            />
          </Grid>
          <Grid item xs={6}>
            <LanguageAutocomplete label='Translate&Create Target Lang' onChange={newValue => setDefaultLanguageCombinationTranslateAndCreateTo(newValue.id)}
              allowedOptions={allowedLanguages}
              initValue={allowedLanguages.find(o => o.id == portalProperties?.defaultLanguageCombinationTranslateAndCreateTo)} />
          </Grid>
        </Grid>

        <Grid container spacing={'20px'}>
          <Grid item xs={6}>
            <LanguageAutocomplete label='Workflow Source Lang'
              onChange={newValue => setDefaultLanguageCombinationFileFrom(newValue.id)}
              allowedOptions={allowedLanguages}
              initValue={allowedLanguages.find(o => o.id == portalProperties?.defaultLanguageCombinationFileFrom)} />
          </Grid>
          <Grid item xs={6}>
            <LanguageAutocomplete label='Workflow Target Lang'
              onChange={newValue => setDefaultLanguageCombinationFileTo(newValue.id)}
              allowedOptions={allowedLanguages}
              initValue={allowedLanguages.find(o => o.id == portalProperties?.defaultLanguageCombinationFileTo)} />
          </Grid>
        </Grid>
        <Box height={13} />

        <Label>Enabled tablets</Label>
        <Box height={13} />
        <Grid container spacing='5px' style={{ whiteSpace: 'nowrap' }} columns={3}>
          <Grid item xs={1}>
            <CrossCheckbox defaultChecked={portalProperties?.showInstant} id="showInstant" />Immediate Translation
          </Grid>
          <Grid item xs={1}>
            <CrossCheckbox defaultChecked={portalProperties?.showEnhance} id="showEnhance" />Enhanced Translation
          </Grid>
          <Grid item xs={1}>
            <CrossCheckbox defaultChecked={portalProperties?.showTrustmine} id="showTrustmine" />Trust Mined Translation
          </Grid>
          <Grid item xs={1}>
            <CrossCheckbox defaultChecked={portalProperties?.showBi} id="showBi" />BI
          </Grid>
          <Grid item xs={1}>
            <CrossCheckbox defaultChecked={portalProperties?.showDiscoverMe} id="showDiscoverMe" />Discover More
          </Grid>
        </Grid>
        <SumbitButtons onClose={onClose} saveDisabled={!taxonomy} />
      </form>
    </Box>
  </Modal>
}
