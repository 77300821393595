import React from "react"
import { RaRecord, useRecordContext } from "ra-core"
import { EditIcon, EditTabletIcon } from "./Icons"

interface EditIconSmallProps {
  label: string
  onClick: (id: RaRecord) => void
}

export const EditIconSmall = ({ onClick }: EditIconSmallProps) => {
  const record = useRecordContext()

  return <EditIcon onClick={() => onClick(record)} />
}

export const EditTablet= ({ onClick }: EditIconSmallProps) => {
  const record = useRecordContext()

  return record.stages.length>0 ? <EditTabletIcon onClick={() => onClick(record)} /> : <></>
}
