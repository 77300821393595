import React from 'react'
import { useRecordContext, Link, useTranslate, FieldProps } from 'react-admin'

import { AdminJobDashboardRecord } from 'api/jobs'
import UserService from 'UserService'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const CurrentStageField = (props: FieldProps) => {
  const record = useRecordContext<AdminJobDashboardRecord>()
  const translate = useTranslate()
  if(!record.currentStage) return null 
  const { id, type } = record.currentStage
  let style = {}
  if((record.pendingAcceptance ) && 
    UserService.hasRoles(['ROLE_GATED_COMMUNITY_MANAGER', 'ROLE_EXFLUENCY_ADMIN', 'ROLE_PLATFORM_MANAGER'])){
    style = { 
      backgroundColor: "orange",
      position: "absolute",
      top: "0",
      left: "0",
      width: "100%",
      height: "100%",
      display: "flex",
      alignItems: "center",
      padding: "6px 16px"
    }
  }

  return <Link to={`/stages/${id}/show`} style={style}> 
    {translate(`stage.type.${type}`)}
  </Link>
}

export default CurrentStageField