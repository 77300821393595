import VisitorIcon from '@mui/icons-material/People'

import UserList from './UserList'
import UserShow from './UserShow'

const resource = {
  list: UserList,
  show: UserShow,
  icon: VisitorIcon
}

export default resource