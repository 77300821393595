import React from 'react'
import { Datagrid, ReferenceManyField, NumberField, RaRecord, useGetList } from 'react-admin'
import Box from '@mui/material/Box'

import UserService from 'UserService'
import { CreatePricingRuleModal } from './companyModals/CreatePricing'
import { EditPricingRuleModal } from './companyModals/EditPricing'
import ActionButton from 'components/ActionButton'

import 'styles.scss'
import { Chipify } from 'common/Chipify'
import { EditIconSmall, EditTablet } from 'common/EditField'
import { handleEsc } from 'common/closeOnEscape'
import { CreateTabletModal, Tablet } from './companyModals/CreateTabletModal'

interface Props {
  type: 'FIXED' | 'DYNAMIC'
  path: string
  record: RaRecord
}

const PricingRules = ({ path, type, record }: Props) => {
  type OpenModal = {
    type: 'EDIT' | 'CREATE' | 'CREATE_TABLET',
    record: RaRecord,
  } | undefined
  const [open, setOpen] = React.useState<OpenModal>(undefined)

  const close = () => { setOpen(undefined) }

  React.useEffect(handleEsc(close), [])

  const { data: tablets, isLoading } = useGetList<Tablet>('tablets', { filter: { companyId: record.id } })
  
  if(isLoading) return <></>

  const createBoundle = open?.type==="CREATE" && 
  <CreatePricingRuleModal 
    record={open.record} 
    open={open.type === 'CREATE'} 
    onClose={close} 
    type={type} 
  />
  const editBoundle = open?.type==="EDIT" && 
  <EditPricingRuleModal 
    record={open.record}
    open={open.type === 'EDIT'} 
    onClose={close} 
    type={type}
    tablet={tablets?.find(t => t.stages.map(s => s.stage).sort().join(',') == [...open.record.stages].sort().join(','))}
  />
  const createTablet = open?.type==="CREATE_TABLET" && 
  <CreateTabletModal 
    record={open.record}
    open={open.type === 'CREATE_TABLET'} 
    onClose={close} 
    type={type} 
    tablet={tablets?.find(t => t.stages.map(s => s.stage).sort().join(',') == [...open.record.stages].sort().join(','))}
  />

  const noPricesMessage = 'No bundle prices set'
  const isPmOrAdmin = UserService.hasRoles(['ROLE_PLATFORM_MANAGER'])

  return <>
    {editBoundle}
    {createBoundle}
    {createTablet}
    
    <Box>
      <Box className='edit-tab'>
        <Box className='on-right'>
          {isPmOrAdmin && <ActionButton onClick={() => { setOpen({ type: "CREATE", record: record })}}>Create</ActionButton>}
        </Box>
      </Box>
      <ReferenceManyField
        reference={path}
        target="companyId"
        sort={{ field: 'id', order: 'DESC' }}
        perPage={50}
        fullWidth
      >
        <Datagrid 
          resource={path} 
          bulkActionButtons={false} 
          className='assigments-datagrid' 
          empty={<>{noPricesMessage}</>}
        >
          <Chipify source='stages' translation="stage.type." label={'Stage'} sortable={false} variant='squere-chip' fallbackChipLabel="DEFAULT RULE"/>
          <NumberField
            source='price'
            label='Price Per Word'
            sortable={false}
            options={{ style: 'currency', currency: 'EUR', minimumFractionDigits: 2, maximumFractionDigits: 15 }} />
          {isPmOrAdmin && <EditIconSmall label="Edit" onClick={(e) => { setOpen({ type: 'EDIT', record: e }) }} />}
          {isPmOrAdmin && <EditTablet label="Tablet" onClick={(e) => { setOpen({ type: "CREATE_TABLET", record: e }) }} />}
        </Datagrid>
      </ReferenceManyField>
    </Box>
  </>
}

export default PricingRules