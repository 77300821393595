import React from "react"
import { Clear } from "@mui/icons-material"
import { IconButton } from "@mui/material"
import { formatFileSize } from "./FileUpload"
import { UncacheableImg } from "common/UncacheableImg"

interface Props {
  file?: File,
  initSvgPath?: string,
  onFileRemoved: () => void
}
const FileUploadItem: React.FC<Props> = ({ file, onFileRemoved, initSvgPath }) => {

  return (<div className="flex file-upload-item">
    {initSvgPath && <UncacheableImg className="logo" src={initSvgPath} alt="Application logo" />}<br />
    {file && <>
      <img src={URL.createObjectURL(file)} alt="logo" style={{ height: "50px" }} />
      <div className="file-upload-item--details">
        <p>{file.name}</p>
        <p className="hint">{formatFileSize(file.size)}</p>
      </div>
    </>}    
    <IconButton onClick={() => onFileRemoved()} className="file-upload-item--actions" size="small">
        <Clear />
      </IconButton>
  </div>)
}

export default FileUploadItem
