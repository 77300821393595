import React, { useState } from 'react'
import { useRecordContext, useNotify, useRefresh, Confirm } from 'react-admin'

import Button from '@mui/material/Button'

import { deleterGatedCommunityMember, GatedCommunityRecord, GatedCommunityMemberSmallRecord } from 'api/gatedCommunity'
import { handleEsc } from 'common/closeOnEscape'

import 'styles.scss'

interface Props {
  member: GatedCommunityMemberSmallRecord
  onClose: () => void
  gcName: string
}

const MemberDelete = ({ member, onClose, gcName }: Props) => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)

  const record = useRecordContext<GatedCommunityRecord>()
  const notify = useNotify()
  const refresh = useRefresh()

  const handleMemberDelete = async () => {
    handleDialogClose()
    setIsLoading(true)
    try {
      await deleterGatedCommunityMember(record.id, member.id as string)
      notify('User was successfully deleted.', { type: 'success' })
    } catch (error) {
      notify('Something went wrong.', { type: 'error' })
    }
    setIsLoading(false)
    onClose()
    setTimeout(() => refresh(), 500)
  }

  const handleDialogOpen = () => setIsModalOpen(true)

  const handleDialogClose = () => setIsModalOpen(false)

  React.useEffect(handleEsc(handleDialogClose), [])

  return <>
    <Button style={{ fontSize: '0.8125rem', marginTop: '3rem', height: "35px" }} color='error' disabled={isLoading} onClick={handleDialogOpen} className="outlined-button">
      DELETE MEMBER
    </Button>
    <Confirm
      isOpen={isModalOpen}
      loading={isLoading}
      title="Delete Memeber"
      content={<span>Are you sure you want to remove <b>{member.userName}</b> from <b>{gcName}</b> Gated Community?</span>}
      onConfirm={handleMemberDelete}
      onClose={handleDialogClose}
    />
  </>
}

export default MemberDelete