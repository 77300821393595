import { Box } from '@mui/material'
import { LanguagePairRecord, TaxonomyKeyRecord, UserTrustChainData, getUserTrustChain } from 'api/users'
import RoundedPanel, { Label } from 'common/RoundedPanel'
import React, { useEffect, useState } from 'react'
import { Datagrid, ListContextProvider, NumberField, TextField, useList } from 'react-admin'
import { ErrorOutline } from '@mui/icons-material'
import 'styles.scss'

const TrustChainBox = ({ exfluenscore }: { exfluenscore?: number }) => {
  const trustChain = exfluenscore !== undefined ? exfluenscore?.toFixed(1) : <ErrorOutline />

  return <RoundedPanel centerText width="300px" height="92px">
    <div className="user-score">{trustChain}</div>
    <Label noSelect>Trust Chain</Label>
  </RoundedPanel>
}

interface UserTrustChainDataProps {
  tustChainData: UserTrustChainData
}

const TrustChainView = ({ tustChainData }: UserTrustChainDataProps) => {
  const Langs = ({ languagePairs }: { languagePairs: LanguagePairRecord[] }) => {
    const langPairs = languagePairs.map((data, index) => ({ id: index, ...data }))
    const listContext = useList({ data: langPairs })

    return <ListContextProvider
      value={listContext}
    >
      <Datagrid
        bulkActionButtons={false}
        className="assigments-datagrid"
      >
        <TextField source="languageFrom" />
        <TextField source="languageTo" />
        <NumberField source="assetCount" />
        <NumberField
          source="score"
          locales={'en-US'}
          options={{
            maximumFractionDigits: 1,
            minimumFractionDigits: 1
          }} />
      </Datagrid>
    </ListContextProvider>
  }

  const TaxonomyKeys = ({ taxonomyKeys }: { taxonomyKeys: TaxonomyKeyRecord[] }) => {
    const taxKeys = taxonomyKeys.map((data, index) => ({ id: index, ...data }))
    const listContext = useList({ data: taxKeys })

    return <ListContextProvider
      value={listContext}
    >
      <Datagrid
        bulkActionButtons={false}
        className="assigments-datagrid"
      >
        <TextField source="taxonomyKey" />
        <NumberField source="assetCount" />
        <NumberField
          source="score"
          locales={'en-US'}
          options={{
            maximumFractionDigits: 1,
            minimumFractionDigits: 1
          }} />
      </Datagrid>
    </ListContextProvider>
  }

  return <Box>
    <Box height={32} />
    <TrustChainBox exfluenscore={tustChainData.exfluenscore} />
    <Box height={32} />
    <Box className="panel">
      <Box>
        <RoundedPanel centerText height="51px">
          <Label>Languages</Label>
        </RoundedPanel>
        <Box height={13} />
        <Langs languagePairs={tustChainData.languagePairs} />
      </Box>
      <Box>
        <RoundedPanel centerText height="51px">
          <Label>Skillset Nodes</Label>
        </RoundedPanel>
        <Box height={13} />
        <TaxonomyKeys taxonomyKeys={tustChainData.taxonomyKeys} />
      </Box>
    </Box>
  </Box>
}

const NoTrustChainView = () => {
  return <Box>
    <Box height={32} />
    <TrustChainBox exfluenscore={undefined} />
  </Box>
}

interface Props {
  userName: string
}

const UserTrustChain = ({ userName }: Props) => {
  const [tustChainData, setTrustChainData] = useState<UserTrustChainData | undefined>(undefined)

  useEffect(() => { fetchUserTrustChain() }, [])

  const fetchUserTrustChain = () => {
    getUserTrustChain(userName)
      .then((response) => {
        setTrustChainData(response)
      })
      .catch((error) => {
        console.warn(error)
      })
  }

  return tustChainData?.exfluenscore ? <TrustChainView tustChainData={tustChainData} /> : <NoTrustChainView />
}

export default UserTrustChain

