import React from 'react'
import { FieldProps, useRecordContext } from 'react-admin'

import { showDateCountdown } from 'utils/helpers'

interface Props extends FieldProps {
  deliveryDate?: boolean
}

const CustomDeadlineField = ({ deliveryDate, source }: Props) => {
  const record = useRecordContext()
  return (record && source) ? <div>
    <span>{showDateCountdown(record[source], deliveryDate ? record.deliveryDate : undefined)}</span>
  </div> : null
}
export default CustomDeadlineField