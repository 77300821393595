import React, { useState } from 'react'
import { useNotify } from 'ra-core'
import moment from 'moment'

import DateTimePicker from '@mui/lab/DateTimePicker'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'

import { changeDeadline } from 'api/common'

interface Props {
  dueDate: string
  id: string
  type: 'jobs' | 'stages' | 'assignments'
}

const ChangeDeadline = ({ dueDate, id, type }: Props) => {
  const [date, setDate] = useState<string>(dueDate)

  const notify = useNotify()

  const handleDateChange = (newDate: string | null) => {
    if(!newDate) return
    setDate(newDate)
  }

  const handelDeadlineChange = async () => {
    try {
      await changeDeadline(id, moment(date).format('YYYY-MM-DD HH:mm:ss'), type)
      notify('Deadline was successfully changed', { type: 'success' })
    } catch (error) {
      notify('Something went wrong', { type: 'error' })
    }
  }

  return <div>
    <DateTimePicker
      renderInput={(props) => <TextField {...props} />}
      label="Due Date"
      inputFormat='YYYY-MM-DD HH:mm:ss'
      value={date}
      onChange={(newValue) => handleDateChange(newValue)}
    />
    <Button variant='contained' disabled={dueDate === date} onClick={handelDeadlineChange}>
        Change
    </Button>
  </div>
}

export default ChangeDeadline