import React, { useEffect, useState } from 'react'
import { useNotify, useRefresh } from 'react-admin'

import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogActions from '@mui/material/DialogActions'
import Input from '@mui/material/Input'
import InputAdornment from '@mui/material/InputAdornment'

import { getAssignmentInterruptionPriceOverrideSuggestion, interruptAssignment } from 'api/assignments'
import CustomButton from 'components/CustomButton'

interface Props {
  assignmentId: string
}

const InterruptAssignment = ({ assignmentId }: Props) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [newPrice, setNewPrice] = useState<number | undefined>(undefined)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const notify = useNotify()
  const refresh = useRefresh()

  useEffect(() => {
    if(isOpen) {
      fetchSuggestedPrice()
    }
  }, [isOpen])

  const fetchSuggestedPrice = async () => {
    setIsLoading(true)
    try {
      const response = await getAssignmentInterruptionPriceOverrideSuggestion(assignmentId)
      setNewPrice(response)
    } catch (error) {
      notify('Something went wrong', { type: 'error' })
    }
    setIsLoading(false)
  }

  const handleInterruptAssignment = async () => {
    if(newPrice === undefined || newPrice < 0) return

    try {
      await interruptAssignment(assignmentId, newPrice)
      notify('Successfully interrupted assignment', { type: 'success' })
    } catch (error: any) {
      notify(error.response.data.message || 'Something went wrong', { type: 'error' })
    }
    handleClose()
  }

  const handleClose = () => {
    setIsOpen(false)
    refresh()
  }

  const handleOpen = () => {
    setIsOpen(true)
  }

  const onPriceInputChange = (value: string) => {
    if(typeof parseFloat(value) === 'number' && !isNaN(parseFloat(value))) {
      setNewPrice(parseFloat(value))
    }
  }

  return <div>
    <CustomButton onClick={handleOpen}>Interrupt</CustomButton>
    <Dialog open={isOpen}>
      <DialogTitle>
        Interrupt assignment
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <div>Suggested price based on the time spent by the Contributor. You may override it if you want</div>
          <Input type='number' value={newPrice} onChange={(event) => onPriceInputChange(event.target.value)} startAdornment={<InputAdornment position="start">€</InputAdornment>} />
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
        <Button variant='contained' disabled={newPrice === undefined || newPrice < 0 || isLoading} onClick={handleInterruptAssignment}>
            Submit
        </Button>
      </DialogActions>
    </Dialog>
  </div>
}

export default InterruptAssignment