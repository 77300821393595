import { Box, Modal } from "@mui/material"
import RoundedPanel from "common/RoundedPanel"
import React from "react"
import { ModalProps } from "./common"
import { NormalTextField } from "common/TextField"
import { SumbitButtons } from "common/SubmitButtons"
import { useCreate, useNotify, useRefresh } from "ra-core"

export const CreateGatedCommunityModal = ({ open, onClose }: ModalProps<undefined>) => {
  const notify = useNotify()
  const [create] = useCreate()
  const refresh = useRefresh()

  const handleCreateGatedCommunity = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const formData = new FormData(e.currentTarget)
    const name = formData.get('name') as string
    const data = { name }

    create(
      'gatedCommunities',
      { data },
      {
        onSuccess: () => {
          notify('Gated community was successfully created.', { type: 'success' })
          refresh()
          onClose()
        },
        onError: (error: any) => {
          refresh()
          notify(error?.message ?? 'Something went wrong.', { type: 'error' })
          console.log(JSON.stringify(error))
        }
      }
    )
  }

  return <Modal
    open={open}
    onClose={onClose}
    disableAutoFocus={true}
  >
    <RoundedPanel modal width="500px">
      <h1>Add Gated Community</h1>
      <Box height="20px" />
      <form onSubmit={handleCreateGatedCommunity}>
        <NormalTextField
          label="Name"
          name="name"
          fullWidth
          required
        />
        <Box height="20px" />
        <SumbitButtons onClose={onClose} />
      </form>
    </RoundedPanel>
  </Modal>
}