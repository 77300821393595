import React, { ReactNode } from 'react'

import { Box } from '@mui/material'

import 'styles.scss'
import { Property } from 'csstype'

interface RoundedPanelProps {
  children: ReactNode;
  /**
   * @default undefined 
   * @description "standard" is 64px 56px
   */
  margin?: string|"standard";
  padding?: string;
  width?: string | number;
  height?: string | number;
  backgroundColor?: Property.BackgroundColor;

  /**
   * @default false
   * @description If true, the panel will have absolute position and will be centered on the screen
   */
  modal?: boolean;
  /**
   * @default false
   * @description If true, the content will be centered
   */
  centerText?: boolean;
  /**
   * @default false
   * @description If true, two children divs will be treated as columns. (right gray, based on design)
   */
  columns?: boolean;
  /**
   * @default false
   * @description If true, the panel will have no rounded corners nor border
   */
  noBorder?: boolean;
}

/**
 * White panel with rouded corners
 * Inside you can use following componets (that will be styled):
 * - Label
 * - Alert
 * - CrossCheckbox
 * - h1, h2
 * - hr 
 * - Buttons
 * - Chips
 */
export const RoundedPanel = ({ children, margin, width, height, backgroundColor, modal, centerText, columns, noBorder, padding }: RoundedPanelProps) => {
  const modalClass = modal ? "edit-modal" : ""
  const centerTextClass = centerText ? "center-text" : ""
  const columnsClass = columns ? "columns" : ""
  const noBorderClass = noBorder ? "no-border" : ""

  return <Box
    className={`rounded-panel ${modalClass} ${centerTextClass} ${columnsClass} ${noBorderClass}`}
    width={width}
    height={height}
    margin={margin === "standard" ? "64px 56px" : margin}
    padding={padding}
    style={{ backgroundColor: backgroundColor || 'white' }}
  >
    {children}
  </Box>
}