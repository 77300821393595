import { stringify } from 'query-string'
import { fetchUtils, DataProvider } from 'ra-core'

const X_TOTAL_COUNT = 'x-total-count'
/**
 * Maps react-admin queries to a json-server powered REST API
 *
 * @see https://github.com/typicode/json-server
 *
 * @example
 *
 * getList          => GET http://my.api.url/posts?_sort=title&_order=ASC&_start=0&_end=24
 * getOne           => GET http://my.api.url/posts/123
 * getManyReference => GET http://my.api.url/posts?author_id=345
 * getMany          => GET http://my.api.url/posts?id=123&id=456&id=789
 * create           => POST http://my.api.url/posts/123
 * update           => PUT http://my.api.url/posts/123
 * updateMany       => PUT http://my.api.url/posts/123, PUT http://my.api.url/posts/456, PUT http://my.api.url/posts/789
 * delete           => DELETE http://my.api.url/posts/123
 *
 * @example
 *
 * import * as React from "react"
 * import { Admin, Resource } from 'react-admin'
 * import jsonServerProvider from 'ra-data-json-server'
 *
 * import { PostList } from './posts'
 *
 * const App = () => (
 *     <Admin dataProvider={jsonServerProvider('http://jsonplaceholder.typicode.com')}>
 *         <Resource name="posts" list={PostList} />
 *     </Admin>
 * )
 *
 * export default App
 */

export const remapFilter = (filter: any) => {
  const arrayOfkeys = Object.keys(filter).map(key => {
    if(Array.isArray(filter[key])) {
      if(filter[key][0] instanceof Object) {
        return { [key]: filter[key].map((e: any) => e.id ).join(',') }
      }
      return { [key]: filter[key].join(',') }
    }
    if(filter[key] instanceof Object) {
      return { [key]: filter[key].id }
    }
    return { [key]: filter[key] }
  })
  return arrayOfkeys.reduce((obj, item) => ({ ...obj, ...item }), {})
}

export default (apiUrl: string, httpClient = fetchUtils.fetchJson): DataProvider => ({
  getList: (resource, params) => {
    const { page, perPage } = params.pagination
    const { field, order } = params.sort
    const filter = remapFilter(params.filter) // Sometimes object will be saved as filter instead of single value so this funtion read id from object and convert to single value
    const query = {
      ...fetchUtils.flattenObject(filter),
      _sort: field,
      _order: order,
      _offset: (page - 1) * perPage,
      _limit: perPage
    }
    const url = `${apiUrl}/${resource}?${stringify(query)}`

    return httpClient(url).then(({ headers, json }) => {
      // if (!headers.has(X_TOTAL_COUNT)) {
      //   throw new Error(
      //     'The X-Total-Count header is missing in the HTTP Response. The jsonServer Data Provider expects responses for lists of resources to contain this header with the total number of results to build the pagination. If you are using CORS, did you declare X-Total-Count in the Access-Control-Expose-Headers header?'
      //   )
      // }
      
      const data = shouldAddDummyIds(resource) ? withDummyIds(json) : json
      return {
        data: data,
        total: parseInt(
          headers?.get(X_TOTAL_COUNT)?.split('/').pop() || '20',
          10
        )
      }
    })
  },

  getOne: (resource, params) =>
    httpClient(`${apiUrl}/${resource}/${params.id}`).then(({ json }) => ({
      data: json
    })),

  getMany: (resource, params) => {
    const query = {
      id: params.ids
    }
    const url = `${apiUrl}/${resource}?${stringify(query)}`
    return httpClient(url).then(({ json }) => ({ data: json }))
  },

  getManyReference: (resource, params) => {
    const { page, perPage } = params.pagination
    const { field, order } = params.sort
    const query = {
      ...fetchUtils.flattenObject(params.filter),
      [params.target]: params.id,
      _sort: field,
      _order: order,
      _offset: (page - 1) * perPage,
      _limit: perPage
    }
    const url = `${apiUrl}/${resource}?${stringify(query)}`

    return httpClient(url).then(({ headers, json }) => {
      let a = 1
      if (a) a = 2
      // if (!headers.has(X_TOTAL_COUNT)) {
      //   throw new Error(
      //     'The X-Total-Count header is missing in the HTTP Response. The jsonServer Data Provider expects responses for lists of resources to contain this header with the total number of results to build the pagination. If you are using CORS, did you declare X-Total-Count in the Access-Control-Expose-Headers header?'
      //   )
      // }
      return {
        data: json,
        total: parseInt(
          headers?.get(X_TOTAL_COUNT)?.split('/').pop() || '20',
          10
        )
      }
    })
  },

  update: (resource, params) =>
    httpClient(`${apiUrl}/${resource}/${params.id}`, {
      method: 'PUT',
      body: JSON.stringify(params.data)
    }).then((data) => {
      if (data.json !== undefined) {
        return { data: data.json }
      }
      return { data: { id: params.id, ...params.data } }
    }),

  // json-server doesn't handle filters on UPDATE route, so we fallback to calling UPDATE n times instead
  updateMany: (resource, params) =>
    Promise.all(
      params.ids.map(id =>
        httpClient(`${apiUrl}/${resource}/${id}`, {
          method: 'PUT',
          body: JSON.stringify(params.data)
        })
      )
    ).then(responses => ({ data: responses.map(({ json }) => json.id) })),

  create: (resource, params) =>
    httpClient(`${apiUrl}/${resource}`, {
      method: 'POST',
      body: JSON.stringify(params.data)
    }).then(({ json }) => ({
      data: { ...params.data, id: json.id }
    })),

  delete: (resource, params) =>
    httpClient(`${apiUrl}/${resource}/${params.id}`, {
      method: 'DELETE'
    }).then(({ json }) => ({ data: json })),

  // json-server doesn't handle filters on DELETE route, so we fallback to calling DELETE n times instead
  deleteMany: (resource, params) =>
    Promise.all(
      params.ids.map(id =>
        httpClient(`${apiUrl}/${resource}/${id}`, {
          method: 'DELETE'
        })
      )
    ).then(responses => ({ data: responses.map(({ json }) => json.id) })),

  getAll: (resource: any) =>
    httpClient(`${apiUrl}/${resource}`).then(({ json }) => ({
      data: json
    }))
})

/**
 * react admin expects response data to have "id" property
 * in some cases it is unnecessary
 * instead of adding "id" property to data returned from endpoin, we can populate it with dummy ids on frontend side
 */
const shouldAddDummyIds = (resouce: string) => resouce.endsWith("tempo") || resouce.endsWith("genres")

const withDummyIds = (json: any) => {
  let i = 1
  return json.map((item: any) => {
    item.id = i++
    return item
  })
}