import { red, orange, green } from '@mui/material/colors'

import { UserRecord } from 'api/users'

const rowStyle = (record: UserRecord) => {
  const style = {}
  if (!record) {
    return style
  }
  if (!(record.isActive && record.isAccountConfirmed && record.isNdaSigned))
  {return {
    ...style,
    borderLeftColor: red[500],
    borderLeftWidth: 5,
    borderLeftStyle: 'solid'
  }}
  if (record.outOfOffice)
  {return {
    ...style,
    borderLeftColor: orange[500],
    borderLeftWidth: 5,
    borderLeftStyle: 'solid'
  }}
  return {
    ...style,
    borderLeftColor: green[500],
    borderLeftWidth: 5,
    borderLeftStyle: 'solid'
  }
}

export default rowStyle