import React, { useState } from 'react'
import { useNotify } from 'ra-core'

import ActionButton from 'components/ActionButton'

interface Props {
  getLogsPromise: () => Promise<string>
}

const Logs = ({ getLogsPromise }: Props) => {
  const [link, seLink] = useState<string | undefined>(undefined)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const notify = useNotify()

  const handleGenerateLinkClick = async () => {
    setIsLoading(true)
    try {
      const response = await getLogsPromise()
      seLink(response)
    } catch (error) {
      notify('Something went wrong', { type: 'error' })
    }
    setIsLoading(false)
  }

  return <a href={link ? link : undefined} rel="noreferrer" target="_blank" onClick={handleGenerateLinkClick}>
    <ActionButton disabled={isLoading}>{link ? 'Show logs' : 'Search for Logs'}</ActionButton>
  </a>
}

export default Logs