/* eslint-disable @typescript-eslint/no-namespace */
import { Box, Tab, Tabs } from '@mui/material'
import React, { DOMAttributes, useState } from 'react'
import TableauEmbeddingWithToken from './TableauEmbeddingWithToken'

//tableau-viz needs to be wrapper to use it in React
//using Tableau API downloaded from our server was needed to avoid CORS
class TableauWebComponentWrapper extends HTMLElement {

  static get observedAttributes () {
    return ['token', 'url']
  }
  
  _token = ''
  _url = ''
  
  constructor () {
    super()
  }
  
  connectedCallback () {
    const x = this.attributes as any
    const t = x.token.value
    const url = x.url.value
    console.log("Token:", t, "URL:", url)
    this.innerHTML = `<tableau-viz id="tableauViz"
      src=${url}
      toolbar="hidden"
      hide-tabs
      tooltip="no"
      token="${t}">
  </tableau-viz>`
  }
  
  set token (value: string) {
    this._token = value
  }
  
  get token () {
    return this._token
  }
  
  set url (value: string) {
    this._url = value
  }
  
  get url () {
    return this._url
  }
  
  static get tag () {
    return 'tableau-embedding'
  }
}
  
  type CustomElement<T> = Partial<T & DOMAttributes<T> & { children: any }>
  
  declare global {
    namespace JSX {
      interface IntrinsicElements {
              ['tableau-embedding']: CustomElement<TableauWebComponentWrapper>
          }
      }
  }
  
customElements.define(TableauWebComponentWrapper.tag, TableauWebComponentWrapper)

const tableauViews = new Map<string, string>([
  ['Overview', 'https://tableau-server.prod.app.exfluency-cloud.com/views/BI3_17314952946240/Overview?:origin=card_share_link&:embed=yes&:toolbar=no&:tooltip=no'],
  ['Languages', 'https://tableau-server.prod.app.exfluency-cloud.com/views/BI3_17314952946240/LanguageOverview?:origin=card_share_link&:embed=yes&:toolbar=no'],
  ['Editing Distances', 'https://tableau-server.prod.app.exfluency-cloud.com/views/BI3_17314952946240/EditingDistances?:origin=card_share_link&:embed=yes&:toolbar=no'],
  ['Time Usage','https://tableau-server.prod.app.exfluency-cloud.com/views/BI3_17314952946240/AssignmentDelivery?:origin=card_share_link&:embed=yes&:toolbar=no'],
  ['Zendesk','https://tableau-server.prod.app.exfluency-cloud.com/views/Exfluencyclonedfromapp/WordCostPerLC-Dashboard?:origin=card_share_link&:embed=yes&:toolbar=no'],
  ['Operations','https://tableau-server.prod.app.exfluency-cloud.com/views/Exfluencyclonedfromapp/OperationsSalesDashboard?:origin=card_share_link&:embed=n'],
  ['Community','https://tableau-server.prod.app.exfluency-cloud.com/views/Exfluencyclonedfromapp/CommunityDashboard?:origin=card_share_link&:embed=yes&:toolbar=no'],
  ['Quality','https://tableau-server.prod.app.exfluency-cloud.com/views/Exfluencyclonedfromapp/QualityDashboard?:origin=card_share_link&:embed=yes&:toolbar=no']
])
  
const BiPage: React.FC = () => {
  const [selectedTab, setSelectedTab] = useState(0)

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue)
  }
  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ display: 'flex', justifyContent: 'center', marginBottom: 2 }}>
        <Tabs
          value={selectedTab}
          onChange={handleTabChange}
          variant="scrollable"
          scrollButtons="auto"
          aria-label="Tableau Views Tabs"
          sx={{ display: 'inline-flex' }}
        >
          {[...tableauViews.keys()].map((label, index) => (
            <Tab key={index} label={label} value={index} />
          ))}
        </Tabs>
      </Box>

      <Box sx={{ padding: 2 }}>
        {[...tableauViews.entries()].map(([, url], index) => (
          selectedTab === index && (
            <TableauEmbeddingWithToken key={index} url={url} />
          )
        ))}
      </Box>
    </Box>
  )
}
  
export default BiPage