import { Box } from '@mui/material'
import { Chipify } from 'common/Chipify'
import { handleEsc } from 'common/closeOnEscape'
import CustomPagination from 'common/CustomPagination'
import { EditIconSmall } from 'common/EditField'
import { FilterExportToolbar } from 'common/FilertExportToolbar'
import { CreatePricingRuleModal } from 'company/showTabs/companyModals/CreatePricing'
import { EditPricingRuleModal } from 'company/showTabs/companyModals/EditPricing'
import ActionButton from 'components/ActionButton'
import React from 'react'
import { Datagrid, List, NumberField, RaRecord } from 'react-admin'
import UserService from 'UserService'

const PricingRuleList = () => {
  const [open, setOpen] = React.useState<{ type: 'EDIT', record: RaRecord } | {type: 'CREATE', record?: RaRecord } | undefined>(undefined)
  
  const close = () => { setOpen(undefined) }
  const type = !/fixed/.test(window.location.href ?? '') ? 'DYNAMIC' : 'FIXED'

  React.useEffect(handleEsc(close), [])

  const createBoundle = open?.type==="CREATE" && 
  <CreatePricingRuleModal 
    record={open.record} 
    open={open.type === 'CREATE'} 
    onClose={close} 
    type={type} 
  />
  const editBoundle = open?.type==="EDIT" && 
  <EditPricingRuleModal 
    record={open.record}
    open={open.type === 'EDIT'} 
    onClose={close} 
    type={type} 
  />

  const noPricesMessage = 'No bundle prices set'

  return <Box margin="0px 60px">
    {open && (editBoundle)}
    {open && (createBoundle)}
    
    <List
      perPage={50}
      sort={{ field: 'name', order: 'DESC' }}
      exporter={false}
      pagination={<CustomPagination />}
      actions={<FilterExportToolbar
        right={<>{UserService.hasRoles(['ROLE_PLATFORM_MANAGER']) && <ActionButton onClick={() => { setOpen({ type: "CREATE", record: undefined })}}>Create</ActionButton>}</>}
      />}
      className='assigments-datagrid'
    >
      <Datagrid 
        bulkActionButtons={false}
        empty={<>{noPricesMessage}</>}
      >
        <Chipify source='stages' translation='stage.type.' variant='squere-chip' sortable={false} fallbackChipLabel="DEFAULT RULE"/>
        <NumberField
          source='price'
          label='Price Per Word'
          textAlign='right'
          sortable={false}
          options={{ style: 'currency', currency: 'EUR', minimumFractionDigits: 2, maximumFractionDigits: 15 }} />
        {UserService.hasRoles(['ROLE_PLATFORM_MANAGER']) && <EditIconSmall label="edit" onClick={(e) => {
          setOpen({
            type: 'EDIT',
            record: e
          })
        }} />}
      </Datagrid>
    </List>
  </Box>
}

export default React.memo(PricingRuleList)