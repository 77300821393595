
import React, { useEffect, useState } from 'react'
import { useRecordContext } from 'react-admin'

import MapsUgcOutlinedIcon from '@mui/icons-material/MapsUgcOutlined'

import Box from '@mui/material/Box'
import { Divider, IconButton, Tooltip } from '@mui/material'
import moment from 'moment'

import { ExtendedJobRecord, getComments } from 'api/jobs'
import { Comment } from 'api/common'

import SideCard from 'components/SideCard'
import { COMMENTS_PADDING, CommentHeader, CommentReplyHeader, CommentText } from './Common'

import { AddCommentModal } from './AddCommentModal'
import styled from '@emotion/styled'

export const JobShowIconButton = styled(IconButton)({
  borderRadius: "10%",
  backgroundColor: "#f8f8f8",
  border: "1px solid #DADADA",
  color: "#898989",
  margin: "0px 8px",
  padding: "5px",

  ':hover': {
    backgroundColor: "#f4f4f4",
    borderColor: "#b8b8b8",
    color: "#606060"
  }
})

const JobShowComments = () => {
  const record = useRecordContext<ExtendedJobRecord>()

  const [comments, setComments] = useState<Comment[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [open, setOpen] = useState<boolean>(false)  

  const fetchComments = () => {
    record && getComments(record.id).then((comments) => {
      setComments(comments.sort((a, b) => (a.creationTime > b.creationTime) ? -1 : 1))
    })
      .catch()
      .finally(() => {
        setIsLoading(false)
      })
  }

  useEffect(fetchComments, [record])

  return <SideCard width="1225px">
    {record && <AddCommentModal
      jobId={record.id}
      open={open}
      onClose={() => setOpen(false)}
      onSave={fetchComments}
    />}
    <Box padding={COMMENTS_PADDING} display='flex'>
      <CommentHeader>
        <b>Job comments</b>
        <Tooltip title="Add a comment" placement="top" arrow>
          <JobShowIconButton onClick={() => setOpen(true)}>
            <MapsUgcOutlinedIcon sx={{ fontSize: "20px" }} />
          </JobShowIconButton>
        </Tooltip>
      </CommentHeader>
    </Box>
    <Divider />
    {comments.length === 0 && !isLoading ?
      <Box padding={COMMENTS_PADDING}>
        <CommentText>No Comments</CommentText>
      </Box> :
      comments.map(({ author, creationTime, id, text }) =>
        <>
          <Box padding={COMMENTS_PADDING} gap='16px' display='flex' flexDirection='column' key={id}>
            <CommentReplyHeader><b>{author}</b> {moment(creationTime).fromNow()}</CommentReplyHeader>
            <CommentText>{text}</CommentText>
          </Box>
          <Divider />
        </>
      )
    }
  </SideCard>
}

export default JobShowComments