import React from "react"
import { UserFile, downloadJobAttachment } from "api/jobs"
import { downloadStageAttachment } from "api/stages"
import { ButtonGroup, TableCell, TableRow } from "@mui/material"
import { LoadingButton } from "@mui/lab"
import { useNotify, useTranslate } from "ra-core"

interface Props {
  attachment: UserFile,
  stageId?: string
}

export const SectionAttachmentDownload = ({ attachment, stageId }: Props) => {
  const translate = useTranslate()
  const notify = useNotify()
  const anchorRef = React.useRef<HTMLDivElement>(null)

  const notifyError = () => {
    notify('notifications.fileError', {
      type: 'error'
    })
  }

  function handleAttachmentDownload () {
    notify(`Reference file ${attachment.name} is being downloaded`)
    if (stageId) {
      downloadStageAttachment(stageId, attachment.fileId, attachment.name)
        .catch(notifyError)
    }
    else {
      downloadJobAttachment(attachment.jobId, attachment.fileId, attachment.name)
        .catch(notifyError)
    }
  }

  return <TableRow key={attachment.fileId}>
    <TableCell>{attachment.name}</TableCell>
    <ButtonGroup ref={anchorRef}>
      <LoadingButton sx={{ width: "160px" }} variant="contained" onClick={
        () => handleAttachmentDownload()
      }>
        {translate(`dict.file.ATTACHMENT`)}
      </LoadingButton>
    </ButtonGroup>
  </TableRow>
} 