import React from 'react'
import RoundedPanel from 'common/RoundedPanel'
import { Box, Grid, Modal } from '@mui/material'
import { SumbitButtons } from 'common/SubmitButtons'
import { NormalTextField } from 'common/TextField'

export interface EditStageLimitModalProps {
  executorRole: string
  defaultValue: number
  open: boolean
  onClose: () => void
  onSave: (value: number) => void
  onDelete?: () => void
}

export const EditStageLimitModal = ({ executorRole, defaultValue, open, onClose, onSave, onDelete }: EditStageLimitModalProps) => {

  return <Modal
    open={open}
    onClose={onClose}
    disableAutoFocus={true}
  >
    <RoundedPanel modal width="500px">
      <h1>Edit max amount of projects for {executorRole}</h1>
      <Box height={13} />
      <form onSubmit={(e: any) => {
        e.preventDefault()
        onSave(e.target.maxAmount.value)
      }}>
        <Grid container spacing={'20px'}>
          <Grid item xs={12}>
            <NormalTextField
              id="maxAmount"
              label="Max amount of projects"
              defaultValue={defaultValue}
              fullWidth
              type='number' 
              InputProps={{ inputProps: { min: 1 } }}/>
          </Grid>
        </Grid>
        <Box height={13} />
        <SumbitButtons
          onClose={onClose}
          onSaveLabel="Submit"
          onDelete={onDelete}
          onDeleteLabel='Reset' />
      </form>
    </RoundedPanel>
  </Modal>
}
