import React, { useState } from 'react'
import UserService from 'UserService'
import { Label } from 'common/RoundedPanel'
import ActionButton from 'components/ActionButton'
import { Box } from '@mui/material'
import { EditStageLimitModal } from './EditStageLimitModal'
import { setLimitOfMaxExecutorAssignments } from 'api/gatedCommunity'
import { useNotify } from 'ra-core'
import { StageLimitsParams } from './Limits'

interface PerStageTypeLimitProps {
  stageLimitParams: StageLimitsParams
  maxAmountPerStage: number | undefined
  defaultMaxAmount: number
  communityId: string;
  onChange: () => void;
}
export const PerStageTypeLimit = ({ stageLimitParams: { stageType, executorRole }, communityId, maxAmountPerStage, defaultMaxAmount, onChange }: PerStageTypeLimitProps) => {
  const [open, setOpen] = useState<boolean>(false)

  const notify = useNotify()

  const handleSuccess = () => {
    setOpen(false)
    onChange()
    notify('Limit updated', { type: 'success' })
  }
  const handleFailure = () => { notify('Failed to update limit', { type: 'warning' }) }

  const handleSave = (value: number) => {
    setLimitOfMaxExecutorAssignments(communityId, { stageType: stageType, maxAmount: value })
      .then(handleSuccess)
      .catch(handleFailure)
  }

  const resetToGlobalDefault = () => {
    setLimitOfMaxExecutorAssignments(communityId, { stageType: stageType, maxAmount: 0 })
      .then(handleSuccess)
      .catch(handleFailure)
  }

  return <>
    {defaultMaxAmount &&
      <EditStageLimitModal
        executorRole={executorRole}
        defaultValue={defaultMaxAmount}
        open={open}
        onClose={() => setOpen(false)}
        onSave={handleSave}
        onDelete={maxAmountPerStage && resetToGlobalDefault || undefined}
      />}
    <Box display='flex' justifyContent='space-between' margin='5px'>
      <Box display='inline'>
        <Label noSelect>
          Max amount of projects for {executorRole}: {maxAmountPerStage || `${defaultMaxAmount} (global default value)`}
        </Label>
      </Box>

      {UserService.hasRoles(['ROLE_PLATFORM_MANAGER', 'ROLE_GATED_COMMUNITY_MANAGER']) &&
        <Box display='inline'>
          <ActionButton onClick={() => { setOpen(true) }}>Edit Stage Limit</ActionButton>
        </Box>}
    </Box>
  </>
}
