import React, { useEffect } from 'react'
import { FormControl, TextField } from '@mui/material'
import { useInput } from 'react-admin'

interface OutlinedSearchInputProps {
  source: string,
  label: string,
  alwaysOn?: boolean,
}

export const OutlinedSearchInput = ({ source, label }: OutlinedSearchInputProps) => {
  const { field } = useInput({ source: source })
  const [content, setContent] = React.useState<string>(field.value || '')

  useEffect(() => {
    field.onChange(content)
  }, [content])
  
  return (<FormControl className="custom-array-input"> 
    <TextField
      label={label}
      value={content}
      onChange={(event) => { setContent(event.target.value) }}
    />
  </FormControl>
  )
}