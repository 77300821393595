import React from 'react'
import { FieldProps, Link, useRecordContext } from 'react-admin'

import CustomTranslatedTextField from './CustomTranslatedTextField'

interface Props extends FieldProps {
  targetFieldId: string
  show?: boolean
  translationPath?: string
  trim?: boolean
  basePath: string
}

const CustomLinkField = ({ targetFieldId, show, translationPath, trim, source, label, sortable, basePath }: Props) => {
  const record = useRecordContext()
  return record ? (
    <Link to={`/${basePath}/${record[targetFieldId] || ''}${show ? '/show' : ''}`}>
      <CustomTranslatedTextField source={source} translationPath={translationPath} trim={trim} label={label} sortable={sortable} />
    </Link>
  ) : null
}

export default CustomLinkField