import React, { useState } from 'react'
import { useTranslate } from 'react-admin'
import { StageType } from 'api/common'
import { Box } from '@mui/material'
import { EditIcon } from 'common/Icons'
import { EditLanguagePairLimitModal } from './EditLanguagePairLimitModal'
import UserService from 'UserService'

export interface LanguagePairExceptionProps {
  limitId: string
  executorRole: string
  communityId: string
  stageType: StageType
  sourceLanguage: string
  targetLanguage: string
  maxAmount: number
  onChange: () => void
}
export const LanguagePairException = ({ limitId, sourceLanguage, targetLanguage, maxAmount, communityId, stageType, onChange, executorRole }: LanguagePairExceptionProps) => {
  const translate = useTranslate()
  const [openLanguagePair, setOpenLanguagePair] = useState<boolean>(false)

  return <>
    <EditLanguagePairLimitModal executorRole={executorRole} limitId={limitId} communityId={communityId} stageType={stageType} defaultValue={maxAmount} open={openLanguagePair}
      onClose={() => setOpenLanguagePair(false)} onSave={() => { setOpenLanguagePair(false); onChange() }}
      fixedSourceLanguage={sourceLanguage} fixedTargetLanguage={targetLanguage} />

    <Box display='flex' justifyContent='space-between'>
      <Box width='40%'>{translate(`dict.lang.${sourceLanguage}`)}</Box>
      <Box width='40%'>{translate(`dict.lang.${targetLanguage}`)}</Box>
      <Box width='10%'>{maxAmount}</Box>
      <Box width='10%'>{UserService.hasRoles(['ROLE_PLATFORM_MANAGER', 'ROLE_GATED_COMMUNITY_MANAGER']) && <EditIcon onClick={() => setOpenLanguagePair(true)} />}</Box>
    </Box></>
}
