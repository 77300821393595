import { green, red } from '@mui/material/colors'
import { CompanyRecord } from 'api/company'

const rowStyle = (record: CompanyRecord) => {
  const style = {}
  if (!record) {
    return style
  }
  if (!record.filled)
  {return {
    ...style,
    borderLeftColor: red[500],
    borderLeftWidth: 5,
    borderLeftStyle: 'solid'
  }}
  return {
    ...style,
    borderLeftColor: green[500],
    borderLeftWidth: 5,
    borderLeftStyle: 'solid'
  }
}

export default rowStyle