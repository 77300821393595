import { Box } from '@mui/material'
import { FilterForm, FilterButton, ExportButton, SelectColumnsButton } from 'ra-ui-materialui'
import React from 'react'

import 'styles.scss'

interface ListToolbarProps {
  filters?: JSX.Element[]
  showExport?: boolean
  showFilter?: boolean
  showColumns?: boolean
  right?: JSX.Element
}

export const FilterExportToolbar = ({ filters, showExport, showFilter, showColumns, right }: ListToolbarProps) => (
  <Box className="edit-tab" width="100%">
    <Box className='on-left'>
      {filters&&<FilterForm filters={filters} />}
    </Box>
    <Box className='on-right' width='300px'>
      {right}
      {showFilter && <FilterButton filters={filters} id='filter-button' />}
      {showExport && <ExportButton id='export-button' />}
      {showColumns && <SelectColumnsButton id='export-button'/>}
    </Box>
  </Box>
)