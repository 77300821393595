import React, { useState } from 'react'
import { useListContext, useNotify } from 'react-admin'

import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import FormControl from '@mui/material/FormControl'

import { CompanySearchRecord, getSearchCompany } from 'api/company'

import 'styles.scss'

interface CompanySearchOptionRecord {
  id: string
  label: string
}

const CompanySearchFilter = (props: any) => {
  const [searchDelay, setSearchDelay] = useState<ReturnType<typeof setTimeout> | undefined>(undefined)
  const [companies, setCompanies] = useState<CompanySearchOptionRecord[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(true)

  const { filterValues, setFilters, displayedFilters } = useListContext()
  const notify = useNotify()

  const { source } = props

  const fetchCompanies = async (search: string) => {
    setIsLoading(true)
    try {
      const response = await getSearchCompany(search)
      setCompanies(convertRecordsToOptions(response))
    } catch (error) {
      notify('Something went wrong during searching for companies.', { type: 'error' })
    }
    setIsLoading(false)
  }

  const convertRecordsToOptions = (records: CompanySearchRecord[]): CompanySearchOptionRecord[] =>
    records.map(({ id, name }) => ({ label: name, id }))

  const handleTextChange = (_: any, value: string | null) => {
    if (searchDelay) {
      clearTimeout(searchDelay)
    }
    setSearchDelay(setTimeout(() => fetchCompanies(value || ''), 500))
  }

  const handleOptionChange = (_: any, value: CompanySearchRecord | null) => {
    if (!source) return
    setFilters({ ...filterValues, [source]: value }, displayedFilters)
  }

  const initOnClick = () => {
    if (!companies.length) {
      handleTextChange(null, '')
    }
  }

  if (!source) return null

  return <FormControl
    className="custom-array-input"
    sx={{
      marginBottom: "-4px"
    }}
  >
    <Autocomplete size='small' sx={{ minWidth: '10rem' }} options={companies} onInputChange={handleTextChange} loading={isLoading}
      onChange={handleOptionChange} defaultValue={filterValues[source]}
      renderInput={(params) => <FormControl margin='dense' fullWidth>
        <TextField
          {...params}
          onClick={initOnClick}
          variant='outlined'
          InputLabelProps={{ shrink: true }}
          label="Company" />
      </FormControl>}
    />
  </FormControl>
}

export default CompanySearchFilter