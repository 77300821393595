import CommunityIcon from '@mui/icons-material/GroupWork'

import GatedCommunityShow from './GatedCommunityShow'
import GatedCommunityList from './GatedCommunityList'

const resource: Record<string,any> = {
  list: GatedCommunityList,
  show: GatedCommunityShow,
  icon: CommunityIcon
}

export default resource