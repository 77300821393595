import React, { SyntheticEvent, useState } from 'react'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import CircularProgress from '@mui/material/CircularProgress'
import { AutocompleteArrayInput, required, useNotify } from 'react-admin'
import axios from 'axios'
import { stripCategoryAndIQVIAPrefix } from 'company/showTabs/PortalProperties'

export interface TaxonomySearchOptionRecord {
  id: string,
  label: string
}

interface TaxonomyMultiSearchProps {
  onChange?: (value: TaxonomySearchOptionRecord[] | undefined) => void,
}

export async function getSearchTaxonomy (search: string) {
  const response = await axios.get(`/api/workflow/my_exfluency/taxonomy`, {
    params: { query: search }
  })
  return response.data
}

export const convertRecordsToOptions = (records: string[]): TaxonomySearchOptionRecord[] =>
  records?.map(( value ) => ({ id: value, label: stripCategoryAndIQVIAPrefix(value) }))

export default function TaxonomyMultiSearch ({ onChange }: TaxonomyMultiSearchProps) {
  const [searchDelay, setSearchDelay] = useState<ReturnType<typeof setTimeout> | undefined>(undefined)
  const [isLoading, setIsLoading] = useState(false)
  const [taxonomyOptions, setTaxonomyOptions] = useState<TaxonomySearchOptionRecord[]>([])
  const [selectedValues, setSelectedValues] = useState<any[] | undefined>([])
  const notify = useNotify()

  const handleClose = () => {
    setTaxonomyOptions([])
  }

  const fetchTaxonomy = async (search: string) => {
    setIsLoading(true)
    try {
      if(search !== ''){
        setTaxonomyOptions([])

        const response = await getSearchTaxonomy(search)
        const findedTaxonomy: string[] = response.results[0]?.data
          .flatMap((g: { graph: { nodes: any } }) => g.graph.nodes)
          .map((n: { properties: { title: any } }) => n.properties.title)
          .filter((t: string) => t.toLowerCase() !== 'Category:Main_topic_classifications'.toLowerCase())
          .filter((t: string) => t.toLowerCase().includes(search.toLowerCase()))
        const uniqFindedTaxonomy = [...new Set(findedTaxonomy)]
        
        if (uniqFindedTaxonomy!==undefined){
          setTaxonomyOptions(convertRecordsToOptions(uniqFindedTaxonomy))
        }
      }
    } catch (error) {
      notify('Something went wrong during searching for taxonomy.', { type: 'error' })
    }
    setIsLoading(false)
  }

  const handleTextChange = (_: SyntheticEvent<Element, Event>|null, value: string | null) => {
    if (searchDelay) {
      clearTimeout(searchDelay)
    }
    setSearchDelay(setTimeout(() => fetchTaxonomy(value || ''), 500))
  }

  const handleOptionChange = (_: SyntheticEvent<Element, Event>|null, value: TaxonomySearchOptionRecord[] | undefined) => {
    setSelectedValues(value)
    onChange && onChange(value) //
  }

  const initOnClick = () => {
    setTaxonomyOptions([])
  }

  return (
    <Autocomplete
      sx={{ marginTop: "15px" }}
      fullWidth
      multiple
      loading={isLoading}
      onClose={handleClose}

      limitTags={2}
      onInputChange={handleTextChange}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      options={taxonomyOptions}
      onChange={handleOptionChange}
      value={selectedValues}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Taxonomy search"
          onClick={initOnClick}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            )
          }}
        />
      )}
    />
  )
}

interface TaxonomyMultiSearchInputProps {
  defaultOptions?: TaxonomySearchOptionRecord [],
  defaultValues?: string [],
}

export const TaxonomyMultiSearchInput = ({ defaultOptions, defaultValues }: TaxonomyMultiSearchInputProps) => {
  const [isLoading, setIsLoading] = useState(false)
  const [searchDelay, setSearchDelay] = useState<ReturnType<typeof setTimeout> | undefined>(undefined)
  const [taxonomyOptions, setTaxonomyOptions] = useState<TaxonomySearchOptionRecord[]>(defaultOptions || [])
  const [selectedValues, setSelectedValues] = useState<any[] >(defaultValues || [])
  const notify = useNotify()

  const fetchTaxonomyKeysOptions = async (search: string) => {
    setIsLoading(true)
    try {
      if(search !== ''){
        setTaxonomyOptions((convertRecordsToOptions(selectedValues)))

        const response = await getSearchTaxonomy(search)
        const findedTaxonomy: string[] = response.results[0]?.data
          .flatMap((g: { graph: { nodes: any } }) => g.graph.nodes)
          .map((n: { properties: { title: any } }) => n.properties.title)
          .filter((t: string) => t.toLowerCase() !== 'Category:Main_topic_classifications'.toLowerCase())
          .filter((t: string) => t.toLowerCase().includes(search.toLowerCase()))
        const uniqFindedTaxonomy = [...new Set([...findedTaxonomy, ...selectedValues])]
        
        if (uniqFindedTaxonomy!==undefined){
          setTaxonomyOptions(convertRecordsToOptions(uniqFindedTaxonomy))
        }
      }
    } catch (error) {
      notify('Something went wrong during searching for taxonomy.', { type: 'error' })
    }
    setIsLoading(false)
  }

  const handleChangeFilter = (value: string) => {
    if (searchDelay) {
      clearTimeout(searchDelay)
    }
    setSearchDelay(setTimeout(() => fetchTaxonomyKeysOptions(value || ''), 500))
  }

  const handleOptionChange = (value: any) => {
    setSelectedValues(value)
    setTaxonomyOptions((convertRecordsToOptions(value)))
  }

  return (
    <AutocompleteArrayInput
      label="Taxonomy keys"
      source="taxonomyKey"
      size='small'
      sx={{ minWidth: '223px' }} 
      validate={[required()]}
      optionText='label'
      choices={taxonomyOptions}
      noOptionsText={<React.Fragment>
        {isLoading ? <CircularProgress color="inherit" size={20} /> : 'No options'}
      </React.Fragment>
      }
      setFilter={handleChangeFilter}
      onChange={handleOptionChange}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      value={selectedValues}
    />
  )
}