import React, { useState } from 'react'
import { useRefresh, useTranslate } from 'react-admin'

import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContentText from '@mui/material/DialogContentText'
import MuiPaper from '@mui/material/Paper/Paper'
import { styled } from '@mui/material/styles'

import { ExtendedJobRecord } from 'api/jobs'
import ChangeDeadline from './ChangeDeadline'
import { parseTimestamp } from 'utils/helpers'
import UserService from 'UserService'
import ActionButton from 'components/ActionButton'

const Paper = styled(MuiPaper)(({ theme }) => ({
  padding: theme.spacing(1),
  margin: theme.spacing(0.5)
}))
  
interface Props {
  job: ExtendedJobRecord
}

const ChangeDeadlines = ({ job }: Props) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const translate = useTranslate()
  const refresh = useRefresh()

  const handleClose = () => {
    setIsOpen(false)
    refresh()
  }
    
  const handleOpen = () => {
    setIsOpen(true)
  }

  const { timezone } = UserService.getTokenUserInfo()

  return <>
    <ActionButton onClick={handleOpen}>Change Deadlines</ActionButton>
    <Dialog fullWidth open={isOpen}>
      <DialogTitle>
        Change Deadlines
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Paper elevation={8}>
              Project <ChangeDeadline dueDate={`${parseTimestamp(job.dueDate, timezone, false)}`} id={job.id} type='jobs' />
            {job.stages[0]?.type && job.stages.map(stage => <Paper elevation={6}>
              {translate(`stage.type.${stage.type}`)}<ChangeDeadline dueDate={`${parseTimestamp(stage.dueDate, timezone, false)}`} id={stage.id} type='stages' />
              {stage.assignments.map(assignment => <Paper elevation={4}>
                {assignment.executor}<ChangeDeadline dueDate={`${parseTimestamp(assignment.dueDate, timezone, false)}`} id={assignment.id} type='assignments' />
              </Paper>)}
            </Paper>)}
          </Paper>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
      </DialogActions>
    </Dialog>
  </>
}

export default ChangeDeadlines