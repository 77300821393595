import { styled } from "@mui/material/styles"

export const SM_FONT_LINE_H16 = 'normal normal 500 16px/16px Inter'
export const SM_FONT_LINE_H20 = 'normal normal 500 16px/20px Inter'
export const SM_FONT_LINE_H26 = 'normal normal 500 16px/26px Inter'
export const SM_FONT_LINE_H28 = 'normal normal 500 16px/28px Inter'

export const LG_FONT_LINE_H16 = 'normal bold 500 24px/16px Inter'

export const FONT_COLOR = '#3B3B3B'
export const BG_ACCENT = '#F9F9F9'

export const CARD_WIDTH = '600px'
export const GAP = '25px'

export const STANDARD_PADDING = '25px 25px 25px 35px'
export const COMMENTS_PADDING = '29px 58px 19px 38px'

export const CommentReplyHeader = styled('div')({
  font: SM_FONT_LINE_H20,
  color: FONT_COLOR
})

export const CommentHeader = styled('div')({
  font: SM_FONT_LINE_H16,
  color: FONT_COLOR
})

export const CommentText = styled('p')({
  margin: 0,
  color: FONT_COLOR,
  font: SM_FONT_LINE_H20,
  whiteSpace: 'pre-wrap'
})

export const NormText = styled('div')({
  font: SM_FONT_LINE_H26,
  color: FONT_COLOR, 
  
  'a': {
    textDecoration: 'underline !important',
    color: FONT_COLOR
  }
})
  
export const CardHeader = styled('span')({
  font: LG_FONT_LINE_H16,
  color: FONT_COLOR
})

export const CardFooter = styled('div')({
  minHeight: '60px',
  flex: 1,
  display: 'flex',
  backgroundColor: BG_ACCENT,
  borderRadius: '0px 0px 8px 8px',
  borderTop: '1px solid #CCC'
})

export const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: GAP,
  padding: STANDARD_PADDING
})

export const Footer = styled('div')({
  minHeight: '60px',
  flex: 1,
  display: 'flex',
  backgroundColor: BG_ACCENT,
  borderRadius: '0px 0px 8px 8px',
  borderTop: "1px solid #CCC"
})

/**
 * ## Makes an element unselectable
 */
export const NoSel = styled('span')({
  userSelect: 'none'
})