import React, { useState } from 'react'
import { ModalProps } from './common'
import { Box, MenuItem, Modal, TextField } from '@mui/material'
import { generateCompanyInvoice } from 'api/company'
import { useNotify, useRefresh } from 'ra-core'
import { months } from 'common/dictonary'
import { SumbitButtons } from 'common/SubmitButtons'
import RoundedPanel from 'common/RoundedPanel'

export const InvoiceModal = ({ record, open, onClose }: ModalProps) => {
  const [month, setMonth] = useState<number>(new Date().getMonth() - 1)
  const [year, setYear] = useState<number>(new Date().getFullYear())

  const [isLoading, setIsLoading] = useState<boolean>(false)

  const notify = useNotify()
  const refresh = useRefresh()

  const handleGenerateInvoice = () => {
    setIsLoading(true)
    const lastDayOfMonth = new Date(year, month + 1, 0).getDate()
    const castedMonth = month + 1
    const transferedMonth = castedMonth >= 10 ? castedMonth : '0' + castedMonth
    const startDate = `${year}-${transferedMonth}-01`
    const endDate = `${year}-${transferedMonth}-${lastDayOfMonth}`

    generateCompanyInvoice({ companyId: record.id, invoicingPeriod: { startDate, endDate } })
      .then(() => {
        notify('Successfully generated invoice', { type: 'success' })
      })
      .catch(() => {
        notify('Something went wrong', { type: 'error' })
      })
      .finally(() => {
        onClose()
        refresh()
        setIsLoading(false)
      })
  }

  const handleMonthChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMonth(parseInt(event.target.value))
  }

  const handleYearChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newYear = +event.target.value
    setYear(newYear)
    if(newYear == new Date().getFullYear() && new Date().getMonth() - 1 <= month) {
      setMonth(new Date().getMonth() - 1)
    }
  }

  const yearsList = () => {
    const year = new Date().getFullYear()
    const years = []
    for (let index = 0; index < 10; index++) {
      years.push({ value: year - index, label: year - index })
    }
    return years
  }

  const renderOptions = (option: {value: string | number, label: string | number}, month?: boolean) => {
    const { value, label } = option
    const val = parseInt(value.toString())

    const isDisabled = (val: number, year: number) => {
      const now = new Date()

      const currentMonthValue = now.getMonth() + 1
      const currentYearValue = now.getFullYear()

      return month && (val > currentMonthValue && currentYearValue <= year)
    }

    return <MenuItem 
      key={value} 
      value={value} 
      disabled={isDisabled(val, year)}
    >
      {label}
    </MenuItem>
  }

  return <Modal
    open={open}
    onClose={onClose}
    disableAutoFocus={true}
  >
    <RoundedPanel modal width="430px">
      <h1>Generate Invoice</h1>
      <Box height={18} />
      <TextField select sx={{ minWidth: '13rem', marginRight: '1rem' }} label="Month" value={month} onChange={handleMonthChange} className='custom-array-input normal-height'>
        {months.map(month => renderOptions(month, true))}
      </TextField>
      <TextField select sx={{ minWidth: '8rem' }} label="Year" value={year} onChange={handleYearChange} className='custom-array-input normal-height'>
        {yearsList().map(year => renderOptions(year, false))}
      </TextField>
      <Box height={32}/>
      <SumbitButtons onClose={onClose} onSave={handleGenerateInvoice} isLoading={isLoading} />
    </RoundedPanel>
  </Modal>
}