import React, { ReactNode } from 'react'
import { Datagrid, useListContext, useTranslate } from 'react-admin'

const SortedByTranslatedIdDatagrid = ({ children, translationPath }: { children: ReactNode, translationPath: string }) => {
  const { data, sort, setSort } = useListContext();
  const translate = useTranslate()
  const t = (id: string) => translate(`${translationPath}${id}`)

  const sortedData = data && [...data].sort((a, b) => {
    if (sort.field === 'id') {
      return t(a.id).localeCompare(t(b.id))
    }
    return 0;
  });

  return <Datagrid data={sortedData} sort={sort} setSort={setSort} bulkActionButtons={false}>
    {children}
  </Datagrid>
}

export default SortedByTranslatedIdDatagrid