import axios from "axios"
import { Identifier } from "ra-core"

import { LanguagePair , AllowedRole, Role, StageType } from "./common"

export type ProfileStatus = 'ORGANIZATION_ADMIN' | 'ORGANIZATION_MEMBER' | 'GATED_COMMUNITY_ADMIN' | 'GATED_COMMUNITY_MEMBER';

export interface GatedCommunitySearchRecord {
  id: string
  name: string
}

export interface GatedCommunityMemberSmallRecord {
  id: Identifier,
  userName: string,
  profile: ProfileStatus,
  roles: Role[],
  allowedRoles: AllowedRole[],
  languagePairs: LanguagePair[],
  allowedLanguagePairs: LanguagePair[],
  isNdaSigned: boolean,
  outOfOffice: boolean,
  isActive: boolean,
  isAccountConfirmed: boolean,
  score: number,
  latenessStats?: LatenessStats
}

export interface LatenessStats{
  latest: number,
  total: number
}

export interface GatedCommunityRecord {
  id: string,
  name: string,
  membersCount: number,
  activeMembersCount: number,
  recyclableTranslationLevels: string[],
  anonymizationEnabled: boolean,
  jobsCount: number,
  latestJobsCount: number
}

export interface SimpleGatedCommunityRecord {
  id: string,
  name: string,
  recyclableTranslationLevels: string[],
  anonymizationEnabled: boolean
}

export interface CandidateSearchResult {
  id: string,
  login: string,
  roles: Role[],
  communities: string[]
}

export async function getSearchGatedCommunity (search: string) {
  const response = await axios.get<GatedCommunitySearchRecord[]>(`/api/admin/gatedCommunities/search`, {
    params: { name: search }
  })
  return response.data
}

export async function updateGatedCommunityMemberRoles (gcId: string, userId: string, roles: AllowedRole[]) {
  await axios.post(`/api/admin/gatedCommunities/${gcId}/members/${userId}/allowedRoles`, roles)
}

export async function updateGatedCommunityMemberLanguagePairs (gcId: string, userId: string, languagePairs: LanguagePair[]) {
  await axios.post(`/api/admin/gatedCommunities/${gcId}/members/${userId}/allowedLanguagePairs`, languagePairs)
}

export async function deleterGatedCommunityMember (gcId: string, userId: string) {
  await axios.delete(`/api/admin/gatedCommunities/${gcId}/members/${userId}`)
}

export async function searchCandidatesToGatedCommunity (gcId: string, name: string) {
  const response = await axios.get<CandidateSearchResult[]>(`/api/admin/gatedCommunities/${gcId}/candidates/search`, {
    params: { name }
  })
  return response.data
}

export async function addUserToGatedCommunity (gcId: string, userId: string) {
  await axios.put(`/api/admin/gatedCommunities/${gcId}/members/${userId}`)
}

export async function setUserProfileInGatedCommunity (gcId: string, userId: string, profile: ProfileStatus) {
  await axios.post(`/api/admin/gatedCommunities/${gcId}/members/${userId}/profile`, { profile })
}

export interface MaxExecutorAssignments {
  communityId: string
  stageType: StageType
  maxAmount: number
}

export interface UpdateMaxExecutorAssignments {
  stageType: StageType
  maxAmount: number
}

export interface MaxExecutorAssignmentsPerLanguagePair {
  limitId: string
  communityId: string
  stageType: StageType
  sourceLanguage: string
  targetLanguage: string
  maxAmount: number
}

export interface UpdateMaxExecutorAssignmentsPerLanguagePairRequest {
  stageType: StageType
  sourceLanguage: string
  targetLanguage: string
  maxAmount: number
}

export async function getLimitsOfMaxExecutorAssignments (communityId: string) {
  const response = await axios.get<MaxExecutorAssignments[]>(`/api/workflow/groups_management/v2/communities/${communityId}/limits/max-executor-assignments`)
  return response.data
}

export async function setLimitOfMaxExecutorAssignments (communityId: string, maxExecutorAssignments: UpdateMaxExecutorAssignments) {
  await axios.put<void>(`/api/workflow/groups_management/v2/communities/${communityId}/limits/max-executor-assignments`, maxExecutorAssignments)
}

export async function getLimitsOfMaxExecutorAssignmentsPerLanguagePair (communityId: string) {
  const response = await axios.get<MaxExecutorAssignmentsPerLanguagePair[]>(`/api/workflow/groups_management/v2/communities/${communityId}/limits/max-executor-assignments-per-language-pair`)
  return response.data
}

export async function createLimitOfMaxExecutorAssignmentsPerLanguagePair (communityId: string, maxExecutorAssignments: UpdateMaxExecutorAssignmentsPerLanguagePairRequest) {
  await axios.post<void>(`/api/workflow/groups_management/v2/communities/${communityId}/limits/max-executor-assignments-per-language-pair`, maxExecutorAssignments)
}

export async function updateLimitOfMaxExecutorAssignmentsPerLanguagePair (communityId: string, limitId: string, maxExecutorAssignments: UpdateMaxExecutorAssignmentsPerLanguagePairRequest) {
  await axios.put<void>(`/api/workflow/groups_management/v2/communities/${communityId}/limits/max-executor-assignments-per-language-pair/${limitId}`, maxExecutorAssignments)
}

export async function deleteLimitOfMaxExecutorAssignmentsPerLanguagePair (communityId: string, limitId: string) {
  await axios.delete<void>(`/api/workflow/groups_management/v2/communities/${communityId}/limits/max-executor-assignments-per-language-pair/${limitId}`)
}