import { Box } from "@mui/material"
import React from "react"

interface AlertProps {
  children: React.ReactNode
  variant: "warn"
}

export const Alert = ({ children, variant }: AlertProps) => {
  return <Box className={variant}>
    {children}
  </Box>
}