import React from 'react'
import CustomButton, { ButtonProps } from './CustomButton'

const SmallButton = ({ onClick, disabled, children }: ButtonProps) => {
  return (
    <CustomButton onClick={onClick} disabled={disabled} sx={{
      padding: '6px 10px',
      marginLeft: "5px",
      minWidth: 'auto',
      font: 'normal normal 500 13px/13px Inter'
    }} >
      {children}
    </CustomButton>
  )
}

export default SmallButton