export type RCAType = 'INTERNAL_RCA' | 'REQUESTER_RCA'

export type RCAStatus = 'REQUESTED' | 'IN_PROGRESS' | 'COMPLETED' | 'APPROVED' | 'REJECTED'

export const TRUE_TRANSLATION_ERRORS = ['ADDITIONS_AND_DELETIONS', 'MISTRANSLATIONS', 'TERMINOLOGY', 'FORMATTING', 'GRAMMAR'] as const
export type TrueTranslationError = typeof TRUE_TRANSLATION_ERRORS[number]

export const CORRECTIVE_AND_PREVENTIVE_ACTIONS = ['DIRECT_EMAIL', 'GLOSSARY_UPDATED', 'INDIVIDUAL_RETRAINING', 'NEWSLETTER',
  'LANG_COMBINATION_REMOVED_FROM_GC', 'LINGUISTIC_ASSET_UPDATED', 'QUIZ', 'R_AND_D', 'ROLE_DEACTIVATED', 'WARNING', 'WEBINAR', 'OTHER'] as const
export type CorrectiveAndPreventiveAction = typeof CORRECTIVE_AND_PREVENTIVE_ACTIONS[number]

export type RCAResponsibleUser = {
    stageId: string
    responsibleUserId: string
}

export type RCASmallInfo = {
  rcaId: string,
  rcaType: RCAType,
  rcaStatus: RCAStatus
}

export type RCA = {
  id: string
  zendeskTicketNumber: number
  requestDate: string
  implementationDate: string
  rcaType: RCAType
  rcaStatus: RCAStatus
  issue: string
  justification: string
  exfluencyContactPersonId: string
  exfluencyContactPerson: string
  capaOtherContent: string
  comments: string
  trueTranslationErrors: TrueTranslationError[]
  correctiveAndPreventiveActions: CorrectiveAndPreventiveAction[]
  rcaResponsibleUsersByStageIds: RCAResponsibleUser[]
}