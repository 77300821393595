import React from 'react'
import { useRecordContext, useTranslate, FieldProps, NumberField } from 'react-admin'

import { AdminJobDashboardRecord } from 'api/jobs'
import UserService from 'UserService'

const TotalField = (props: FieldProps) => {
  const record = useRecordContext<AdminJobDashboardRecord>()
  const shouldSeeFeeOrProfit = UserService.hasRoles(['ROLE_SUPPORT', 'ROLE_EXFLUENCY_ADMIN', 'ROLE_COMMUNITY_MANAGER', 'ROLE_PLATFORM_MANAGER'])
  let style = {}
  if (record.profit < 0 && shouldSeeFeeOrProfit) {
    style = {
      backgroundColor: "#DB474C",
      position: "absolute",
      top: "0",
      left: "0",
      width: "100%",
      height: "100%",
      display: "flex",
      alignItems: "center",
      padding: "6px 16px"
    }
  }

  return <NumberField source="cost" options={{ style: 'currency', currency: 'EUR' }} style={ style } />
}

export default TotalField