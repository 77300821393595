import React from 'react'
import { styled } from "@mui/material/styles"
import { Property } from 'csstype'

const StyledCard = styled('div')<{
  fullWidth?: boolean, 
  width?: string, 
  backgroundColor?: Property.BackgroundColor
}>(({ fullWidth, width, backgroundColor }) => ({
  backgroundColor: backgroundColor || 'white',
  border: '1px solid #CCC',
  borderRadius: '8px',
  width: fullWidth ? '100%' : width || 'auto'
}))

interface SideCardProps {
    children: React.ReactNode
    fullWidth?: boolean
    width? : string
    backgroundColor?: Property.BackgroundColor
}

const SideCard = ({ children, fullWidth, width, backgroundColor }: SideCardProps) => {
  return <StyledCard fullWidth={fullWidth} width={width} backgroundColor={backgroundColor}>
    {children}
  </StyledCard>
}

export default SideCard