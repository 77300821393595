import { Checkbox } from '@mui/material'
import { useRecordContext } from 'ra-core'
import React from 'react'

interface CheckboxFieldProps {
    selected: Set<string>,
    label: string,
    setSelected: (id: Set<string>) => void,
    size?: 'small' | 'medium'
}

export const CheckboxField = ({ selected, setSelected, size }: CheckboxFieldProps) => {
  const record = useRecordContext()

  return <Checkbox
    size={size}
    checked={selected.has(record.id as string)}
    onChange={(event) => {
      const { checked } = event.target
      const newSelected = new Set(selected)
      if (checked) {
        newSelected.add(record.id as string)
      }
      else {
        newSelected.delete(record.id as string)
      }
      setSelected(newSelected)
    }}
  />
}

