import React, { useState } from 'react'
import moment from 'moment'
import { useListContext } from 'react-admin'

import TextField from '@mui/material/TextField'
import { DateTimePicker } from '@mui/lab'
import { FormControl } from '@mui/material'

import 'styles.scss'

interface Props {
  label: string
  source: string
  alwaysOn?: boolean
}

const OutlinedDateTimeInput = ({ label, source }: Props) => {
  const listContext = useListContext()
  const { filterValues, setFilters, displayedFilters } = listContext
  const [date, setDate] = useState<string | null>(filterValues[source] || null)
  
  const handleDateChange = (newDate: string | null) => {
    setDate(newDate)
  }

  const handleAccept = (newDate: string | null) => {
    if(!source) return
    const filterDate = newDate ? moment(newDate).format('YYYY-MM-DDTHH:mm:ss.SSSZ') : null 
    setFilters({ ...filterValues, [source]: filterDate }, displayedFilters)
  }
  
  return <FormControl className="custom-array-input"> 
    <DateTimePicker
      renderInput={(props) => <TextField variant='outlined' {...props} />}
      label={label}
      inputFormat='YYYY-MM-DD HH:mm:ss'
      clearable
      value={date}
      disableFuture
      onChange={handleDateChange}
      onAccept={handleAccept}
    />
  </FormControl>
}
  
export default OutlinedDateTimeInput